@charset "UTF-8";
@import "https://fonts.googleapis.com/css?family=Yantramanav:500,700,900";

.modal-backdrop.in {
    filter  : alpha(opacity=80);
    opacity : .8
}

.media.inline-block .media-body {
    width : auto
}

.breadcrumb {
    margin           : 0;
    padding          : 0;
    background-color : transparent;
    border-radius    : 0;
    text-transform   : uppercase;
    color            : inherit;
    font-size        : 11px;
    line-height      : 20px;
    font-weight      : 700
}

.breadcrumb > .active {
    color : #3D6889
}

.breadcrumb > li + li:before {
    color   : inherit;
    padding : 0 4px;
    content : "/"
}

.pagination {
    margin : 0
}

.pagination > li {
    display : inline-block
}

.pagination > li > a, .pagination > li > span {
    text-align     : center;
    padding        : 10px 0;
    font-size      : 12px;
    text-transform : uppercase;
    font-weight    : 700;
    color          : #88ADB9;
    margin-left    : 6px;
    margin-bottom  : 10px;
    min-width      : 40px;
    border-radius  : 2px;
    border         : 1px solid rgba(61, 104, 137, .2)
}

.pagination > li:first-child > a, .pagination > li:first-child > span {
    padding : 10px 26px
}

.pagination > li:last-child > a, .pagination > li:last-child > span {
    padding : 10px 26px
}

.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
    border-color : #3D6889
}

input[type=text], input[type=email], input[type=url], input[type=password], input[type=search], input[type=tel], textarea, select, .form-control {
    height           : 50px;
    font-size        : 14px;
    text-transform   : uppercase;
    font-weight      : 700;
    background-color : #E8F1F3;
    border-radius    : 3px;
    border           : none;
    border-bottom    : 1px solid rgba(61, 104, 137, .2);
    color            : #3D6889;
    padding          : 15px 20px;
}

.content-card input[type=text], .content-card input[type=email], .content-card input[type=url], .content-card input[type=password], .content-card input[type=search], .content-card input[type=tel], .content-card textarea, .content-card select, .content-card .form-control {
    font-size : 11px
}

textarea {
    height : auto
}

.form-group {
    margin-bottom : 10px
}

.select-group {
    position         : relative;
    padding          : 0;
    background-color : #E8F1F3
}

.select-group select {
    position           : relative;
    width              : 100%;
    cursor             : pointer;
    z-index            : 1;
    padding-right      : 30px;
    -webkit-appearance : none;
    -moz-appearance    : none;
    appearance         : none;
    background-color   : transparent !important
}

.select-group select::-ms-expand {
    display : none
}

.select-group i {
    background-color : #D3E4E8
}

.select-group:hover i {
    opacity : .5
}

.select-group .empty {
    color : #88ADB9
}

.select-group option {
    color : #3D6889
}

.select-group option[data-default] {
    color : #3D6889
}

.form-control option {
    color            : #292A2E;
    background-color : #FFFFFF
}

.form-control, .form-control:focus {
    box-shadow : none
}

.form-control.invalid {
    box-shadow : 0 0 0 2px #3D6889
}

.form-control:focus {
    border-color : rgba(61, 104, 137, .2)
}

.form-control:-moz-placeholder {
    color : #88ADB9
}

.form-control::-moz-placeholder {
    color : #88ADB9
}

.form-control:-ms-input-placeholder {
    color : #88ADB9
}

.form-control::-webkit-input-placeholder {
    color : #88ADB9
}

.form-control:focus:-moz-placeholder {
    color : transparent
}

.form-control:focus::-moz-placeholder {
    color : transparent
}

.form-control:focus:-ms-input-placeholder {
    color : transparent
}

.form-control:focus::-webkit-input-placeholder {
    color : transparent
}

form label + i {
    position  : absolute;
    font-size : 24px;
    color     : #88ADB9;
    left      : 15px;
    top       : 12px
}

form label + [class*=icon2-] + .form-control {
    padding-left : 50px
}

label {
    font-weight : 400
}

.form-group {
    position : relative
}

.form-control.right-icon {
    padding-right : 50px
}

.form-control + i {
    position           : absolute;
    left               : auto;
    right              : 10px;
    bottom             : 10px;
    width              : 28px;
    height             : 28px;
    line-height        : 26px;
    text-align         : center;
    border             : 1px solid rgba(61, 104, 137, .2);
    border-radius      : 2px;
    font-size          : 14px;
    color              : #88ADB9;
    z-index            : 0;
    -webkit-transition : all .2s;
    transition         : all .2s
}

.form-control:focus + i {
    color : #3D6889
}

.carousel {
    overflow : hidden
}

.carousel-indicators li {
    width              : 10px;
    height             : 10px;
    border-width       : 0;
    background-color   : transparent;
    border             : 2px solid #292A2E;
    margin             : 0 1px;
    -webkit-transition : all .2s ease-in-out 0s;
    transition         : all .2s ease-in-out 0s
}

.carousel-indicators .active {
    width            : 10px;
    height           : 10px;
    margin           : 0 1px;
    background-color : #292A2E
}

.carousel-control.left, .carousel-control.right {
    border           : 1px solid #FFFFFF;
    color            : #FFFFFF;
    border-radius    : 0;
    width            : 50px;
    height           : 50px;
    left             : -50px;
    top              : 50%;
    margin-top       : -25px;
    text-shadow      : none;
    opacity          : 1;
    background-image : none;
    filter           : alpha(opacity=100);
    text-align       : center
}

.carousel-control.right {
    right : -50px
}

.carousel:hover .carousel-control {
    left : 50px
}

.carousel:hover .carousel-control.right {
    left  : auto;
    right : 50px
}

.carousel-control.left span, .carousel-control.right span {
    font-family : rt-icons-2;
    width       : 50px;
    height      : 50px;
    margin      : 0;
    font-size   : 16px;
    left        : 0;
    top         : 0;
    line-height : 50px
}

.carousel-control .icon-prev:before {
    content : "\e7c4"
}

.carousel-control .icon-next:before {
    content : "\e7c5"
}

.panel-heading .panel-title {
    font-size      : inherit;
    letter-spacing : 0;
    padding        : 0;
    position       : relative
}

.panel-heading .panel-title > a {
    font-family      : Arial, Helvetica, sans-serif;
    font-size        : 14px;
    text-transform   : uppercase;
    font-weight      : 700;
    line-height      : 1;
    display          : block;
    padding          : 16px 65px 16px 20px;
    color            : #FFFFFF;
    background-color : #3D6889;
    border           : none;
    border-bottom    : 1px solid #73D0F4;
    border-radius    : 3px;
    word-wrap        : break-word
}

.panel-heading .panel-title > a i {
    line-height : 13px
}

.panel-heading .panel-title > a.collapsed:hover, .panel-heading .panel-title > a:hover {
    opacity          : 1;
    color            : #FFFFFF;
    background-color : #3D6889
}

.panel-heading .panel-title > a.collapsed {
    color               : #88ADB9;
    background-color    : #E8F1F3;
    border-bottom-color : transparent
}

.panel-heading .panel-title > a:after {
    content       : "\f0d7";
    font-family   : FontAwesome;
    position      : absolute;
    top           : 11px;
    right         : 20px;
    width         : 28px;
    height        : 28px;
    text-align    : center;
    line-height   : 26px;
    border        : 1px solid #D3E4E8;
    border-radius : 2px
}

.panel-heading .panel-title > a.collapsed:after {
    content : "\f0da"
}

.panel-heading .panel-title > a > i {
    position      : relative;
    top           : 2px;
    font-size     : 16px;
    padding-right : 16px
}

.panel {
    box-shadow : none;
    overflow   : hidden
}

.panel-group .panel {
    border-radius    : 3px;
    background-color : transparent;
    border           : none;
    border-bottom    : 1px solid rgba(61, 104, 137, .2)
}

.panel-default > .panel-heading {
    background-color : transparent;
    color            : inherit;
    position         : relative;
    border           : none;
    border-radius    : 0;
    padding          : 0
}

.panel-default {
    border-color : transparent
}

.panel-group .panel + .panel {
    margin-top : 10px
}

.panel-body {
    border-top       : none !important;
    background-color : #E8F1F3
}

.panel-body .media-left {
    padding-right : 15px
}

.panel-body .media-left .img-circle {
    max-width : 50px
}

.panel-body .media-left img {
    max-width : 80px
}

.panel-group .panel-heading + .panel-collapse .panel-body {
    padding       : 35px;
    border-radius : 3px
}

.panel-group.style2 .panel-heading .panel-title > a {
    font-size        : 11px;
    padding          : 19px 55px 19px 20px;
    background-color : transparent;
    color            : #3D6889;
    border-radius    : 0;
    border-color     : rgba(61, 104, 137, .2)
}

.panel-group.style2 .panel-heading .panel-title > a:after {
    right       : 10px;
    content     : "\f068";
    font-weight : 300;
    line-height : 28px;
    font-size   : 10px
}

.panel-group.style2 .panel-heading .panel-title > a.collapsed {
    color               : #88ADB9;
    border-bottom-color : transparent
}

.panel-group.style2 .panel-heading .panel-title > a.collapsed:after {
    content : "\f067";
    color   : #88ADB9
}

.panel-group.style2 .panel-body {
    font-size        : 14px;
    background-color : transparent
}

.panel-group.style2 .panel-heading + .panel-collapse .panel-body {
    padding : 21px 20px
}

.panel-group.style2 .panel {
    border : 1px solid rgba(61, 104, 137, .2)
}

p + .progress {
    margin-top : -10px
}

.progress {
    overflow         : hidden;
    height           : 40px;
    position         : relative;
    border-radius    : 0;
    box-shadow       : none;
    border           : none;
    background-color : #F3F8F9
}

.progress-bar {
    background-color : #3D6889;
    color            : inherit;
    box-shadow       : none;
    height           : 40px
}

.progress-bar span {
    position  : relative;
    color     : #FFFFFF;
    top       : 10px;
    font-size : .9em
}

.progress-bar-info {
    background-color : #8AA5EC
}

.progress-bar-warning {
    background-color : #F0AD4E
}

.progress-bar-danger {
    background-color : #D9534F
}

.alert {
    border-radius : 0;
    border-width  : 0 0 0 4px;
    padding       : 30px
}

.media {
    margin-top : 16px
}

.media .media-object {
    max-width : 100px
}

.nav > li > a {
    color : #292A2E
}

.nav > li > a:hover, .nav > li > a:focus {
    background-color : inherit
}

.tab-content {
    border-radius : 0 0 0 0;
    padding       : 35px 40px;
    border        : 1px solid rgba(61, 104, 137, .2)
}

@media (max-width : 399px) {
    .tab-content {
        padding : 35px 20px 30px
    }
}

.tab-content.no-border {
    padding : 35px 0 0;
    border  : none
}

.tab-content.with_background {
    border : none
}

.tab-content.no-border.top-color-border:before, .tab-content.with_background.top-color-border:before {
    left  : 0;
    right : 0
}

.tab-content.top-color-border {
    position    : relative;
    padding-top : 45px
}

.tab-content.top-color-border:before {
    content          : '';
    position         : absolute;
    left             : -1px;
    right            : -1px;
    top              : -1px;
    height           : 10px;
    background-color : #3D6889
}

.tab-content .featured-tab-image {
    margin : -36px -41px 30px
}

@media (max-width : 399px) {
    .tab-content .featured-tab-image {
        margin : -36px -21px 30px
    }
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    border : 1px solid rgba(61, 104, 137, .2)
}

.nav-tabs {
    border-bottom-color : transparent;
    margin-top          : 0;
    margin-bottom       : -1px
}

.nav-tabs > li > a {
    font-size        : 14px;
    text-transform   : uppercase;
    font-weight      : 700;
    padding          : 15px 20px;
    margin-right     : 1px;
    margin-top       : 0;
    border           : none;
    border           : 1px solid rgba(61, 104, 137, .2);
    background-color : transparent;
    color            : rgba(136, 173, 185, .7);
    border-radius    : 2px 2px 0 0
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    border-color     : transparent;
    background-color : #3D6889;
    color            : #FFFFFF
}

.nav-tabs > li > a:hover, .nav-tabs > li > a:focus, .nav-tabs.nav-justified > .active > a {
    border-color     : rgba(61, 104, 137, .2);
    background-color : transparent;
    color            : #3D6889
}

.vertical-tabs .tab-content.no-border {
    padding-top : 0
}

.vertical-tabs .nav > li > a {
    background-color : #292A2E;
    color            : #FFFFFF;
    padding          : 18px 60px 18px 40px;
    margin-bottom    : 10px;
    position         : relative
}

.vertical-tabs .nav > li > a i {
    position      : relative;
    top           : 1px;
    padding-right : 12px
}

.vertical-tabs .nav > li > a:after {
    color          : rgba(255, 255, 255, .8);
    font-family    : rt-icons-2;
    content        : "\e7c5";
    position       : absolute;
    font-size      : 12px;
    line-height    : 58px;
    text-align     : center;
    right          : 0;
    top            : 0;
    bottom         : 0;
    width          : 60px;
    border-left    : 1px solid rgba(255, 255, 255, .2);
    letter-spacing : 0
}

.vertical-tabs .nav > li.active a, .vertical-tabs .nav > li > a:hover {
    background-color : #3D6889
}

@media (min-width : 768px) {
    .vertical-tabs .nav > li.active {
        position : relative;
        z-index  : 2
    }
}

.btn {
    font-size          : 11px;
    font-weight        : 700;
    color              : #3D6889;
    padding            : 9px 20px;
    text-transform     : uppercase;
    margin             : 0;
    border             : none;
    border-radius      : 0;
    outline            : none;
    -webkit-transition : all .3s ease-in-out 0s;
    transition         : all .3s ease-in-out 0s
}

.btn:hover, .btn:focus {
    outline : none
}

li.media:before, li.media:after {
    display : block;
    content : '';
    clear   : both
}

.dropdown-menu {
    box-shadow    : none;
    border-radius : 0;
    border-color  : rgba(61, 104, 137, .2);
    z-index       : 1001;
    font-size     : 16px;
    margin-top    : 0
}

[class*=right] .dropdown-menu {
    right : 0;
    left  : auto
}

.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
    color            : #292A2E;
    background-color : transparent
}

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
    padding      : 13px 8px;
    border-color : #D3E4E8
}

.table {
    border-top    : 1px solid #D3E4E8;
    border-bottom : 1px solid #D3E4E8
}

.table td + td {
    border-left : 1px solid #D3E4E8
}

.table-bordered > thead > tr > td, .table-bordered > thead > tr > th {
    border-bottom-width : 1px
}

.table-responsive {
    border : none
}

.well {
    box-shadow    : none;
    border        : none;
    border-radius : 0
}

.embed-responsive-3by2 {
    padding-bottom : 66.666%
}

.embed-placeholder:before {
    -webkit-transition : all .2s ease-in-out 0s;
    transition         : all .2s ease-in-out 0s;
    content            : "\e696";
    font-family        : rt-icons-2;
    position           : absolute;
    color              : #FFFFFF;
    font-size          : 30px;
    left               : 50%;
    top                : 50%;
    margin-left        : -20px;
    margin-top         : -5px
}

.embed-placeholder:hover:before {
    opacity : .4
}

html {
    width    : 100%;
    height   : 100%;
    position : relative;
    overflow : visible
}

::-moz-selection {
    color            : #FFFFFF;
    background-color : #3D6889
}

::selection {
    color            : #FFFFFF;
    background-color : #3D6889
}

::-moz-selection {
    color            : #FFFFFF;
    background-color : #3D6889
}

body {
    font-family      : Arial, Helvetica, sans-serif;
    font-size        : 17px;
    line-height      : 22px;
    background-color : #FFFFFF;
    overflow         : visible;
    overflow-x       : hidden
}

h1, h2, h3, h4, h5, h6 {
    font-family    : yantramanav, sans-serif;
    font-weight    : 500;
    line-height    : .95;
    word-break     : break-word;
    letter-spacing : -.025em
}

h1 {
    font-size : 45px
}

h2 {
    font-size : 35px
}

h3 {
    font-size : 30px
}

h4 {
    font-size : 25px
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
    margin-top : 0
}

p {
    margin-bottom : 20px
}

p:last-child {
    margin-bottom : 0
}

blockquote {
    position    : relative;
    margin      : 60px 0;
    padding     : 5px 20px;
    border-left : 5px solid rgba(61, 104, 137, .4);
    font-size   : 25px;
    line-height : 26px;
    font-style  : italic
}

blockquote .media {
    font-size   : 14px;
    font-style  : normal;
    margin      : 0 0 6px;
    padding-top : 10px
}

blockquote .media img {
    min-width     : 40px;
    max-width     : 40px;
    border-radius : 50%
}

blockquote .item-meta {
    margin-top    : 25px;
    margin-bottom : 0;
    font-style    : normal;
    color         : rgba(136, 173, 185, .5)
}

blockquote.no-border {
    border-left : none;
    padding     : 0;
    margin      : 0
}

img, figure {
    max-width : 100%;
    height    : auto
}

figcaption, .entry-caption {
    padding : 3px 10px
}

figcaption p, .entry-caption p {
    margin-bottom : 0
}

a {
    text-decoration    : none;
    -webkit-transition : all .3s ease-in-out 0s;
    transition         : all .3s ease-in-out 0s
}

a:hover {
    color              : #4E8DBD;
    text-decoration    : none;
    -webkit-transition : all .15s linear 0s;
    transition         : all .15s linear 0s
}

a:focus {
    outline         : medium;
    text-decoration : none
}

a img {
    -webkit-transition : all .15s ease-in-out 0s;
    transition         : all .15s ease-in-out 0s
}

hr {
    border           : none;
    height           : 1px;
    background-color : #D3E4E8
}

iframe {
    border    : none;
    max-width : 100%
}

input[type=number] {
    -moz-appearance : textfield
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance : none
}

ul, ol {
    padding-left : 30px
}

ul.list1 {
    list-style : none;
    padding    : 0;
    margin     : 25px 0
}

ul.list1 li {
    position : relative;
    padding  : 11px 0 10px 30px
}

ul.list1 li > .media {
    margin : 3px 0
}

ul.list1 li > .media.bottommargin_10 {
    margin-bottom : 10px
}

ul.list1 li > .media .media-body.thin {
    padding-top : 2px
}

ul.list1.no-bullets li {
    padding-left : 0
}

ul.list1.no-bullets li:before {
    display : none
}

ul.list1 li:first-child {
    border-top : 1px solid rgba(61, 104, 137, .2)
}

ul.list1 li {
    border-bottom : 1px solid rgba(61, 104, 137, .2)
}

.muted_background ul.list1 li {
    border-color : rgba(255, 255, 255, .4)
}

ul.list1.middle-borders li:first-child {
    border-top  : none;
    padding-top : 0
}

ul.list1.middle-borders li:last-child {
    border-bottom  : none;
    padding-bottom : 0
}

ul.list1 li:before {
    content       : '';
    display       : block;
    position      : absolute;
    border        : 1px solid #3D6889;
    left          : 10px;
    top           : 17px;
    width         : 7px;
    height        : 7px;
    border-radius : 50%
}

ul.list2 {
    list-style    : none;
    padding-left  : 15px;
    margin-bottom : 10px;
    margin-top    : 10px
}

ul.list2 li {
    position : relative
}

ul.list2 li:before {
    font-family : FontAwesome;
    font-size   : 14px;
    color       : #73D0F4;
    content     : "\f0da";
    position    : absolute;
    left        : -13px
}

ol.list3 {
    margin-top    : 10px;
    counter-reset : li;
    padding       : 0;
    margin-left   : 20px
}

ol.list3 li {
    position : relative
}

table {
    width     : 100%;
    max-width : 100%;
    margin    : 10px 0
}

table td, table th {
    padding : 3px 0
}

.table_template thead {
    background-color : #4E8DBD;
    color            : #292A2E;
    text-transform   : uppercase;
    font-size        : 14px
}

.table_template thead.light {
    background-color : #FFFFFF
}

.table_template td, .table_template th {
    padding : 18px 30px
}

.table_template tbody tr:nth-of-type(even) {
    background-color : #F3F8F9
}

h1 > [class*=rt-icon], h2 > [class*=rt-icon], h3 > [class*=rt-icon], h4 > [class*=rt-icon], h5 > [class*=rt-icon], h6 > [class*=rt-icon] {
    font-size     : 1.2em;
    position      : relative;
    top           : .1em;
    line-height   : 0;
    padding-right : .1em
}

p > [class*=rt-icon] {
    font-size   : 1.1em;
    position    : relative;
    top         : .05em;
    line-height : 0;
    padding     : 0 .2em
}

.theme_buttons a > [class*=rt-icon], .theme_button > [class*=rt-icon] {
    font-size   : 1.2em;
    line-height : 0;
    position    : relative;
    top         : 2px;
    padding     : 0 5px
}

.theme_buttons a > [class*=glyphicon], .theme_buttons a > [class*=fa-], .theme_button > [class*=glyphicon], .theme_button > [class*=fa-] {
    font-size   : 1.2em;
    position    : relative;
    top         : 1px;
    line-height : 0;
    padding     : 0 5px
}

.theme_buttons a, .theme_button {
    font-size          : 14px;
    padding            : 17px 20px;
    margin-bottom      : 4px;
    line-height        : 1;
    display            : inline-block;
    min-width          : 8.5em;
    text-align         : center;
    text-transform     : uppercase;
    font-weight        : 700;
    color              : #FFFFFF;
    border             : 1px solid #292A2E;
    background-color   : #292A2E;
    border-radius      : 3px;
    position           : relative;
    -webkit-transition : all .4s linear 0s;
    transition         : all .4s linear 0s
}

.theme_buttons.color1 a, .theme_button.color1 {
    background-color : #3D6889;
    border-color     : #3D6889
}

.theme_buttons.color2 a, .theme_button.color2 {
    background-color : #4E8DBD;
    border-color     : #4E8DBD
}

.theme_buttons.color3 a, .theme_button.color3 {
    background-color : #73D0F4;
    border-color     : #73D0F4
}

.theme_buttons a:active, .theme_buttons a:hover, .theme_buttons a:focus, .theme_button:active, .theme_button:hover, .theme_button:focus {
    color              : rgba(255, 255, 255, .7);
    opacity            : 1;
    text-decoration    : none;
    outline            : none;
    -webkit-transition : all .05s linear 0s;
    transition         : all .05s linear 0s
}

.theme_buttons a:active, .theme_button:active {
    top : 1px
}

.theme_buttons.inverse a, .theme_button.inverse {
    color            : #3D6889;
    background-color : transparent;
    border           : 1px solid #88ADB9
}

.muted_buttons a, .muted_button {
    opacity : .2
}

.small_buttons a, .small_button {
    padding        : 1px 7px 0;
    text-transform : uppercase;
    font-weight    : 100;
    min-width      : auto
}

.square_buttons a, .square_button {
    padding-left  : 10px;
    padding-right : 10px;
    min-width     : auto
}

.wide_buttons a, .wide_button {
    padding-left  : 50px;
    padding-right : 50px
}

.block_buttons a, .block_button {
    display    : block;
    width      : 100%;
    text-align : center
}

.section_padding_0 > [class*=container] {
    padding-top    : 0;
    padding-bottom : 0
}

.section_padding_15 > [class*=container] {
    padding-top    : 15px;
    padding-bottom : 15px
}

.section_padding_20 > [class*=container] {
    padding-top    : 20px;
    padding-bottom : 20px
}

.section_padding_25 > [class*=container] {
    padding-top    : 25px;
    padding-bottom : 25px
}

.section_padding_35 > [class*=container] {
    padding-top    : 35px;
    padding-bottom : 35px
}

.section_padding_50 > [class*=container] {
    padding-top    : 50px;
    padding-bottom : 50px
}

.section_padding_75 > [class*=container] {
    padding-top    : 75px;
    padding-bottom : 75px
}

.section_padding_90 > [class*=container] {
    padding-top    : 90px;
    padding-bottom : 90px
}

.section_padding_100 > [class*=container] {
    padding-top    : 100px;
    padding-bottom : 100px
}

.section_padding_150 > [class*=container] {
    padding-top    : 150px;
    padding-bottom : 150px
}

.section_padding_top_0 > [class*=container] {
    padding-top : 0
}

.section_padding_top_5 > [class*=container] {
    padding-top : 5px
}

.section_padding_top_15 > [class*=container] {
    padding-top : 15px
}

.section_padding_top_25 > [class*=container] {
    padding-top : 25px
}

.section_padding_top_30 > [class*=container] {
    padding-top : 30px
}

.section_padding_top_40 > [class*=container] {
    padding-top : 40px
}

.section_padding_top_50 > [class*=container] {
    padding-top : 50px
}

.section_padding_top_65 > [class*=container] {
    padding-top : 65px
}

.section_padding_top_75 > [class*=container] {
    padding-top : 75px
}

.section_padding_top_100 > [class*=container] {
    padding-top : 100px
}

.section_padding_top_120 > [class*=container] {
    padding-top : 120px
}

.section_padding_top_140 > [class*=container] {
    padding-top : 140px
}

.section_padding_top_150 > [class*=container] {
    padding-top : 150px
}

.section_padding_bottom_0 > [class*=container] {
    padding-bottom : 0
}

.section_padding_bottom_5 > [class*=container] {
    padding-bottom : 5px
}

.section_padding_bottom_15 > [class*=container] {
    padding-bottom : 15px
}

.section_padding_bottom_25 > [class*=container] {
    padding-bottom : 25px
}

.section_padding_bottom_30 > [class*=container] {
    padding-bottom : 30px
}

.section_padding_bottom_40 > [class*=container] {
    padding-bottom : 40px
}

.section_padding_bottom_50 > [class*=container] {
    padding-bottom : 50px
}

.section_padding_bottom_65 > [class*=container] {
    padding-bottom : 65px
}

.section_padding_bottom_75 > [class*=container] {
    padding-bottom : 75px
}

.section_padding_bottom_90 > [class*=container] {
    padding-bottom : 90px
}

.section_padding_bottom_100 > [class*=container] {
    padding-bottom : 100px
}

.section_padding_bottom_120 > [class*=container] {
    padding-bottom : 120px
}

.section_padding_bottom_140 > [class*=container] {
    padding-bottom : 140px
}

@media (max-width : 991px) {
    .section_padding_90 > [class*=container], .section_padding_100 > [class*=container], .section_padding_150 > [class*=container], .section_padding_top_100 > [class*=container], .section_padding_top_120 > [class*=container], .section_padding_top_140 > [class*=container], .section_padding_top_150 > [class*=container] {
        padding-top : 75px
    }

    .section_padding_90 > [class*=container], .section_padding_100 > [class*=container], .section_padding_150 > [class*=container], .section_padding_bottom_90 > [class*=container], .section_padding_bottom_100 > [class*=container], .section_padding_bottom_120 > [class*=container], .section_padding_bottom_140 > [class*=container] {
        padding-bottom : 75px
    }
}

[class*=col-] {
    margin-top    : 10px;
    margin-bottom : 10px
}

@media (max-width : 767px) {
    aside[class*=col-] {
        margin-top : 80px !important
    }
}

.columns_padding_1 [class*=col-] {
    margin-top    : 0;
    margin-bottom : 1px
}

.columns_padding_2 [class*=col-] {
    margin-top    : 1px;
    margin-bottom : 1px
}

.columns_padding_5 [class*=col-] {
    margin-top    : 5px;
    margin-bottom : 5px
}

.columns_padding_0 [class*=col-] {
    padding : 0
}

.columns_padding_1 [class*=col-] {
    padding-left  : 0;
    padding-right : 1px
}

.columns_padding_2 [class*=col-] {
    padding-left  : 1px;
    padding-right : 1px
}

.columns_padding_5 [class*=col-] {
    padding-left  : 5px;
    padding-right : 5px
}

.columns_padding_0 > .container, .columns_padding_1 > .container, .columns_padding_2 > .container {
    padding-left  : 30px;
    padding-right : 30px
}

.columns_padding_5 > .container {
    padding-left  : 25px;
    padding-right : 25px
}

.columns_padding_0.table_section > .container {
    padding : 0
}

.row.columns_padding_0 {
    margin : 0
}

.row.columns_padding_1 {
    margin-left  : 0;
    margin-right : -1px
}

.row.columns_padding_2 {
    margin-left  : -1px;
    margin-right : -1px
}

.row.columns_padding_5 {
    margin-left  : -5px;
    margin-right : -5px
}

@media (min-width : 500px) {
    .columns_padding_25 > [class*=container] > .row, .row.columns_padding_25 {
        margin-right : -25px;
        margin-left  : -25px
    }

    .columns_padding_25 > [class*=container] > .row > [class*=col-], .row.columns_padding_25 > [class*=col-] {
        padding-left  : 25px;
        padding-right : 25px;
        margin-top    : 25px;
        margin-bottom : 25px
    }
}

.columns_margin_top_0 [class*=col-], .row.columns_margin_top_0 [class*=col-] {
    margin-top : 0
}

.columns_margin_top_5 [class*=col-], .row.columns_margin_top_5 [class*=col-] {
    margin-top : 5px
}

.columns_margin_top_10 [class*=col-], .row.columns_margin_top_10 [class*=col-] {
    margin-top : 10px
}

.columns_margin_top_15 [class*=col-], .row.columns_margin_top_15 [class*=col-] {
    margin-top : 15px
}

.columns_margin_top_20 [class*=col-], .row.columns_margin_top_20 [class*=col-] {
    margin-top : 20px
}

.columns_margin_top_30 [class*=col-], .row.columns_margin_top_30 [class*=col-] {
    margin-top : 30px
}

.columns_margin_bottom_0 [class*=col-], .row.columns_margin_bottom_0 [class*=col-] {
    margin-bottom : 0
}

.columns_margin_bottom_5 [class*=col-], .row.columns_margin_bottom_5 [class*=col-] {
    margin-bottom : 5px
}

.columns_margin_bottom_10 [class*=col-], .row.columns_margin_bottom_10 [class*=col-] {
    margin-bottom : 10px
}

.columns_margin_bottom_15 [class*=col-], .row.columns_margin_bottom_15 [class*=col-] {
    margin-bottom : 15px
}

.columns_margin_bottom_20 [class*=col-], .row.columns_margin_bottom_20 [class*=col-] {
    margin-bottom : 20px
}

.columns_margin_bottom_30 [class*=col-], .row.columns_margin_bottom_30 [class*=col-] {
    margin-bottom : 30px
}

.columns_margin_bottom_40 [class*=col-], .row.columns_margin_bottom_40 [class*=col-] {
    margin-bottom : 40px
}

.columns_margin_bottom_60 [class*=col-], .row.columns_margin_bottom_60 [class*=col-] {
    margin-bottom : 60px
}

.columns_margin_0 [class*=col-], header [class*=col-] {
    margin-top    : 0;
    margin-bottom : 0;
    min-height    : 0
}

.table_section [class*=container] {
    height        : 100%;
    padding-left  : 0;
    padding-right : 0
}

.table_section .row {
    display      : table;
    min-width    : 100%;
    height       : 100%;
    margin-left  : 0;
    margin-right : 0
}

.table_section [class*=col-] {
    display        : table-cell;
    vertical-align : middle;
    float          : none
}

@media screen and (max-width : 767px) {
    .table_section .row, .table_section [class*=col-] {
        display : block;
        width   : auto
    }
}

@media screen and (max-width : 991px) {
    .table_section.table_section_md .row, .table_section.table_section_md [class*=col-] {
        display : block;
        width   : auto
    }
}

@media screen and (max-width : 1199px) {
    .table_section.table_section_lg .row, .table_section.table_section_lg [class*=col-] {
        display : block;
        width   : auto
    }
}

@media screen and (min-width : 1200px) {
    .top-overlap {
        margin-top : -170px
    }

    .top-overlap-small {
        margin-top : -100px
    }
}

@media (max-width : 767px) {
    .container {
        max-width : 500px
    }
}

.background_cover {
    background-position : center;
    background-size     : cover
}

.parallax {
    background-attachment : fixed;
    background-size       : cover;
    background-position   : 50% 0;
    background-repeat     : no-repeat;
    position              : relative
}

@media (max-width : 767px) {
    .parallax {
        background-size : auto auto
    }
}

.parallax.ls:after {
    background-color : #FFFFFF;
    -ms-filter       : "progid: DXImageTransform.Microsoft.Alpha(Opacity=92)";
    filter           : alpha(opacity=8);
    opacity          : .8
}

.parallax.ls.ms:after {
    background-color : #F3F8F9;
    -ms-filter       : "progid: DXImageTransform.Microsoft.Alpha(Opacity=88)";
    filter           : alpha(opacity=8);
    opacity          : .8
}

.parallax.cs:after {
    background-color : #3D6889;
    -ms-filter       : "progid: DXImageTransform.Microsoft.Alpha(Opacity=90)";
    filter           : alpha(opacity=90);
    opacity          : .9
}

.parallax.cs:before {
    display : none
}

.parallax.cs.main_color2:after {
    background-color : #4E8DBD
}

.parallax.cs.main_color3:after {
    background-color : #73D0F4
}

.parallax.ds:before {
    background-color : #000000;
    opacity          : .75
}

.parallax.ds.ms:after {
    display : none
}

.texture_bg {
    background-size     : cover;
    background-position : 50% 0;
    background-repeat   : no-repeat;
    position            : relative;
    background-image    : url(../img/aside_bg.jpg)
}

.muted_section:before {
    background-color : rgba(41, 42, 46, .1);
    opacity          : 1
}

.parallax > *, .muted_section > *, .radial_gradient > *, .gradient > *, .vertical_gradient > *, .darken_gradient > *, .diagonal_section > * {
    z-index  : 4;
    position : relative
}

.radial_gradient {
    position : relative;
    overflow : hidden
}

.gradient, .muted_section, .vertical_gradient, .darken_gradient {
    position : relative
}

.muted_section:before, .muted_section:after, .parallax:before, .parallax:after, .gradient:before, .gradient:after, .vertical_gradient:before, .vertical_gradient:after, .darken_gradient:before, .darken_gradient:after {
    content  : '';
    display  : block;
    position : absolute;
    top      : 0;
    left     : 0;
    right    : 0;
    bottom   : 0;
    z-index  : 3
}

.muted_section:before, .muted_section:after, .parallax:before, .parallax:after {
    opacity : .8
}

.header_gradient, .gradient:before, .dark_gradient:before {
    background : #3D6889;
    background : -webkit-gradient(linear, left top, right top, color-stop(0%, #3D6889), color-stop(100%, #4E8DBD));
    background : -webkit-linear-gradient(left, #3D6889 0%, #4E8DBD 100%);
    background : linear-gradient(to right, #3D6889 0%, #4E8DBD 100%);
    filter     : progid:DXImageTransform.Microsoft.gradient(startColorstr='$mainColor', endColorstr='$mainColor2', GradientType=1)
}

.vertical_gradient:before {
    background : #3D6889;
    background : -webkit-linear-gradient(top, #3D6889 0%, #4E8DBD 100%);
    background : linear-gradient(to bottom, #3D6889 0%, #4E8DBD 100%);
    filter     : progid:DXImageTransform.Microsoft.gradient(startColorstr='$mainColor', endColorstr='$mainColor2', GradientType=0)
}

.darken_gradient:before {
    background : -webkit-linear-gradient(top, transparent 0%, rgba(55, 52, 47, 0.65) 100%);
    background : linear-gradient(to bottom, transparent 0%, rgba(55, 52, 47, 0.65) 100%);
    filter     : progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a637342f', GradientType=0)
}

.with_top_border, .with_bottom_border {
    position : relative
}

.with_top_border:before, .with_bottom_border:after {
    content          : '';
    display          : block;
    position         : absolute;
    height           : 1px;
    left             : 0;
    right            : 0;
    top              : 0;
    background-color : #D3E4E8
}

.with_bottom_border:after {
    top    : auto;
    bottom : 0
}

.bg_image, .bg_image:after {
    background-image : url(../img/background_section.png)
}

.section_header {
    font-size   : 35px;
    position    : relative;
    font-weight : 500
}

.section_header.big {
    margin-bottom : 45px
}

.section_header.small {
    font-size      : 25px;
    text-transform : none
}

.to_animate {
    visibility : hidden
}

.lt-ie10 .to_animate, .lt-ie9 .to_animate {
    visibility : visible
}

.animated {
    visibility : visible !important
}

#box_wrapper, #canvas {
    overflow : visible;
    position : relative
}

#canvas.boxed {
    padding : 1px 0
}

#box_wrapper.container, #box_wrapper > section, #box_wrapper > div > section {
    overflow : hidden
}

body.boxed {
    position : static;
    width    : auto;
    height   : auto
}

.pattern1 {
    background-image : url(../img/pattern1.png)
}

.pattern2 {
    background-image : url(../img/pattern2.png)
}

.pattern3 {
    background-image : url(../img/pattern3.png)
}

.pattern4 {
    background-image : url(../img/pattern4.png)
}

.pattern5 {
    background-image : url(../img/pattern5.png)
}

.pattern6 {
    background-image : url(../img/pattern6.png)
}

.pattern7 {
    background-image : url(../img/pattern7.png)
}

.pattern8 {
    background-image : url(../img/pattern8.png)
}

.pattern9 {
    background-image : url(../img/pattern9.png)
}

.pattern10 {
    background-image : url(../img/pattern10.png)
}

.pattern11 {
    background-image : url(../img/pattern11.png)
}

.container {
    max-width : 100%
}

.boxed .container {
    max-width     : 100%;
    padding-left  : 50px;
    padding-right : 50px
}

@media (max-width : 500px) {
    .boxed .container {
        padding-left  : 10px;
        padding-right : 10px
    }
}

@media (min-width : 1400px) {
    .boxed #box_wrapper.container {
        width : 1370px
    }
}

@media (min-width : 1600px) {
    .boxed #box_wrapper.container {
        width : 1570px
    }
}

.boxed #box_wrapper.container {
    padding    : 0;
    box-shadow : 0 0 10px 0 rgba(0, 0, 0, .2)
}

.boxed #box_wrapper.container.top-bottom-margins {
    margin-top    : 80px;
    margin-bottom : 80px
}

.alignleft {
    float     : left;
    margin    : 5px 30px 30px 0;
    max-width : 50%
}

.alignright {
    float     : right;
    margin    : 5px 0 30px 30px;
    max-width : 50%
}

.round {
    border-radius : 50%
}

.rounded {
    border-radius : 6px
}

.bottom-rounded {
    border-radius : 0 0 6px 6px
}

.bottom-rouded-small {
    border-radius : 0 0 3px 3px;
    overflow      : hidden
}

.inline-block {
    display   : inline-block;
    max-width : 100%
}

.with_padding {
    padding : 35px
}

@media (max-width : 399px) {
    .with_padding {
        padding : 35px 20px
    }
}

.table_section_content {
    padding : 30px 50px
}

.table_section_content h3 {
    font-size   : 42px;
    font-weight : 300;
    margin      : 0 0 32px
}

.with_background {
    background-color : #E8F1F3
}

.muted_background {
    background-color : #F3F8F9
}

.with_border {
    border : 1px solid #D3E4E8
}

.bottom-color-border {
    border-bottom : 5px solid #3D6889
}

.bottom-color-border.color2 {
    border-bottom-color : #4E8DBD
}

.bottom-color-border.color3 {
    border-bottom-color : #73D0F4
}

.before_cover, .after_cover, .before_cover > *, .after_cover > * {
    position : relative;
    z-index  : 2
}

.before_cover:before, .after_cover:after {
    content  : '';
    display  : block;
    position : absolute;
    top      : 0;
    right    : 0;
    bottom   : 0;
    left     : 0
}

.top-corner {
    position    : absolute;
    top         : 0;
    left        : 0;
    line-height : 1
}

.big {
    font-size   : 42px;
    line-height : .9
}

.response, .highlight {
    color        : #3D6889;
    border-color : #3D6889
}

.highlight2 {
    color        : #4E8DBD;
    border-color : #4E8DBD
}

.highlight3 {
    color        : #73D0F4;
    border-color : #73D0F4
}

.red, .required {
    color : #FA5C5D
}

.black {
    color        : #1A1A1A;
    border-color : #1A1A1A
}

.grey {
    color        : #292A2E;
    border-color : #292A2E
}

.greylinks a {
    color : #88ADB9
}

.darklinks a {
    color : #3D6889
}

.thin {
    font-weight : 300
}

.bold {
    font-weight : 700
}

.weight-black {
    font-weight : 900
}

.quotesign {
    position    : relative;
    display     : inline-block;
    width       : 50px;
    height      : 50px;
    line-height : 1
}

.quotesign:before {
    font-size   : 150px;
    font-family : pt sans, sans-serif;
    content     : '‘‘';
    position    : absolute;
    left        : 0;
    top         : 0;
    line-height : 1
}

.light_bg_color {
    background-color : #FFFFFF
}

.main_bg_color {
    color            : #FFFFFF;
    background-color : #3D6889
}

.main_bg_color2 {
    color            : #FFFFFF;
    background-color : #4E8DBD
}

.dark_bg_color {
    color            : #FFFFFF;
    background-color : #1A1A1A
}

.dark_bg_color h1, .dark_bg_color h2, .dark_bg_color h3, .dark_bg_color h4, .dark_bg_color h5, .dark_bg_color h6 {
    color : #FFFFFF
}

.darkgrey_bg_color {
    color            : #FFFFFF;
    background-color : #292A2E
}

.darkgrey_bg_color h1, .darkgrey_bg_color h2, .darkgrey_bg_color h3, .darkgrey_bg_color h4, .darkgrey_bg_color h5, .darkgrey_bg_color h6 {
    color : #FFFFFF
}

.divider_10 {
    margin : 10px 0
}

.divider_15 {
    margin : 15px 0
}

.divider_20 {
    margin : 20px 0
}

.divider_30 {
    margin : 30px 0
}

.divider_40 {
    margin : 40px 0
}

.margin_0 {
    margin : 0 !important
}

.topmargin_0 {
    margin-top : 0 !important
}

.topmargin_1 {
    margin-top : 1px
}

.topmargin_5 {
    margin-top : 5px
}

.topmargin_10 {
    margin-top : 10px
}

.topmargin_20 {
    margin-top : 20px
}

.topmargin_30 {
    margin-top : 30px
}

.topmargin_40 {
    margin-top : 40px
}

.topmargin_50 {
    margin-top : 50px
}

.topmargin_60 {
    margin-top : 60px
}

.topmargin_70 {
    margin-top : 70px
}

.topmargin_80 {
    margin-top : 80px
}

.bottommargin_0 {
    margin-bottom : 0 !important
}

.bottommargin_5 {
    margin-bottom : 5px
}

.bottommargin_10 {
    margin-bottom : 10px
}

.bottommargin_15 {
    margin-bottom : 15px
}

.bottommargin_20 {
    margin-bottom : 20px
}

.bottommargin_25 {
    margin-bottom : 25px
}

.bottommargin_30 {
    margin-bottom : 30px
}

.bottommargin_40 {
    margin-bottom : 40px
}

.bottommargin_60 {
    margin-bottom : 60px
}

.bottommargin_70 {
    margin-bottom : 70px
}

.bottommargin_80 {
    margin-bottom : 80px
}

.leftmargin_10 {
    margin-left : 10px
}

.leftmargin_20 {
    margin-left : 20px
}

.leftmargin_30 {
    margin-left : 30px
}

.leftmargin_40 {
    margin-left : 40px
}

.leftmargin_50 {
    margin-left : 50px
}

.leftmargin_60 {
    margin-left : 60px
}

.rightmargin_10 {
    margin-right : 10px
}

.rightmargin_20 {
    margin-right : 20px
}

.rightmargin_30 {
    margin-right : 30px
}

.rightmargin_40 {
    margin-right : 40px
}

.rightmargin_50 {
    margin-right : 50px
}

.rightmargin_60 {
    margin-right : 60px
}

.padding_0 {
    padding : 0
}

.padding_10 {
    padding : 10px
}

.padding_20 {
    padding : 20px
}

.padding_30 {
    padding : 30px
}

.padding_40 {
    padding : 40px
}

.toppadding_5 {
    padding-top : 5px
}

.toppadding_10 {
    padding-top : 10px
}

.toppadding_20 {
    padding-top : 20px
}

.toppadding_30 {
    padding-top : 30px
}

.toppadding_40 {
    padding-top : 40px
}

.toppadding_50 {
    padding-top : 50px
}

.toppadding_60 {
    padding-top : 60px
}

.bottompadding_10 {
    padding-bottom : 10px
}

.bottompadding_20 {
    padding-bottom : 20px
}

.bottompadding_30 {
    padding-bottom : 30px
}

.bottompadding_40 {
    padding-bottom : 40px
}

.bottompadding_50 {
    padding-bottom : 50px
}

.bottompadding_60 {
    padding-bottom : 60px
}

.leftpadding_10 {
    padding-left : 10px
}

.leftpadding_20 {
    padding-left : 20px
}

.leftpadding_30 {
    padding-left : 30px
}

.leftpadding_40 {
    padding-left : 40px
}

.leftpadding_50 {
    padding-left : 50px
}

.leftpadding_60 {
    padding-left : 60px
}

.rightpadding_10 {
    padding-right : 10px
}

.rightpadding_20 {
    padding-right : 20px
}

.rightpadding_30 {
    padding-right : 30px
}

.rightpadding_40 {
    padding-right : 40px
}

.rightpadding_50 {
    padding-right : 50px
}

.rightpadding_60 {
    padding-right : 60px
}

.fontsize_16 {
    font-size : 16px
}

.fontsize_18 {
    font-size : 18px
}

.fontsize_20 {
    font-size : 20px
}

.fontsize_24 {
    font-size : 24px
}

.fontsize_25 {
    font-size : 25px
}

.fontsize_32 {
    font-size : 32px
}

.fontsize_35 {
    font-size : 35px
}

.fontsize_24, .fontsize_25, .fontsize_32, .fontsize_35 {
    line-height : .9
}

.yantramanov {
    font-family    : yantramanav, sans-serif;
    letter-spacing : -.025em
}

.display_table {
    display   : table;
    min-width : 100%;
    height    : 100%;
    margin    : auto
}

.display_table_cell {
    display        : table-cell;
    vertical-align : middle;
    float          : none;
    overflow       : hidden;
    zoom           : 1
}

@media (min-width : 1200px) {
    .text-lg-right {
        text-align : right
    }

    .text-lg-left {
        text-align : left
    }

    .text-lg-center {
        text-align : center
    }

    .display_table_lg {
        display      : table;
        min-width    : 100%;
        height       : 100%;
        margin-left  : auto;
        margin-right : auto
    }

    .display_table_cell_lg {
        display        : table-cell;
        vertical-align : middle;
        float          : none;
        overflow       : hidden;
        zoom           : 1
    }
}

@media (min-width : 992px) {
    .text-md-right {
        text-align : right
    }

    .text-md-left {
        text-align : left
    }

    .text-md-center {
        text-align : center
    }

    .display_inline_md {
        display : inline
    }

    .display_table_md {
        display      : table;
        min-width    : 100%;
        height       : 100%;
        margin-left  : auto;
        margin-right : auto
    }

    .display_table_cell_md {
        display        : table-cell;
        vertical-align : middle;
        float          : none;
        overflow       : hidden;
        zoom           : 1
    }
}

@media (min-width : 768px) {
    .text-sm-right {
        text-align : right
    }

    .text-sm-left {
        text-align : left
    }

    .text-sm-center {
        text-align : center
    }

    .display_table_sm {
        display      : table;
        min-width    : 100%;
        height       : 100%;
        margin-left  : auto;
        margin-right : auto
    }

    .display_table_cell_sm {
        display        : table-cell;
        vertical-align : middle;
        float          : none;
        overflow       : hidden;
        zoom           : 1
    }
}

@media (max-width : 767px) {
    .text-xs-right {
        text-align : right
    }

    .text-xs-left {
        text-align : left
    }

    .text-xs-center {
        text-align : center
    }

    .not_found {
        font-size : 130px
    }
}

@media (max-width : 500px) {
    .hidden-xxs {
        display : none
    }
}

.inline-dropdown {
    list-style : none;
    margin     : 0;
    padding    : 0
}

.inline-dropdown > li {
    display : inline-block
}

.small-text {
    font-size      : 11px;
    line-height    : 1.3em;
    text-transform : uppercase;
    font-weight    : 700
}

.small-text2 {
    font-size      : 14px;
    text-transform : uppercase;
    font-weight    : 700;
    letter-spacing : -.025em
}

.small-text2.thin {
    line-height : 1.1
}

.with_border .item-media {
    margin  : -1px -1px -2px;
    z-index : 1
}

.with_border.full-padding .item-media {
    margin : 0
}

.full-padding {
    padding : 30px
}

.content-padding .item-content {
    padding : 30px 30px 20px
}

.side-item .row {
    margin : 0
}

.side-item [class*=col-] {
    padding : 0;
    margin  : 0
}

.side-item .item-title + .item-content {
    padding-top : 20px
}

.side-item [class*=col-] > .item-title {
    padding : 15px 30px
}

.side-item [class*=col-] > .item-title h2, .side-item [class*=col-] > .item-title h3, .side-item [class*=col-] > .item-title h4 {
    margin : 0
}

.side-item.with_border [class*=col-] > .item-title {
    margin : -1px
}

@media (max-width : 767px) {
    .no-content-padding [class*=col-xs-] .item-content, .full-padding [class*=col-xs-] .item-content {
        padding-left : 30px
    }

    .no-content-padding [class*=col-xs-][class*=col-xs-pull] .item-content, .full-padding [class*=col-xs-][class*=col-xs-pull] .item-content {
        padding-left  : 0;
        padding-right : 30px
    }

    .no-content-padding .col-xs-12 .item-content, .full-padding .col-xs-12 .item-content {
        padding-top   : 30px;
        padding-left  : 0;
        padding-right : 0
    }
}

@media (min-width : 768px) {
    .no-content-padding [class*=col-sm-] .item-content, .full-padding [class*=col-sm-] .item-content {
        padding-left : 30px
    }

    .no-content-padding [class*=col-sm-][class*=col-sm-pull] .item-content, .full-padding [class*=col-sm-][class*=col-sm-pull] .item-content {
        padding-left  : 0;
        padding-right : 30px
    }

    .no-content-padding .col-sm-12 .item-content, .full-padding .col-sm-12 .item-content {
        padding-top   : 30px;
        padding-left  : 0;
        padding-right : 0
    }
}

@media (min-width : 992px) {
    .no-content-padding [class*=col-md-] .item-content, .full-padding [class*=col-md-] .item-content {
        padding-left : 30px
    }

    .no-content-padding [class*=col-md-][class*=col-md-pull] .item-content, .full-padding [class*=col-md-][class*=col-md-pull] .item-content {
        padding-left  : 0;
        padding-right : 30px
    }

    .no-content-padding .col-md-12 .item-content, .full-padding .col-md-12 .item-content {
        padding-top   : 30px;
        padding-left  : 0;
        padding-right : 0
    }
}

@media (min-width : 1200px) {
    .no-content-padding [class*=col-lg-] .item-content, .full-padding [class*=col-lg-] .item-content {
        padding-left : 30px
    }

    .no-content-padding [class*=col-lg-][class*=col-lg-pull] .item-content, .full-padding [class*=col-lg-][class*=col-lg-pull] .item-content {
        padding-left  : 0;
        padding-right : 30px
    }

    .no-content-padding .col-lg-12 .item-content, .full-padding .col-lg-12 .item-content {
        padding-top   : 30px;
        padding-left  : 0;
        padding-right : 0
    }
}

.vertical-item .item-media + .item-content {
    padding-top : 30px
}

.vertical-item .list1 {
    text-align : left;
    margin     : 0
}

.vertical-item.content-padding .item-content {
    padding : 35px
}

@media (max-width : 400px) {
    .vertical-item.content-padding .item-content {
        padding : 35px 20px
    }
}

.vertical-item.content-absolute {
    position : relative
}

.vertical-item.content-absolute .item-content {
    position : absolute;
    bottom   : 0;
    left     : 0;
    right    : 0;
    padding  : 0 30px 20px
}

.vertical-item.content-absolute.vertical-center .item-content {
    top         : 0;
    line-height : 1.1
}

.vertical-item + .item-title {
    padding : 10px 30px
}

.vertical-item + .item-title h2, .vertical-item + .item-title h3, .vertical-item + .item-title h4 {
    margin : 0
}

.item-meta {
    margin-bottom : 10px
}

.item-meta i {
    position  : relative;
    font-size : 20px;
    top       : 3px;
    padding   : 0;
    margin    : 0
}

.item-media {
    position : relative;
    overflow : hidden
}

.item-media img {
    width : 100%
}

.media-links {
    text-align : center;
    position   : absolute;
    top        : 0;
    right      : 0;
    bottom     : 0;
    left       : 0
}

.media-links div {
    position   : absolute;
    top        : 50%;
    width      : 100%;
    margin-top : -25px;
    z-index    : 4
}

.media-links:before {
    content            : '';
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : 0;
    background-color   : rgba(55, 52, 47, .5);
    filter             : alpha(opacity=0);
    opacity            : 0;
    -webkit-transition : all .2s linear;
    transition         : all .2s linear
}

[class*=item]:hover .media-links:before {
    filter  : alpha(opacity=50);
    opacity : .5
}

.media-links a.abs-link {
    position : absolute;
    top      : 0;
    right    : 0;
    bottom   : 0;
    left     : 0;
    opacity  : 0
}

[class*=item]:hover .media-links a.abs-link {
    opacity : 1
}

.links-wrap a {
    position           : relative;
    width              : 50px;
    height             : 50px;
    line-height        : 50px;
    top                : -100px;
    display            : inline-block;
    z-index            : 5;
    font-size          : 24px;
    text-align         : center;
    filter             : alpha(opacity=0);
    opacity            : 0;
    transition         : all .6s cubic-bezier(.175, .885, .32, 1.275) 0s;
    -moz-transition    : all .6s cubic-bezier(.175, .885, .32, 1.275) 0s;
    -ms-transition     : all .6s cubic-bezier(.175, .885, .32, 1.275) 0s;
    -o-transition      : all .6s cubic-bezier(.175, .885, .32, 1.275) 0s;
    -webkit-transition : all .6s cubic-bezier(.175, .885, .32, 1.275) 0s;
    border             : 1px solid #3D6889;
    background-color   : #3D6889
}

.links-wrap a:hover {
    color : rgba(255, 255, 255, .6)
}

.links-wrap a.p-view {
    color : #FFFFFF
}

.links-wrap a.p-link {
    background-color   : transparent;
    color              : #FFFFFF;
    border-color       : #FFFFFF;
    transition         : all .6s cubic-bezier(.175, .885, .32, 1.275) .1s;
    -moz-transition    : all .6s cubic-bezier(.175, .885, .32, 1.275) .1s;
    -ms-transition     : all .6s cubic-bezier(.175, .885, .32, 1.275) .1s;
    -o-transition      : all .6s cubic-bezier(.175, .885, .32, 1.275) .1s;
    -webkit-transition : all .6s cubic-bezier(.175, .885, .32, 1.275) .1s
}

.links-wrap a:before {
    font-family : rt-icons-2;
    content     : "\e6d4"
}

.links-wrap a.p-link:before {
    content : "\e707"
}

[class*=item]:hover .links-wrap a {
    top     : 0;
    filter  : alpha(opacity=100);
    opacity : 1
}

[class*=item]:hover .media-links div {
    filter  : alpha(opacity=100);
    opacity : 1
}

.item-icons {
    display    : table;
    width      : 100%;
    border-top : 1px solid rgba(128, 128, 128, .1)
}

.item-icons i {
    display   : block;
    font-size : 16px
}

.item-icons > div {
    padding : 22px 10px 18px;
    display : table-cell;
    width   : 400px
}

.item-icons > div + div {
    border-left : 1px solid rgba(128, 128, 128, .1)
}

.item-icons [data-id] {
    display : none
}

.bottom-border {
    position : relative
}

.bottom-border:after {
    content          : '';
    position         : absolute;
    left             : 0;
    right            : 0;
    bottom           : 0;
    height           : 2px;
    background-color : rgba(0, 0, 0, .1)
}

.block-header {
    text-transform : uppercase;
    font-weight    : 400;
    font-size      : 12px;
    letter-spacing : .1em;
    line-height    : 1;
    padding-bottom : 9px
}

.block-header .highlight {
    position : relative
}

.block-header .highlight:after {
    content          : '';
    position         : absolute;
    left             : 0;
    right            : 0;
    bottom           : -11px;
    height           : 3px;
    z-index          : 2;
    background-color : #3D6889
}

@media (min-width : 1200px) {
    .lg-clearfix {
        clear : both
    }
}

@media (min-width : 992px) and (max-width : 1199px) {
    .md-clearfix {
        clear : both
    }
}

@media (min-width : 768px) and (max-width : 991px) {
    .sm-clearfix {
        clear : both
    }
}

@media (max-width : 767px) {
    .xs-clearfix {
        clear : both
    }
}

.ls {
    background-color : #FFFFFF;
    color            : #88ADB9
}

.ls h1, .ls h2, .ls h3, .ls h4, .ls h5, .ls h6 {
    color : #3D6889
}

.ls h1 a, .ls h2 a, .ls h3 a, .ls h4 a, .ls h5 a, .ls h6 a {
    color : inherit
}

.ls h1 a:hover, .ls h2 a:hover, .ls h3 a:hover, .ls h4 a:hover, .ls h5 a:hover, .ls h6 a:hover {
    color   : inherit;
    opacity : .4
}

.ls a {
    color : #3D6889
}

.ls a:hover {
    color : #292A2E
}

.ls .highlight3 a, .ls a.highlight3 {
    color : #73D0F4
}

.ls .highlight3 a:hover, .ls a.highlight3:hover {
    color : #3D6889
}

.ls .small-text {
    color : #C3D7E1
}

.ls .theme_buttons a, .ls .theme_button {
    color : #FFFFFF
}

.ls .theme_buttons a:hover, .ls .theme_buttons a:focus, .ls .theme_button:hover, .ls .theme_button:focus {
    color : rgba(255, 255, 255, .7)
}

.ls .theme_button.inverse a, .ls .theme_button.inverse {
    color : #3D6889
}

.ls .theme_button.inverse a:hover, .ls .theme_button.inverse a:focus, .ls .theme_button.inverse:hover, .ls .theme_button.inverse:focus {
    color : rgba(61, 104, 137, .7)
}

.ls .color-icon.bg-icon {
    background-color : #F8F8F8
}

.ls .highlight {
    color : #3D6889
}

.ls .highlight2 {
    color : #4E8DBD
}

.ls .highlight3 {
    color : #73D0F4
}

.ls .greylinks a {
    color : #88ADB9
}

.ls .greylinks a:hover {
    color : #3D6889
}

.ls .greylinks a.social-icon:hover {
    color : #3D6889
}

.ls .darklinks a {
    color : #3D6889
}

.ls .darklinks a:hover {
    color : #73D0F4
}

.ls .pagination > li > a, .ls .pagination > li > span {
    background-color : #E8F1F3;
    color            : #88ADB9
}

.ls .pagination > li.active > a, .ls .pagination > li.active > span, .ls .pagination > li > a:hover, .ls .pagination > li > span:hover, .ls .pagination > li > a:focus, .ls .pagination > li > span:focus {
    background-color : #3D6889;
    border-color     : #3D6889;
    color            : #FFFFFF
}

.ls.ms {
    background-color : #F3F8F9
}

.ls.ms.ms2 {
    background-color : #E8F1F3
}

.ls.ms .greylinks a:hover {
    color : #292A2E
}

.ls.ms .with_background {
    background-color : #FFFFFF
}

.ls.ms .with_background hr {
    background-color : rgba(61, 104, 137, .2)
}

.ls.ms hr {
    background-color : #FFFFFF
}

.ls.ms .panel-title > a {
    background-color : #3D6889
}

.ls.ms .panel-title > a.collapsed {
    background-color : #FFFFFF
}

.ls.ms .panel-title > a.collapsed:hover, .ls.ms .panel-title > a:hover {
    background-color : #3D6889
}

.ls.ms .panel-body {
    background-color : #FFFFFF
}

.ls.ms .pagination a, .ls.ms .pagination span {
    background-color : #FFFFFF
}

.ls.ms .breadcrumb {
    color : #88ADB9
}

.ls.ms .breadcrumb a {
    color : #6897A6
}

.ls.ms .breadcrumb a:hover {
    color : #3D6889
}

.with_background input[type=text], .with_background input[type=email], .with_background input[type=url], .with_background input[type=password], .with_background input[type=search], .with_background input[type=tel], .with_background textarea, .with_background select, .with_background .form-control, .ls.ms input[type=text], .ls.ms input[type=email], .ls.ms input[type=url], .ls.ms input[type=password], .ls.ms input[type=search], .ls.ms input[type=tel], .ls.ms textarea, .ls.ms select, .ls.ms .form-control {
    background-color : #FFFFFF
}

.ds {
    background-color : #292A2E;
    color            : #C3D7E1
}

.ds.ms {
    background-color : #1A1A1A
}

.ds {
}

.ds h1, .ds h2, .ds h3, .ds h4, .ds h5, .ds h6 {
    color : #3D6889
}

.ds h1 a, .ds h2 a, .ds h3 a, .ds h4 a, .ds h5 a, .ds h6 a {
    color : inherit
}

.ds h1 a:hover, .ds h2 a:hover, .ds h3 a:hover, .ds h4 a:hover, .ds h5 a:hover, .ds h6 a:hover {
    color : rgba(61, 104, 137, .5)
}

.ds .ls {
    background-color : #FFFFFF;
    color            : #88ADB9
}

.ds .ls h1, .ds .ls h2, .ds .ls h3, .ds .ls h4, .ds .ls h5, .ds .ls h6 {
    color : #292A2E
}

.ds .ls h1 a, .ds .ls h2 a, .ds .ls h3 a, .ds .ls h4 a, .ds .ls h5 a, .ds .ls h6 a {
    color : inherit
}

.ds .ls h1 a:hover, .ds .ls h2 a:hover, .ds .ls h3 a:hover, .ds .ls h4 a:hover, .ds .ls h5 a:hover, .ds .ls h6 a:hover {
    color : #3D6889
}

.ds .ls a {
    color : #3D6889
}

.ds .ls a:hover {
    color : #292A2E
}

.ds a {
    color : #FFFFFF
}

.ds a:hover {
    color : #3D6889
}

.ds .highlight3 a, .ds a.highlight3 {
    color : #73D0F4
}

.ds .highlight3 a:hover, .ds a.highlight3:hover {
    color : #3D6889
}

.ds hr {
    background-color : rgba(178, 207, 229, .2)
}

.ds blockquote, .ds .blockquote {
    color : #FFFFFF
}

.ds blockquote .item-meta, .ds .blockquote .item-meta {
    color : #C3D7E1
}

.ds .blockquote-big:before, .ds .blockquote-big:after {
    color : #2C2C2C
}

.ds .theme_buttons a, .ds .theme_button {
    color : #FFFFFF
}

.ds .theme_buttons a:hover, .ds .theme_buttons a:focus, .ds .theme_button:hover, .ds .theme_button:focus {
    color : rgba(255, 255, 255, .5)
}

.ds .theme_buttons.inverse a, .ds .theme_button.inverse {
    border-color     : #FFFFFF;
    background-color : transparent;
    color            : #FFFFFF
}

.ds .theme_buttons.inverse a:after, .ds .theme_button.inverse:after {
    display : none
}

.ds .theme_buttons.inverse a:hover, .ds .theme_buttons.inverse a:focus, .ds .theme_button.inverse:hover, .ds .theme_button.inverse:focus {
    color : rgba(255, 255, 255, .5)
}

.ds .social-icon.color-icon:hover {
    color : #FFFFFF
}

.ds .muted_background, .ds .with_background, .ds .well {
    background-color : #383B3D
}

.ds.muted_section:before {
    background-color : #383B3D
}

.ds .with_border {
    border-color : rgba(178, 207, 229, .2)
}

.ds .highlight {
    color : #3D6889
}

.ds .highlight2 {
    color : #4E8DBD
}

.ds .highlight3 {
    color : #73D0F4
}

.ds .grey, .ds .black {
    color        : #FFFFFF;
    border-color : #FFFFFF
}

.ds .highlightlinks a {
    color : #3D6889
}

.ds .highlightlinks a:hover {
    opacity : .5
}

.ds .greylinks a {
    color : #C3D7E1
}

.ds .greylinks a:hover {
    color : #3D6889
}

.ds .darklinks a {
    color : #FFFFFF
}

.ds .darklinks a:hover {
    color : #3D6889
}

.ds.with_top_border:before, .ds.with_bottom_border:after {
    background-color : rgba(178, 207, 229, .2)
}

.ds input[type=text], .ds input[type=email], .ds input[type=url], .ds input[type=password], .ds input[type=search], .ds input[type=tel], .ds textarea, .ds select, .ds .form-control, .ds .select-group {
    color            : #FFFFFF;
    background-color : rgba(255, 255, 255, .1);
    border-color     : rgba(178, 207, 229, .2)
}

.ds form label + [class*=icon-] {
    color : #FFFFFF
}

.ds .form-group-select:before {
    border-color : rgba(178, 207, 229, .2)
}

.ds .form-control + i {
    border-color : rgba(178, 207, 229, .2)
}

.ds .select-group .form-control + i {
    background-color : rgba(178, 207, 229, .2)
}

.ds .form-control:-moz-placeholder {
    color : #88ADB9
}

.ds .form-control::-moz-placeholder {
    color : #88ADB9
}

.ds .form-control:-ms-input-placeholder {
    color : #88ADB9
}

.ds .form-control::-webkit-input-placeholder {
    color : #88ADB9
}

.ds .form-control:focus:-moz-placeholder {
    color : transparent
}

.ds .form-control:focus::-moz-placeholder {
    color : transparent
}

.ds .form-control:focus:-ms-input-placeholder {
    color : transparent
}

.ds .form-control:focus::-webkit-input-placeholder {
    color : transparent
}

.ds .contact-form.transparent-background .form-control {
    border-color : rgba(178, 207, 229, .2)
}

.ds .border-paragraphs p {
    color        : #FFFFFF;
    border-color : rgba(178, 207, 229, .2)
}

.ds .table, .ds .table-bordered, .ds .table th, .ds .table td {
    border-color : rgba(178, 207, 229, .2)
}

.ds .table-striped > tbody > tr:nth-child(odd), .ds .table-striped > tbody > tr:nth-child(odd) > td, .ds .table-striped > tbody > tr:nth-child(odd) > th {
    border-color     : rgba(178, 207, 229, .2);
    background-color : #383B3D
}

.ds .color-icon.bg-icon {
    background-color : #FFFFFF
}

.ds .color-icon.bg-icon:hover {
    color : #FFFFFF
}

.ds .color-bg-icon:hover {
    background-color : #3D6889
}

.ds .tab-content, .ds .nav-tabs > li > a {
    border-color : rgba(178, 207, 229, .2)
}

.ds .nav-tabs > li.active > a, .ds .nav-tabs > li.active > a:hover, .ds .nav-tabs > li.active > a:focus {
    border-color        : #3D6889;
    border-bottom-color : transparent;
    background-color    : #3D6889;
    color               : #FFFFFF
}

.ds .price-table.style2 {
    background-color : #383B3D
}

.ds .plan-name {
    background-color : #383B3D
}

.ds .plan-price p {
    color : #FFFFFF
}

.ds .features-list .disabled:before {
    color : #FFFFFF
}

.ds ul.list1 {
    color : #FFFFFF
}

.ds ul.list1 li {
    border-color : rgba(178, 207, 229, .2)
}

.ds .owl-theme .owl-dots .owl-dot span {
    border-color : #FFFFFF
}

.ds .owl-theme .owl-dots .owl-dot.active span {
    background-color : #FFFFFF
}

.ds .testimonials-carousel .media + p {
    background-color : #383B3D
}

.ds .side-item {
    border-color : rgba(178, 207, 229, .2)
}

.ds .entry-excerpt {
    color : #FFFFFF
}

.ds .comment-meta {
    color : #FFFFFF
}

.ds .comment-meta .author_url {
    color : #FFFFFF
}

.ds .comment-list article:after {
    background-color : rgba(178, 207, 229, .2)
}

.ds .comment-respond {
    border-color : rgba(178, 207, 229, .2)
}

.ds .entry-tags {
    border-color : rgba(178, 207, 229, .2)
}

.ds .widget_shopping_cart .cart_list, .ds .widget_recent_entries li, .ds .widget_recent_comments li, .ds .widget_archive li, .ds .widget_categories li, .ds .widget_meta li, .ds .widget_nav_menu li, .ds .widget_pages li, .ds .widget_popular_entries li {
    border-color : rgba(178, 207, 229, .2)
}

.ds .widget_nav_menu ul ul {
    border-color : rgba(178, 207, 229, .2)
}

.ds .widget_popular_entries .media-heading {
    color : #FFFFFF
}

.ds .tweet_list li + li .tweet_right {
    border-color : rgba(178, 207, 229, .2)
}

.ds .widget_tag_cloud a {
    border-color     : rgba(178, 207, 229, .2);
    background-color : #383B3D
}

.ds #calendar_wrap {
    border-color : rgba(178, 207, 229, .2)
}

.ds .widget_calendar table {
    border-color : rgba(178, 207, 229, .2)
}

.ds .widget_calendar tfoot td a {
    border-color : rgba(178, 207, 229, .2)
}

.ds .widget_calendar caption, .ds .widget_calendar thead, .ds .widget_calendar th {
    color : #FFFFFF
}

.ds .widget_calendar tbody td a:hover {
    color : #FFFFFF
}

.ds .grid-view h3:before, .ds .grid-view h3:after {
    background-color : rgba(178, 207, 229, .2)
}

.ds .owl-carousel.product-thumbnails .owl-nav > div:after {
    color : #FFFFFF
}

.ds .ui-slider {
    background-color : #383B3D
}

.ds .ui-slider .ui-slider-handle {
    background-color : #FFFFFF
}

.ds #sort_view {
    border-color : rgba(178, 207, 229, .2);
    color        : #FFFFFF
}

.ds #toggle_shop_view.grid-view:after, .ds #toggle_shop_view:before {
    background-color : #313335
}

.ds .widget_layered_nav .color-filters a:before {
    border-color : rgba(178, 207, 229, .2)
}

.ds .pagination > li > a, .ds .pagination > li > span {
    color            : #FFFFFF;
    background-color : #383B3D
}

.ds .pagination > li.active > a, .ds .pagination > li.active > span, .ds .pagination > li > a:hover, .ds .pagination > li > span:hover, .ds .pagination > li > a:focus, .ds .pagination > li > span:focus {
    background-color : #3D6889
}

.ds .thumbnail {
    background-color : transparent
}

.ds .thumbnail h3 a {
    color : #FFFFFF
}

.ds .thumbnail .caption {
    border-color : transparent
}

.ds .progress {
    background-color : rgba(178, 207, 229, .2)
}

.ds .panel-group .panel-heading + .panel-collapse .panel-body, .ds .panel-group.style2 .panel-heading .panel-title > a, .ds .panel-group.style2 .panel, .ds .panel-heading .panel-title > a:after, .ds .owl-theme .owl-nav > div {
    border-color : rgba(178, 207, 229, .2)
}

.ds .panel-group.style2 .panel-heading .panel-title > a.collapsed {
    border-color : transparent
}

.ds .vertical-tabs .nav > li > a {
    background-color : #383B3D
}

.ds .vertical-tabs .nav > li.active > a, .ds .vertical-tabs .nav > li > a:hover {
    background-color : #3D6889
}

.ds .entry-meta .comments-link a {
    color : #FFFFFF
}

.ds #timetable tbody th, .ds #timetable tbody td, .ds .item-meta-footer, .ds .item-meta-footer > div + div, .ds .day-cell + .day-cell, .ds .week-row {
    border-color : rgba(178, 207, 229, .2)
}

.ds .carousel-indicators li {
    border-color : #FFFFFF
}

.ds .carousel-indicators li.active {
    background-color : #FFFFFF
}

.cs.main_color2 {
    background-color : #4E8DBD
}

.cs.main_color2 .highlight {
    color : #3D6889
}

.cs {
    background-color : #3D6889;
    color            : #FFFFFF
}

.cs.main_bg_color2 {
    background-color : #4E8DBD
}

.cs.main_bg_color3 {
    background-color : #73D0F4
}

.cs h1, .cs h2, .cs h3, .cs h4, .cs h5, .cs h6 {
    color : #FFFFFF
}

.cs h1 a, .cs h2 a, .cs h3 a, .cs h4 a, .cs h5 a, .cs h6 a {
    color : #FFFFFF
}

.cs h1 a:hover, .cs h2 a:hover, .cs h3 a:hover, .cs h4 a:hover, .cs h5 a:hover, .cs h6 a:hover {
    color : rgba(255, 255, 255, .6)
}

.cs a {
    color : #FFFFFF
}

.cs a:hover {
    color : #91B4CE
}

.cs .small-text {
    color : #A7C6DE
}

.cs .breadcrumb > .active {
    color : #88ADB9
}

.cs blockquote, .cs .blockquote {
    border-color : #4D83AC
}

.cs .theme_buttons a[class*=color], .cs .theme_button[class*=color] {
    background-color : #FFFFFF;
    border-color     : #FFFFFF
}

.cs .theme_buttons a.color1, .cs .theme_button.color1 {
    color : #3D6889
}

.cs .theme_buttons a.color1:hover, .cs .theme_button.color1:hover {
    color : rgba(61, 104, 137, .7)
}

.cs .theme_buttons a.color2, .cs .theme_button.color2 {
    color : #4E8DBD
}

.cs .theme_buttons a.color2:hover, .cs .theme_button.color2:hover {
    color : rgba(78, 141, 189, .7)
}

.cs .theme_buttons a.color3, .cs .theme_button.color3 {
    color : #73D0F4
}

.cs .theme_buttons a.color3:hover, .cs .theme_button.color3:hover {
    color : rgba(115, 208, 244, .7)
}

.cs .theme_buttons a:hover, .cs .theme_buttons a:focus, .cs .theme_button:hover, .cs .theme_button:focus {
    color : rgba(255, 255, 255, .5)
}

.cs .theme_buttons.inverse a, .cs .theme_button.inverse {
    background-color : transparent;
    border-color     : rgba(255, 255, 255, .2);
    color            : #FFFFFF
}

.cs .theme_buttons.inverse a:hover, .cs .theme_buttons.inverse a:focus, .cs .theme_button.inverse:hover, .cs .theme_button.inverse:focus {
    color : rgba(255, 255, 255, .5)
}

.cs .with_background {
    background-color : rgba(255, 255, 255, .2)
}

.cs .with_border {
    border-color : rgba(255, 255, 255, .2)
}

.cs.with_top_border .container:before, .cs.with_bottom_border .container:after {
    background-color : #4D83AC
}

.cs .section_header:before {
    background-color : #4D83AC
}

.cs .greylinks a {
    color   : #FFFFFF;
    opacity : .7
}

.cs .greylinks a:hover {
    opacity : 1;
    color   : #FFFFFF
}

.cs .greylinks a.social-icon:hover {
    color : #FFFFFF
}

.cs .darklinks a.social-icon {
    color : #FFFFFF
}

.cs .darklinks a.social-icon:hover {
    color : #73D0F4
}

.cs .black {
    border-color : #FFFFFF;
    color        : #FFFFFF
}

.cs .grey {
    border-color : #292A2E;
    color        : #292A2E
}

.cs .response, .cs .highlight {
    color : #FFFFFF
}

.cs ul.list1 li {
    border-color : rgba(255, 255, 255, .2)
}

.cs [class*=soc-] {
    color        : #FFFFFF;
    border-color : #FFFFFF
}

.cs .color-icon.bg-icon {
    background-color : #FFFFFF
}

.cs a.social-icon.color-bg-icon:hover {
    background-color : #292A2E
}

.cs input[type=text], .cs input[type=email], .cs input[type=url], .cs input[type=password], .cs input[type=search], .cs input[type=tel], .cs textarea, .cs select, .cs .form-control, .cs .select-group {
    color            : #FFFFFF;
    background-color : rgba(255, 255, 255, .1);
    border-color     : rgba(255, 255, 255, .2)
}

.cs .form-control:-moz-placeholder {
    color : #D3E2EE
}

.cs .form-control::-moz-placeholder {
    font-style : italic;
    color      : #D3E2EE
}

.cs .form-control:-ms-input-placeholder {
    color : #D3E2EE
}

.cs .form-control::-webkit-input-placeholder {
    color : #D3E2EE
}

.cs .form-control:focus:-moz-placeholder {
    color : transparent
}

.cs .form-control:focus::-moz-placeholder {
    color : transparent
}

.cs .form-control:focus:-ms-input-placeholder {
    color : transparent
}

.cs .form-control:focus::-webkit-input-placeholder {
    color : transparent
}

.cs .pagination > li > a, .cs .pagination > li > span {
    color            : #FFFFFF;
    background-color : rgba(255, 255, 255, .2)
}

.cs .pagination > li.active > a, .cs .pagination > li.active > span, .cs .pagination > li > a:hover, .cs .pagination > li > span:hover, .cs .pagination > li > a:focus, .cs .pagination > li > span:focus {
    background-color : #FFFFFF;
    color            : #292A2E
}

.cs .widget_recent_entries li + li, .cs .widget_recent_comments li + li, .cs .widget_archive li + li, .cs .widget_categories li + li, .cs .widget_meta li + li, .cs .widget_popular_entries li + li, .cs .widget_nav_menu li + li, .cs .widget_pages li + li {
    border-top-color : rgba(255, 255, 255, .2)
}

.header_white {
    color            : #88ADB9;
    background-color : #FFFFFF
}

.header_white.affix {
    box-shadow : 0 2px 3px rgba(0, 0, 0, .1)
}

.header_white a.logo {
    color : #292A2E
}

.header_darkgrey {
    background-color : #292A2E
}

.header_darkgrey a[class*=soc-] {
    color : #FFFFFF
}

@media (max-width : 992px) {
    .page_header_wrapper .col-sm-12 {
        min-height : 0
    }
}

a.logo {
    display        : inline-block;
    word-break     : break-word;
    text-transform : uppercase;
    position       : relative;
    z-index        : 2
}

a.logo img + .logo_text {
    padding : 0 15px
}

.home a.logo img, footer a.logo img {
    max-width : 100%
}

@media (max-width : 991px) {
    a.logo.logo_image {
        max-width : 120px
    }
}

.header_gradient a.logo, .header_darkgrey a.logo {
    color : #FFFFFF
}

a.logo.bottom_logo {
    padding   : 0;
    font-size : 28px
}

a.logo.bottom_logo img {
    max-width : 200px
}

.floating_logo a.logo {
    float        : left;
    margin-right : 30px
}

a.logo.vertical_logo {
    padding : 0
}

.header_logo_center .logo_wrapper {
    position : absolute;
    left     : 0;
    right    : 0
}

.logo-meta {
    line-height : 1.2;
    display     : inline-block;
    padding     : 20px 0
}

@media (max-width : 1400px) {
    .logo + .logo-meta {
        display : none
    }
}

.logo-meta strong {
    font-size : 20px
}

.boxed .page_header .logo-meta {
    display : none
}

.lt-ie9 .page_header_wrapper {
    height : auto !important
}

.lt-ie9 .page_header_wrapper .md-hidden {
    display : inline-block
}

.page_header {
    top      : 0;
    left     : 0;
    right    : 0;
    position : relative;
    z-index  : 1000
}

.page_header.mobile-active {
    z-index : 1002
}

.page_header.affix {
    position : fixed
}

.page_header.affix-bottom {
    top      : 0 !important;
    position : fixed
}

.page_header a.header-button {
    display     : inline-block;
    color       : #88ADB9;
    padding     : 0 10px;
    line-height : 60px
}

@media (min-width : 1400px) {
    .page_header a.header-button {
        line-height : 80px;
        border-left : 1px solid rgba(136, 173, 185, .1);
        padding     : 0 30px
    }
}

.boxed .page_header a.header-button {
    padding     : 0 10px;
    line-height : 60px;
    border      : none
}

.page_header a.header-button i {
    opacity : .6
}

.page_header .social-icon:last-child {
    margin-right : 20px
}

.page_header .page_social {
    margin-top    : 14px;
    margin-bottom : 14px
}

.page_header.template_header {
    background-color : #FFFFFF
}

.page_header.template_header .header-block {
    margin-bottom : -45px
}

.page_header.template_header.affix {
    background-color : #3D6889
}

.page_header.template_header.affix .header-block {
    margin-bottom : 0
}

.ds + .page_header_wrapper .page_header.template_header {
    background-color : #292A2E
}

.ds + .page_header_wrapper .page_header.template_header.affix, .ds + .page_header_wrapper .page_header.template_header.subpage_header {
    background-color : #3D6889
}

.page_header.template_header.subpage_header {
    background-color : #3D6889
}

.page_header.template_header.subpage_header .header-block {
    margin-bottom : 0
}

.modal-open .page_header_wrapper, .modal-open .page_header.affix {
    right : 16px
}

#search_modal {
    color : #FFFFFF
}

#search_modal a {
    color          : #FFFFFF;
    opacity        : .6;
    font-size      : 12px;
    text-transform : uppercase
}

#search_modal a:hover {
    color   : #3D6889;
    opacity : 1
}

#search_modal .searchform-respond {
    width  : 300px;
    margin : 20px auto
}

#search_modal .form-control {
    border           : none;
    background-color : transparent;
    height           : 60px;
    color            : #FFFFFF
}

#search_modal .theme_button {
    padding : 18px
}

#search_modal .form-control:-moz-placeholder {
    color   : rgba(255, 255, 255, .8);
    opacity : 1
}

#search_modal .form-control::-moz-placeholder {
    color   : rgba(255, 255, 255, .8);
    opacity : 1
}

#search_modal .form-control:-ms-input-placeholder {
    color : rgba(255, 255, 255, .8)
}

#search_modal .form-control::-webkit-input-placeholder {
    color : rgba(255, 255, 255, .8)
}

body {
    position         : relative;
    left             : 0;
    background-color : #DDDDDD
}

body:after {
    content          : '';
    position         : fixed;
    top              : 0;
    left             : 0;
    right            : 0;
    bottom           : 0;
    background-color : rgba(0, 0, 0, .5);
    z-index          : 1001;
    visibility       : hidden;
    opacity          : 0
}

body, body:after, .page_header_side {
    -webkit-transition : all .8s ease-in-out;
    transition         : all .8s ease-in-out
}

body {
    -webkit-transition : left .8s ease-in-out, right .8s ease-in-out;
    transition         : left .8s ease-in-out, right .8s ease-in-out
}

body.active-side-header {
    left : 270px
}

body.active-side-header .page_header_side {
    left : 0
}

body.active-side-header:after {
    visibility : visible;
    opacity    : 1
}

.page_header_side {
    z-index  : 1002;
    position : fixed;
    width    : 270px;
    left     : -270px;
    top      : 0;
    bottom   : 0
}

.page_header_side.active-slide-side-header {
    box-shadow : 0 0 3px 3px rgba(50, 50, 50, .15)
}

.side_header_inner {
    padding : 60px 30px
}

.toggle_menu_side {
    position         : absolute;
    top              : 15px;
    right            : -65px;
    background-color : #FFFFFF;
    width            : 50px;
    height           : 50px;
    text-align       : center;
    line-height      : 50px;
    cursor           : pointer;
    box-shadow       : 0 0 4px 0 rgba(50, 50, 50, .5)
}

.toggle_menu_side:before {
    font-family : fontawesome;
    content     : "\f0c9"
}

.active-slide-side-header .toggle_menu_side:before, .active-side-header .toggle_menu_side:before {
    content : "\f00d"
}

.ds .toggle_menu_side {
    background-color : #292A2E;
    color            : #FFFFFF
}

@media (max-width : 350px) {
    .toggle_menu_side {
        top   : 5px;
        right : -55px
    }
}

.page_header_side.active-slide-side-header {
    left : 0
}

.page_header_side.header_side_right {
    left  : auto;
    right : -270px
}

.page_header_side.header_side_right.active-slide-side-header {
    right : 0
}

.page_header_side.header_side_right .toggle_menu_side {
    right : auto;
    left  : -65px
}

@media (max-width : 350px) {
    .page_header_side.header_side_right .toggle_menu_side {
        left : -50px
    }
}

body.active-side-header.slide-right {
    left : -270px
}

body.active-side-header.slide-right .page_header_side {
    left  : auto;
    right : 0
}

.mainmenu_side_wrapper ul ul, .mainmenu_side_wrapper .mega-menu {
    height   : 0;
    overflow : hidden
}

.mainmenu_side_wrapper ul li.sfHover > .mega-menu ul {
    display : block;
    height  : 100%
}

.mainmenu_side_wrapper .mega-menu > ul {
    height : auto
}

.menu-side-click {
    padding : 0
}

.menu-side-click li {
    position   : relative;
    list-style : none
}

.menu-side-click li a {
    margin-right : 25px
}

.menu-side-click li .activate_submenu {
    cursor     : pointer;
    position   : absolute;
    right      : 0;
    top        : 11px;
    font-size  : 16px;
    color      : #3D6889;
    text-align : center;
    width      : 25px
}

.menu-side-click li .activate_submenu:before {
    content : '+'
}

.menu-side-click li.active-submenu > .activate_submenu:before {
    content : '-'
}

.menu-side-click ul {
    padding-left       : 20px;
    opacity            : 0;
    height             : 0;
    overflow           : hidden;
    -webkit-transition : opacity .05s, height .05s ease;
    transition         : opacity .05s, height .05s ease
}

.menu-side-click .active-submenu > div {
    height : auto
}

.menu-side-click .active-submenu > ul, .menu-side-click .active-submenu > div > ul {
    display            : block;
    opacity            : 1;
    height             : auto;
    -webkit-transition : opacity .6s, height .6s ease;
    transition         : opacity .6s, height .6s ease
}

.scroll-wrapper {
    overflow : hidden;
    padding  : 0;
    position : relative;
    height   : 100%
}

.scroll-wrapper > .scroll-content {
    border     : none;
    box-sizing : content-box;
    height     : auto;
    left       : 0;
    margin     : 0;
    max-height : none;
    max-width  : none;
    overflow   : scroll;
    padding    : 0;
    position   : relative;
    top        : 0;
    width      : auto
}

.scroll-wrapper > .scroll-content::-webkit-scrollbar {
    height : 0;
    width  : 0
}

.scroll-wrapper.scroll--rtl {
    direction : rtl
}

.scroll-element {
    box-sizing : content-box;
    display    : none
}

.scroll-element div {
    box-sizing : content-box
}

.scroll-element .scroll-bar, .scroll-element .scroll-arrow {
    cursor : default
}

.scroll-element.scroll-x.scroll-scrollx_visible, .scroll-element.scroll-y.scroll-scrolly_visible {
    display : block
}

.scrollbar-macosx > .scroll-element, .scrollbar-macosx > .scroll-element div {
    background : 0 0;
    border     : none;
    margin     : 0;
    padding    : 0;
    position   : absolute;
    z-index    : 10
}

.scrollbar-macosx > .scroll-element div {
    display : block;
    height  : 100%;
    left    : 0;
    top     : 0;
    width   : 100%
}

.scrollbar-macosx > .scroll-element .scroll-element_track {
    display : none
}

.scrollbar-macosx > .scroll-element .scroll-bar {
    background-color   : rgba(127, 127, 127, .4);
    display            : block;
    -ms-filter         : "alpha(opacity=0)";
    filter             : alpha(opacity=0);
    opacity            : 0;
    border-radius      : 7px;
    -webkit-transition : opacity .2s linear;
    transition         : opacity .2s linear
}

.scrollbar-macosx:hover > .scroll-element .scroll-bar, .scrollbar-macosx > .scroll-element.scroll-draggable .scroll-bar {
    -ms-filter : "alpha(opacity=70)";
    filter     : alpha(opacity=70);
    opacity    : .7
}

.scrollbar-macosx > .scroll-element.scroll-x {
    bottom    : 0;
    height    : 0;
    left      : 0;
    min-width : 100%;
    overflow  : visible;
    width     : 100%
}

.scrollbar-macosx > .scroll-element.scroll-y {
    height     : 100%;
    min-height : 100%;
    right      : 0;
    top        : 0;
    width      : 0
}

.scrollbar-macosx > .scroll-element.scroll-x .scroll-bar {
    height    : 7px;
    min-width : 10px;
    top       : -9px
}

.scrollbar-macosx > .scroll-element.scroll-y .scroll-bar {
    left       : -9px;
    min-height : 10px;
    width      : 7px
}

.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_outer {
    left : 2px
}

.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_size {
    left : -4px
}

.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_outer {
    top : 2px
}

.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_size {
    top : -4px
}

.scrollbar-macosx > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
    left : -11px
}

.scrollbar-macosx > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
    top : -11px
}

.page_topline {
    position : relative;
    z-index  : 1001
}

.page_topline [class*=rt-icon] {
    font-size     : 18px;
    top           : .08em;
    position      : relative;
    padding-right : 6px;
    line-height   : inherit
}

@media (min-width : 768px) {
    .page_topline [class*=col-] > span {
        padding-left : 33px
    }

    .page_topline [class*=col-] > span:first-child {
        padding-left : 0
    }
}

@media (max-width : 767px) {
    .page_topline [class*=col-] > span {
        display : block
    }
}

.page_mainslider .flex-custom-nav {
    opacity : 0
}

.page_breadcrumbs {
}

.page_breadcrumbs .breadcrumbs_logo {
    margin-bottom : 10px
}

.page_breadcrumbs .breadcrumbs_logo img {
    opacity : .5
}

.page_breadcrumbs h1 {
    display        : inline;
    font-size      : 56px;
    text-transform : uppercase;
    word-wrap      : break-word
}

.page_breadcrumbs h1.small {
    font-size : 32px
}

@media (max-width : 991px) {
    .page_breadcrumbs h1 {
        font-size : 32px
    }
}

@media screen and (min-width : 992px) {
    .page_breadcrumbs .display_table_md {
        width     : auto;
        min-width : 0
    }

    .page_breadcrumbs h1.display_table_cell_md {
        padding-right : 20px
    }

    .page_breadcrumbs .breadcrumb.display_table_cell_md {
        border-left  : 1px solid rgba(255, 255, 255, .2);
        padding-left : 25px
    }
}

.page_breadcrumbs .bottom_breadcrumbs {
    text-align       : center;
    float            : right;
    padding          : 0 20px;
    margin           : 0 0 -26px;
    background-color : rgba(26, 26, 26, .035)
}

.page_portfolio {
    overflow : hidden
}

.section_subscribe .form-control {
    width     : 350px;
    max-width : 100%;
    display   : inline-block
}

#progress.parallax {
    background-image : url(../images/parallax/breadcrumbs.jpg)
}

.page_breadcrumbs.parallax {
    background-image : url(../images/parallax/breadcrumbs.jpg)
}

.page_testimonials {
    overflow : hidden
}

.page_testimonials.parallax {
    background-image : url(../images/parallax/testimonials.jpg)
}

.page_copyright p {
    margin : 0
}

.page_copyright i.fa {
    font-size : 14px
}

.page_copyright.table_section .bottom_logo img {
    margin-bottom : 0
}

.page_contact.parallax {
    background-image : url(../images/parallax/map.jpg)
}

.page_copyright.parallax, .page_footer.parallax {
    background-image : url(../images/parallax/bottom.jpg)
}

.ds.ms + .page_footer.ds.ms {
    background-color : #151515
}

#featured {
    background-image : url(../images/parallax/services.jpg);
    background-size  : cover
}

.section_subscribe.parallax {
    background-image : url(../images/parallax/booking-depart.jpg)
}

.features {
    background-image    : url(../images/parallax/features.jpg);
    background-position : center;
    background-size     : cover
}

#map, .page_map {
    height : 500px
}

#map img, .page_map img {
    max-width : inherit
}

#map p, .page_map p {
    line-height   : 1.3;
    margin-bottom : 0
}

#map .map_marker_description, .page_map .map_marker_description {
    padding : 10px 5px 5px
}

#map .map_marker_icon, .page_map .map_marker_icon {
    display : none
}

section .intro_section .slide_description_wrapper {
    padding-left  : 60px;
    padding-right : 60px;
    right         : 30px
}

@media (max-width : 600px) {
    section .intro_section .slide_description_wrapper {
        padding-left  : 5px;
        padding-right : 5px
    }
}

.muted_background .pagination {
    margin : 40px 40px 20px
}

@media (min-width : 992px) {
    .page_info_banners [class*=col-] {
        margin-top    : 10px;
        margin-bottom : 10px
    }

    #box_wrapper > .page_info_banners {
        overflow   : visible;
        margin-top : -21px;
        z-index    : 0
    }

    #box_wrapper > .page_info_banners + * {
        position : relative;
        z-index  : 2
    }

    .page_info_banners > .container {
        padding-top    : 0;
        padding-bottom : 0;
        position       : relative
    }

    .page_info_banners div[class*=col-] {
        position : relative
    }

    .page_info_banners .info_banner {
        position          : absolute;
        bottom            : -10px;
        overflow          : hidden;
        -webkit-transform : translateY(calc(100% - 90px));
        transform         : translateY(calc(100% - 90px));
        z-index           : 2
    }

    .page_info_banners .info_banner:hover {
        -webkit-transform : translateY(0);
        transform         : translateY(0)
    }
}

@media (min-width : 1400px) {
    .page_info_banners .row {
        position : absolute;
        bottom   : -10px;
        left     : 30px;
        right    : 30px;
        z-index  : 2
    }

    .page_info_banners .info_banner {
        position          : static;
        -webkit-transform : translateY(0);
        transform         : translateY(0)
    }
}

.page_services.parallax {
    background-image : url(../images/parallax/services.jpg)
}

.booking_departments {
    background-image : url(../images/parallax/booking-depart.jpg)
}

.template_footer .row + .row {
    position : relative
}

.template_footer .row + .row:before {
    content          : "";
    position         : absolute;
    display          : block;
    top              : 0;
    left             : 15px;
    right            : 15px;
    height           : 1px;
    background-color : rgba(255, 255, 255, .2)
}

.template_footer .widgets-row .widget {
    padding : 80px 0
}

.template_footer .widgets-row .widget.widget_button_map {
    background-image    : url(../images/continents.png);
    background-position : center;
    background-size     : contain;
    background-repeat   : no-repeat
}

@media (max-width : 991px) {
    .template_footer .widgets-row .widget {
        padding : 40px 0
    }
}

@media (max-width : 767px) {
    .template_footer .widgets-row .widget {
        padding-bottom : 0
    }

    .template_footer .widgets-row .widget.widget_button_map {
        padding-bottom : 40px
    }
}

.template_footer .row[class*=display_table_] {
    margin-right : -15px;
    margin-left  : -15px
}

.intro_section {
    position : relative;
    overflow : hidden;
    clear    : both
}

.intro_section > img {
    width : 100%
}

.intro_section .flexslider:before, .intro_section .flexslider:after {
    content : '';
    display : block;
    clear   : both
}

.intro_section .container {
    position     : absolute;
    top          : 0;
    bottom       : 0;
    left         : 0;
    right        : 0;
    margin-left  : auto;
    margin-right : auto
}

.intro_section [class*=col-] {
    position : static
}

.intro_section .slide_description_wrapper {
    position : absolute;
    top      : 0;
    bottom   : 0;
    right    : 15px;
    left     : 15px;
    height   : 100%;
    z-index  : 5;
    padding  : 70px 0
}

@media (min-width : 600px) {
    .boxed .intro_section .slide_description_wrapper {
        padding-left  : 60px;
        padding-right : 60px
    }
}

@media (min-width : 1400px) {
    .intro_section .slide_description_wrapper {
        padding : 0 0 250px
    }
}

.intro_section .slide_description_wrapper:before, .intro_section .slide_description {
    display        : inline-block;
    vertical-align : top
}

@media (min-width : 1400px) {
    .intro_section .slide_description_wrapper:before, .intro_section .slide_description {
        vertical-align : middle
    }
}

.intro_section .slide_description_wrapper:before {
    content : '';
    display : inline-block;
    width   : 0;
    height  : 100%
}

.intro_section .slide_description > div {
    margin : 15px 0
}

.intro_section .slide_description {
    position : relative
}

.intro_section .big {
    font-size   : 45px;
    font-weight : 700
}

@media (max-width : 991px) {
    .intro_section {
        text-align : center
    }

    .intro_section .nav-container {
        padding : 0
    }

    .intro_section .flex-control-nav, .intro_section .flex-custom-nav {
        bottom     : 13px;
        text-align : center
    }

    .intro_section .container {
        position  : static;
        width     : auto;
        max-width : 100%
    }

    .intro_section .slide_description_wrapper:before {
        display : none
    }

    .intro_section .slide_description {
        padding : 20px 20px 30px;
        display : block
    }

    .intro_section .slide_description_wrapper {
        padding  : 0;
        position : static
    }

    .intro_section .flex-direction-nav .flex-prev {
        top : 14%
    }

    .intro_section .flex-direction-nav .flex-next {
        top : 14%
    }
}

.sf-menu a {
    font-size      : 14px;
    text-transform : uppercase;
    font-weight    : 700
}

@media (min-width : 992px) {
    .sf-menu, .sf-menu ul {
        margin     : 0;
        padding    : 0;
        list-style : none
    }

    .sf-menu li {
        position : relative
    }

    .sf-menu li > ul {
        position : absolute;
        display  : none;
        top      : 100%;
        z-index  : 99
    }

    .sf-menu li:hover > ul, .sf-menu li.sfHover > ul {
        display : block
    }

    .sf-menu a {
        display  : block;
        position : relative
    }

    .sf-menu li > ul > li > ul {
        top  : -21px;
        left : 100%
    }

    .sf-menu a {
        padding                     : .4em 1.1em;
        text-decoration             : none;
        zoom                        : 1;
        -webkit-transition-property : color, background-color, border-color;
        transition-property         : color, background-color, border-color
    }

    .sf-menu li {
        white-space        : nowrap;
        *white-space       : normal;
        -webkit-transition : background .2s;
        transition         : background .2s
    }

    .sf-menu > li {
        display  : inline-block;
        position : relative;
        margin   : 0 -2px
    }

    .sf-menu > li > a {
        padding-top    : 34px;
        padding-bottom : 34px
    }

    .affix .sf-menu > li > a {
        padding-top    : 14px;
        padding-bottom : 14px
    }

    .subpage_header .sf-menu > li > a {
        padding-top    : 14px;
        padding-bottom : 14px
    }

    .sf-menu > li > a:hover, .sf-menu > li.active > a {
        color : #3D6889
    }

    .bordered_items .sf-menu {
        text-align : center
    }

    .bordered_items .sf-menu > li {
        padding : 0
    }

    .bordered_items .sf-menu > li + li:before {
        content          : '';
        width            : 1px;
        position         : absolute;
        left             : 0;
        top              : 0;
        bottom           : 0;
        background-color : rgba(178, 207, 229, .2)
    }

    .header_white .sf-menu > li > a {
        color : #88ADB9
    }

    .header_white .sf-menu > li > a:hover, .header_white .sf-menu > li.active > a {
        color : #3D6889
    }

    .header_white .sf-menu > li + li:before {
        background-color : #D3E4E8
    }

    .header_darkgrey .sf-menu > li > a {
        color : #FFFFFF
    }

    .header_darkgrey .sf-menu > li > a:hover, .header_darkgrey .sf-menu > li.active > a {
        color : #3D6889
    }

    .header_gradient .sf-menu > li > a, .header_color .sf-menu > li > a {
        color : #C3D7E1
    }

    .header_gradient .sf-menu > li > a:hover, .header_gradient .sf-menu > li.active > a, .header_color .sf-menu > li > a:hover, .header_color .sf-menu > li.active > a {
        color : #FFFFFF
    }

    .sf-menu li:hover, .sf-menu li.sfHover {
        -webkit-transition : none;
        transition         : none
    }

    .sf-menu > li .mega-menu, .sf-menu li > ul {
        margin-top       : 10px;
        text-align       : left;
        list-style       : none;
        padding          : 20px 0;
        background-color : #FFFFFF;
        border-radius    : 6px;
        box-shadow       : 0 2px 3px rgba(0, 0, 0, .1);
        min-width        : 12em;
        *width           : 12em
    }

    .sf-menu ul a {
        color         : #88ADB9;
        padding-left  : 30px;
        padding-right : 30px
    }

    .sf-menu .mega-menu ul {
        box-shadow : none;
        padding    : 0
    }

    .sf-menu .mega-menu ul a {
        padding-left  : 0;
        padding-right : 0
    }

    .sf-menu ul a:hover, .sf-menu ul li.active > a {
        color : #3D6889
    }

    .sf-menu ul ul {
        margin-top  : 0;
        margin-left : 1px
    }
}

@media (min-width : 992px) {
    .sf-menu > li > a {
        padding-left  : 2px;
        padding-right : 0
    }

    .bordered_items .sf-menu > li > a {
        padding-right : 35px;
        padding-left  : 35px
    }
}

@media (min-width : 992px) and (max-width : 1199px) {
    .sf-menu > li > a {
        padding-left  : 8px;
        padding-right : 8px
    }

    .bordered_items .sf-menu > li > a {
        padding-right : 20px;
        padding-left  : 24px
    }
}

@media (min-width : 1200px) {
    .sf-menu > li > a {
        padding-left  : 15px;
        padding-right : 15px
    }

    .boxed .col-md-6 .sf-menu > li > a {
        padding-left  : 14px;
        padding-right : 14px
    }
}

@media (min-width : 1400px) {
    .sf-menu > li > a {
        padding-left  : 15px;
        padding-right : 15px
    }
}

.toggle_menu {
    position    : absolute;
    top         : 0;
    left        : 50%;
    margin-left : -30px;
    display     : none;
    width       : 60px;
    height      : 50px;
    cursor      : pointer;
    z-index     : 1001;
    visibility  : visible
}

.header_white .toggle_menu span, .header_white .toggle_menu span:before, .header_white .toggle_menu span:after {
    background-color : #292A2E
}

.page_toplogo .toggle_menu {
    position         : fixed;
    top              : 0;
    left             : auto;
    right            : 0;
    background-color : #292A2E;
    margin           : 0
}

.toggle_menu_left .toggle_menu {
    left        : 15px;
    margin-left : 0
}

.toggle_menu_relative .toggle_menu {
    position      : relative;
    left          : 0;
    margin-left   : 0;
    margin-bottom : -6px
}

@media (max-width : 991px) {
    .toggle_menu_relative .toggle_menu {
        display : inline-block
    }
}

.toggle_menu:hover span:before {
    top : -7px
}

.toggle_menu:hover span:after {
    top : 7px
}

.toggle_menu span, .toggle_menu span:before, .toggle_menu span:after {
    display            : block;
    width              : 24px;
    height             : 2px;
    position           : absolute;
    background-color   : #FFFFFF;
    content            : '';
    left               : 0;
    -webkit-transition : all .2s ease-in-out 0s;
    transition         : all .2s ease-in-out 0s
}

.toggle_menu span {
    left       : 18px;
    top        : 50%;
    margin-top : -1px
}

.toggle_menu span:before {
    top : -6px
}

.toggle_menu span:after {
    top : 6px
}

@media (max-width : 991px) {
    .mainmenu_wrapper {
        position           : fixed;
        visibility         : hidden;
        z-index            : 10;
        top                : 0;
        left               : 0;
        bottom             : 0;
        right              : 0;
        background-color   : transparent;
        opacity            : 0;
        -webkit-transition : all .3s ease 0s;
        transition         : all .3s ease 0s
    }

    .mobile-active .mainmenu_wrapper {
        visibility       : visible;
        opacity          : 1;
        background-color : #111111;
        background-color : rgba(0, 0, 0, .9)
    }

    .toggle_menu {
        display : block
    }

    .toggle_menu.mobile-active {
        background-color : #292A2E;
        border           : none;
        left             : auto;
        top              : 0;
        right            : 0;
        position         : fixed;
        margin           : 0;
        z-index          : 3000
    }

    .toggle_menu.mobile-active:after {
        position    : absolute;
        top         : 18px;
        left        : 10px;
        font-family : rt-icons-2;
        content     : "\e117";
        font-size   : 40px;
        color       : #FFFFFF
    }

    .toggle_menu.mobile-active span {
        display : none
    }

    .nav-justified > li > a {
        text-align : left
    }

    .sf-menu {
        position           : fixed;
        overflow           : auto;
        background-color   : #292A2E;
        padding            : 10px 20px 10px 10px;
        top                : 0;
        left               : 0;
        bottom             : 0;
        right              : 100%;
        font-size          : 1.1em;
        text-transform     : uppercase;
        font-weight        : 400;
        word-wrap          : break-word;
        text-align         : left;
        -webkit-transition : all .4s ease-out 0s;
        transition         : all .4s ease-out 0s;
        opacity            : 0
    }

    .mobile-active .sf-menu {
        opacity : 1;
        right   : 20px
    }

    .sf-menu > li > a, .sf-menu a {
        color : #FFFFFF
    }

    .sf-menu ul {
        list-style   : none;
        padding-left : 10px
    }

    .sf-menu ul li a {
        display        : block;
        padding        : 10px;
        font-weight    : 300;
        font-size      : 16px;
        text-transform : none
    }

    .sf-menu ul li a:before {
        content       : '-';
        padding-right : 5px
    }

    .sf-menu ul ul li a:before {
        content       : '--';
        padding-right : 5px
    }

    .sf-arrows .sf-with-ul {
        padding-right  : 2.5em;
        *padding-right : 1em
    }

    .sf-arrows .sf-with-ul:after {
        font-family : fontawesome;
        content     : '\f107';
        position    : absolute;
        right       : 1.44em;
        height      : 0;
        width       : 0;
        font-size   : 20px
    }
}

@media (min-width : 992px) {
    .sf-menu .md-hidden {
        display : none
    }
}

@media (max-width : 991px) {
    #more-li {
        display : none
    }
}

.sf-menu i[class] {
    padding-right : 7px;
    font-size     : 1.1em
}

.mega-menu {
    border-radius : 6px
}

@media screen and (min-width : 992px) {
    .mainmenu_wrapper ul .mega-menu ul {
        min-width : auto
    }

    .mainmenu_wrapper .menu-item-has-mega-menu {
        position : relative
    }

    .mainmenu_wrapper .mega-menu {
        display  : none;
        position : absolute;
        width    : 940px;
        top      : 100%;
        z-index  : 10
    }

    .mainmenu_wrapper .menu-item-has-mega-menu:hover .mega-menu {
        display : block
    }

    .mainmenu_wrapper .mega-menu-row {
        position     : static;
        display      : table;
        table-layout : fixed
    }

    .mainmenu_wrapper .mega-menu-row ul {
        position : static;
        display  : block
    }

    .mainmenu_wrapper .mega-menu-col {
        display     : table-cell;
        width       : 2000px;
        padding     : 0 20px;
        border-left : 1px solid #D3E4E8
    }

    .mainmenu_wrapper .mega-menu-col > a {
        text-transform : uppercase;
        font-weight    : 900;
        margin-bottom  : 10px
    }

    .mainmenu_wrapper .mega-menu-col:first-child {
        border-left : none
    }

    .mainmenu_wrapper ul ul .mega-menu {
        width       : auto;
        top         : -20px;
        margin-left : 1px;
        display     : block;
        width       : auto
    }

    .mainmenu_wrapper ul ul .mega-menu li {
        display : block;
        width   : auto;
        border  : none
    }
}

@media screen and (min-width : 1200px) {
    .mainmenu_wrapper .mega-menu {
        width : 1140px
    }
}

@media screen and (max-width : 991px) {
    .mainmenu_wrapper .mega-menu {
        position : static
    }
}

.edit-link {
    text-align : right;
    display    : block
}

.not_found {
    font-size     : 100px;
    font-weight   : 700;
    line-height   : .75;
    margin-bottom : 5px
}

.not_found + h2 {
    color          : #88ADB9;
    font-size      : 35px;
    font-weight    : 500;
    text-transform : uppercase;
    margin         : 0 0 30px
}

.not_found + h2 + p {
    margin : 0 0 15px
}

.not_found + h2 + p + .widget_search {
    max-width : 370px;
    margin    : auto
}

.about-content {
    padding : 10px 10px 20px
}

.page-meta {
    padding : 9px 40px 30px
}

.page-meta h5 {
    font-size   : 14px;
    font-weight : 300
}

.page-meta i {
    line-height   : .6;
    padding-right : 8px
}

.page-meta .media {
    position      : relative;
    padding       : 28px 0 0;
    margin-bottom : 20px
}

.page-meta .media + .media:before {
    content          : '';
    position         : absolute;
    height           : 1px;
    width            : 100%;
    top              : 0;
    background-color : rgba(150, 150, 150, .1)
}

.page-meta .media-left {
    padding-right : 8px
}

article iframe {
    margin-bottom : -8px
}

h1.entry-title {
    font-size : 42px;
    margin    : 0 0 5px
}

@media (max-width : 991px) {
    h1.entry-title {
        font-size : 32px
    }
}

article.post + article.post, .search article + article, .archive article + article {
    margin-top : 80px
}

article.vertical-item.content-padding .item-content {
    padding : 35px
}

@media (max-width : 400px) {
    article.vertical-item.content-padding .item-content {
        padding : 35px 20px
    }
}

.item-meta-footer {
    font-size   : 0;
    line-height : 0;
    border-top  : 1px solid rgba(61, 104, 137, .2)
}

.item-meta-footer > div {
    padding     : 18px 0 15px;
    font-size   : 18px;
    line-height : 1.2;
    display     : inline-block;
    width       : 33.33333333%
}

.item-meta-footer > div + div {
    border-left : 1px solid rgba(61, 104, 137, .2)
}

.item-meta-footer > div i {
    opacity   : .5;
    font-size : 16px
}

#blog-gallery-slider .item-media {
    max-height : 600px
}

#blog-gallery-slider .vertical-item.content-absolute .item-content {
    padding : 0 15px 20px
}

#blog-gallery-slider .item-content {
    background-color : rgba(41, 42, 46, .3)
}

#blog-gallery-slider .item-content p {
    font-family    : yantramanav, sans-serif;
    font-size      : 20px;
    font-weight    : 500;
    letter-spacing : -.025em;
    line-height    : 1.2;
    text-transform : uppercase
}

#blog-gallery-slider .item-content p strong {
    font-size : 30px
}

@media (min-width : 768px) {
    #blog-gallery-slider .item-content p {
        font-size : 35px
    }

    #blog-gallery-slider .item-content p strong {
        font-size : 45px
    }
}

.format-status .media + br + a + div {
    margin-top : -10px
}

.format-status, .format-quote {
    background-image    : url(../images/status.jpg);
    background-size     : cover;
    background-position : center
}

.format-status.after_cover:after, .format-quote.after_cover:after {
    background-color : #292A2E;
    opacity          : .9
}

.format-chat ol {
    font-size     : 18px;
    list-style    : none;
    margin-bottom : 0
}

.format-chat ol li {
    margin : 14px 0
}

.format-chat ol .depth-2 {
    padding-left : 15px
}

.format-chat ol p {
    padding       : 3px 0;
    margin-bottom : 0
}

.mosaic-post p {
    margin-bottom : 0
}

.mosaic-post .post-social-links {
    padding : 18px 40px 17px
}

h3.entry-title {
    margin : 10px 0 14px
}

h3.entry-title + .date {
    display        : inline-block;
    text-transform : uppercase;
    line-height    : 1;
    padding        : 4px 14px;
    margin-bottom  : 22px
}

h4.entry-title {
    margin-top  : 0;
    line-height : 1
}

h2.search-title {
    margin : 0 0 30px
}

.item-meta + .entry-title {
    margin-bottom : 30px
}

.item-meta.meta-flex {
    display          : -webkit-box;
    display          : -ms-flexbox;
    display          : flex;
    -webkit-box-pack : justify;
    -ms-flex-pack    : justify;
    justify-content  : space-between;
    -ms-flex-wrap    : wrap;
    flex-wrap        : wrap;
    margin-bottom    : 33px
}

.item-meta.meta-flex + .entry-title {
    margin-bottom : 10px
}

.entry-tags {
    margin-top : 30px
}

.entry-tags .theme_button {
    margin-right : 6px
}

.entry-tags span + span {
    margin-left : 14px
}

.entry-tags i {
    font-size    : 14px;
    margin-right : 5px
}

.entry-meta:before, .entry-meta:after, .comment-meta:before, .comment-meta:after, .post-adds:before, .post-adds:after {
    display : block;
    clear   : both;
    content : '';
    width   : 0;
    height  : 0
}

.entry-thumbnail {
    text-align : center;
    position   : relative
}

.entry-thumbnail img {
    background-color : #FFFFFF
}

.entry-thumbnail[class*=col-] {
    margin-bottom : 0
}

.post-adds {
    margin        : 15px 0 33px;
    font-size     : 14px;
    border-radius : 6px;
    overflow      : hidden
}

.post-adds span {
    display : inline-block
}

.post-adds .item-likes {
    padding-left  : 20px;
    padding-right : 20px
}

.post-adds [class*=votes_count_] {
    padding : 0 20px
}

.post-adds .views-count, .post-adds .item-likes {
    padding     : 0 20px;
    line-height : 50px
}

.post-adds .theme_button {
    padding       : 17px 8px;
    min-width     : 0;
    margin-bottom : 0;
    border-radius : 0
}

.post-adds .theme_button i {
    font-size          : 16px;
    opacity            : .5;
    -webkit-transition : all .2s linear 0s;
    transition         : all .2s linear 0s
}

.post-adds .theme_button i.rt-icon2-arrow-forward {
    font-size : 22px
}

.post-adds .theme_button:hover {
    color : #FFFFFF
}

.post-adds .theme_button:hover i {
    opacity : 1
}

.post-adds .theme_button.inverse {
    padding            : 17px 11px;
    margin-left        : -4px;
    border-color       : transparent;
    border-right-color : rgba(61, 104, 137, .2)
}

.categories-links a + a {
    margin-left : 6px
}

.entry-content p.big-first-letter:first-letter {
    font-size    : 60px;
    line-height  : 60px;
    font-weight  : 700;
    color        : #3D6889;
    float        : left;
    margin-right : 10px
}

.entry-excerpt {
    font-size   : 20px;
    line-height : 30px;
    margin      : 8px 0 30px
}

.entry-excerpt p {
    margin-bottom : 16px
}

.blog-more-toggle {
    display          : block;
    text-transform   : uppercase;
    padding          : 9px 0;
    margin           : 32px 0 80px;
    background-color : rgba(128, 128, 128, .05)
}

.blog-more-toggle .blog-more-visible {
    display : inline
}

.blog-more-toggle .blog-more-hidden {
    display : none
}

.blog-more-toggle.collapsed .blog-more-visible {
    display : none
}

.blog-more-toggle.collapsed .blog-more-hidden {
    display : inline
}

.st_facebook_hcount, .st_twitter_hcount, .st_googleplus_hcount {
    position   : relative;
    text-align : right
}

.st_facebook_hcount span, .st_twitter_hcount span, .st_googleplus_hcount span {
    -ms-box-sizing : content-box;
    box-sizing     : content-box
}

.author-meta h4 {
    margin : 0 0 20px
}

.author-meta .item-content {
    position : relative;
    padding  : 30px 35px
}

@media (min-width : 992px) {
    .author-meta .item-content {
        padding-left : 35px
    }
}

@media (max-width : 400px) {
    .author-meta .item-content {
        padding : 30px 20px
    }
}

.event-item .item-content {
    padding : 35px
}

@media (max-width : 400px) {
    .event-item .item-content {
        padding : 30px 20px
    }
}

.event-item .item-content i {
    padding-right : 5px
}

.comments-area {
    margin-top : 28px
}

.comments-area h2 {
    margin-bottom : 28px;
    font-size     : 40px;
    font-weight   : 700
}

.comments-area li {
    list-style  : none;
    padding-top : 7px
}

.comments-area .media {
    position : relative
}

.comments-area .media-left {
    padding-right : 0
}

.comments-area .media-left img {
    max-width     : 70px;
    border-radius : 50%
}

.comments-area .media-object {
    max-width : 70px
}

.comments-area .media-body {
    padding : 15px 0 26px 20px
}

.comment-list {
    margin   : 0;
    padding  : 0;
    position : relative
}

.comment-list ol {
    padding-left : 15px
}

@media (min-width : 992px) {
    .comment-list ol {
        padding-left : 90px
    }
}

.comment-meta {
    line-height    : 1;
    margin-bottom  : 0;
    padding-bottom : 32px;
    padding-right  : 50px
}

.comment-meta .comment-metadata {
    display : block;
    margin  : 7px 0 10px
}

.comment-meta .comment-date {
    display : block
}

.comment-meta .author_url {
    font-family    : yantramanav, sans-serif;
    font-weight    : 700;
    font-size      : 25px;
    text-transform : uppercase;
    letter-spacing : -.025em
}

@media (max-width : 991px) {
    .comment-meta .author_url {
        font-size : 18px
    }
}

.reply a.theme_button {
    position   : absolute;
    right      : 0;
    top        : 5px;
    padding    : 12px 7px;
    color      : #88ADB9;
    font-size  : 0;
    text-align : center;
    opacity    : .4
}

.reply a.theme_button:hover {
    opacity : 1
}

.reply a.theme_button i {
    top         : 0;
    font-size   : 24px;
    line-height : 1
}

.comment-respond {
    margin-top : 75px
}

.comment-respond h3 {
    font-size : 30px;
    margin    : 0 0 24px
}

.comment-respond p {
    margin-bottom : 10px;
    position      : relative
}

.comment-form.muted_background {
    padding : 30px 30px 20px
}

.comment-form p {
    margin-bottom : 0
}

.comment-form .form-submit {
    margin-top : 35px
}

.comment-form label[for] {
    display : none
}

.comment-form .theme_button {
    margin-right : 6px
}

@media (max-width : 500px) {
    .comment-respond i.pull-left {
        display : none
    }

    .comment-respond .comment-form {
        margin-left : 0
    }
}

.blog-buttons {
    line-height : 0;
    margin-top  : 75px
}

.blog-buttons .item-content {
    line-height        : normal;
    padding            : 0 10px !important;
    background-color   : rgba(41, 42, 46, .5);
    -webkit-transition : all .2s ease-in-out 0s;
    transition         : all .2s ease-in-out 0s
}

.blog-buttons .vertical-item:hover .item-content {
    background-color : rgba(41, 42, 46, .7)
}

#isotope_filters, .filters {
    margin     : 0 0 40px;
    padding    : 0;
    list-style : none
}

#isotope_filters li, .filters li {
    display : inline-block;
    margin  : 0;
    padding : 0
}

.gallery-item {
    overflow : hidden;
    position : relative
}

.gallery-item .item-content {
    padding : 0 40px 5px
}

.gallery-item h4.item-meta {
    font-weight        : 300;
    margin             : 0;
    font-size          : 20px;
    z-index            : 5;
    -webkit-transition : all .15s ease-in-out;
    transition         : all .15s ease-in-out;
    -ms-filter         : "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter             : alpha(opacity=0);
    opacity            : 0;
    -webkit-transform  : scale(0);
    transform          : scale(0)
}

.gallery-item:hover h4.item-meta {
    -webkit-transform : scale(1);
    transform         : scale(1);
    -ms-filter        : "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter            : alpha(opacity=100);
    opacity           : 1
}

.gallery-item .post-adds {
    margin-bottom : 0
}

.gallery-title-item + .item-title {
    padding : 30px 0
}

.gallery-title-item + .item-title h3 {
    font-size     : 28px;
    margin-bottom : 5px
}

i.loadmore_spinner {
    font-size : 30px;
    opacity   : .3;
    margin    : 10px 0 20px
}

.gallery-single-title {
    margin : 12px 0 0
}

@media (min-width : 768px) {
    .gallery-single-title {
        font-size : 60px
    }
}

.gallery-single-title + .block-header {
    margin-bottom : 40px
}

.share-gallery-single {
    margin : 40px 0 16px
}

.items-nav {
    padding : 30px 23px
}

.items-nav[class*=display_table_] {
    margin-top : 30px
}

.items-nav h4 {
    margin : -5px 0 0
}

.items-nav .media {
    position : relative;
    padding  : 10px 0
}

.items-nav .next-item img {
    max-width : none;
    padding   : 0 30px 0 0
}

.items-nav .next-item i {
    right : 0
}

.items-nav .prev-item img {
    max-width : none;
    padding   : 0 0 0 30px
}

.items-nav a {
    position : relative
}

.items-nav a i {
    top        : 50%;
    margin-top : -14px;
    font-size  : 20px
}

.items-nav a i.position-absolute {
    position : absolute
}

#gallery-owl-carousel .owl-dots {
    margin-top : 30px
}

.owl-carousel.hover-scale {
    margin-bottom : 50px
}

@media (min-width : 768px) {
    .owl-carousel.hover-scale .owl-stage-outer {
        overflow : visible
    }

    .owl-carousel.hover-scale .vertical-item {
        -webkit-transition : all .2s ease-in;
        transition         : all .2s ease-in
    }

    .owl-carousel.hover-scale .owl-item:hover .vertical-item, .owl-carousel.hover-scale .owl-item.center .vertical-item {
        -webkit-transform : scale(1.1);
        transform         : scale(1.1);
        z-index           : 4;
        overflow          : hidden
    }
}

.owl-carousel.related-photos-carousel {
    margin-bottom : 0
}

.cart-button {
    margin : 50px 0 55px
}

.share-title {
    font-size : 20px;
    margin    : 56px 0 18px
}

.shop-item .item-content {
    padding : 30px 40px
}

.shop-item .item-price {
    margin      : 9px 0 16px;
    font-size   : 30px;
    line-height : 1;
    font-weight : 800
}

.shop-item .item-price span {
    font-size : 40px
}

.shop-item .cart-button {
    margin : 50px 0 28px
}

.related-item .item-content {
    padding : 14px 25px
}

.related-item h4 {
    font-size   : 20px;
    line-height : 24px
}

.related-item .cart-button {
    margin : 14px 0
}

aside > div + div {
    margin-top : 35px
}

aside .widget + .widget {
    margin-top : 35px
}

.affix-aside {
    position : relative
}

.affix-aside.affix {
    position : fixed;
    top      : 50px
}

@media (max-width : 767px) {
    .affix-aside, .affix-aside.affix, .affix-aside.affix-bottom {
        position : static;
        width    : inherit !important
    }
}

.module-header {
    margin         : 0 0 38px;
    text-transform : capitalize;
    font-size      : 30px
}

.page_footer .module-header {
    font-size : 28px
}

.widget > h3, .widget-title {
    font-size  : 25px;
    margin     : 0 0 18px;
    position   : relative;
    text-align : center
}

.widget ul {
    list-style : none;
    padding    : 0;
    margin     : 0
}

.widget ul ul li {
    padding-left : 20px
}

.widget li {
    position : relative
}

.widget .vertical-item h4 {
    margin : 17px 0 0
}

.widget .vertical-item + .widget .vertical-item {
    margin-top : 16px
}

.page_footer .widget-title {
    font-size : 30px
}

.widget_mailchimp {
    overflow : hidden
}

.widget_mailchimp button[class*=main_bg_color]:hover {
    background-color : #3D6889
}

.widget_mailchimp p {
    padding-top : 10px
}

.widget_search .form-inline, .widget_mailchimp .form-inline {
    position : relative
}

.widget_search .form-inline .form-group, .widget_mailchimp .form-inline .form-group {
    display        : block;
    vertical-align : top;
    position       : relative
}

.widget_search .form-inline input[type=text], .widget_mailchimp .form-inline input[type=email] {
    padding-right : 50px;
    display       : block;
    width         : 100%
}

.widget_search .form-inline .theme_button, .widget_mailchimp .form-inline .theme_button {
    font-size : 0;
    padding   : 12px;
    margin    : 0
}

.widget_search .form-inline .theme_button, .widget_mailchimp .form-inline .theme_button {
    position         : absolute;
    right            : 13px;
    top              : 0;
    border           : none;
    background-color : transparent;
    color            : #3D6889;
    padding          : 9px;
    opacity          : .5
}

.widget_search .form-inline .theme_button i, .widget_mailchimp .form-inline .theme_button i {
    position    : relative;
    font-size   : 16px;
    line-height : 30px
}

.widget_search .form-inline .theme_button:hover, .widget_mailchimp .form-inline .theme_button:hover {
    opacity : 1
}

.cs .widget_search .form-inline .theme_button, .cs .widget_mailchimp .form-inline .theme_button {
    color : #FFFFFF
}

.widget_mailchimp .form-inline .theme_button i {
    top : 2px
}

.widget_mailchimp .form-inline .theme_button:before {
    content : "\e65c"
}

.widget_recent_comments li {
    padding : 15px 0
}

.widget_recent_comments li:first-child {
    padding-top : 0
}

.widget_recent_comments li:before {
    font-family   : rt-icons-2;
    content       : "\e65c";
    font-size     : 16px;
    line-height   : 1;
    position      : relative;
    top           : 4px;
    padding-right : 10px;
    color         : #3D6889
}

.widget_recent_entries li {
    padding : 10px 0
}

.widget_recent_entries p {
    margin-bottom : 0
}

.widget_recent_entries li:first-child {
    padding-top : 0
}

.widget_recent_entries .media {
    margin-top : 0
}

.widget_recent_entries .media-left {
    padding-right : 20px
}

.widget_recent_entries h5 {
    font-size   : 17px;
    font-weight : 600;
    line-height : 20px;
    margin      : 0 0 5px
}

.widget_recent_comments li, .widget_archive li, .widget_categories li, .widget_meta li, .widget_popular_entries li, .widget_nav_menu li, .widget_pages li {
    border-top : 1px solid rgba(61, 104, 137, .2)
}

.widget_recent_entries > ul > li:first-child, .widget_recent_comments > ul > li:first-child, .widget_archive > ul > li:first-child, .widget_categories > ul > li:first-child, .widget_meta > ul > li:first-child, .widget_popular_entries > ul > li:first-child, .widget_nav_menu > ul > li:first-child, .widget_pages > ul > li:first-child {
    border-top : none
}

.widget_archive a, .widget_categories a, .widget_meta a, .widget_nav_menu a, .widget_pages a {
    display  : inline-block;
    padding  : 12px 0 12px 16px;
    position : relative
}

.widget_archive a:before, .widget_categories a:before, .widget_meta a:before, .widget_nav_menu a:before, .widget_pages a:before {
    font-family : rt-icons-2;
    content     : "\e7c1";
    font-size   : 18px;
    color       : #3D6889;
    position    : absolute;
    left        : -3px
}

.widget_archive span, .widget_categories span {
    position : absolute;
    right    : 0;
    top      : .8em
}

.widget_nav_menu ul ul li:first-child a {
    padding-top : 13px
}

.widget_nav_menu a i {
    padding-right : 7px
}

@media (min-width : 768px) {
    .page_copyright .widget_nav_menu li, .page_topline .widget_nav_menu li {
        display       : inline-block;
        position      : relative;
        border        : none;
        padding-right : 5px
    }

    .page_copyright .widget_nav_menu li:before, .page_topline .widget_nav_menu li:before {
        content          : '';
        position         : absolute;
        padding          : 0;
        left             : -5px;
        top              : 5px;
        bottom           : 5px;
        width            : 1px;
        background-color : rgba(61, 104, 137, .2);
        opacity          : .3;
        display          : block
    }

    .page_copyright .widget_nav_menu li:first-child:before, .page_topline .widget_nav_menu li:first-child:before {
        display : none
    }

    .page_copyright .widget_nav_menu a, .page_topline .widget_nav_menu a {
        padding : 0 10px
    }

    .page_copyright .widget_nav_menu a:before, .page_topline .widget_nav_menu a:before {
        display : none
    }
}

.widget_tag_cloud a {
    border           : 1px solid rgba(61, 104, 137, .2);
    border-radius    : 2px;
    padding          : 1px 10px;
    margin           : 0 1px 5px 0;
    font-size        : 11px;
    line-height      : 26px;
    text-transform   : uppercase;
    font-weight      : 700;
    background-color : #F3F8F9;
    display          : inline-block
}

.ls .widget_tag_cloud a, .ds .widget_tag_cloud a {
    color : #88ADB9
}

.ls .widget_tag_cloud a:hover, .ds .widget_tag_cloud a:hover {
    color : #FFFFFF
}

.widget_tag_cloud a:hover {
    background-color : #3D6889;
    border-color     : #3D6889;
    color            : #FFFFFF
}

.widget_flickr a {
    display : inline-block;
    width   : 25%
}

.widget_flickr ul#flickr {
    margin : -5px
}

.widget_flickr img {
    width         : 100%;
    padding       : 5px;
    border-radius : 1px
}

.widget_popular_entries p {
    line-height   : 24px;
    margin-bottom : 10px
}

.widget_popular_entries .media-body {
    overflow : visible
}

.widget_popular_entries .media-left {
    padding-right : 20px
}

.widget_popular_entries .media-object {
    max-width : 100px
}

@media (min-width : 768px) and (max-width : 1199px) {
    .widget_popular_entries .media-object {
        max-width : 40px
    }
}

.widget_popular_entries li {
    margin-top : 2px;
    padding    : 20px 0 18px
}

.widget_popular_entries li:first-child {
    padding-top : 0
}

.widget_popular_entries li:last-child {
    padding-bottom : 0
}

.widget_banner {
    overflow    : hidden;
    line-height : 1
}

.widget_banner .item-content {
    padding : 20px
}

.widget_banner .item-content h3 {
    margin    : 0;
    padding   : 8px 0;
    font-size : 28px
}

.widget_banner .item-content span {
    display        : inline-block;
    text-transform : uppercase;
    padding        : 4px 10px 1px;
    min-width      : 120px
}

.widget_banner .small-text {
    margin-bottom : 0
}

.widget_slider .owl-carousel {
    margin-bottom : 0
}

.widget_slider .owl-carousel:hover .owl-nav {
    top : -43px
}

.widget_slider .owl-carousel .owl-nav {
    opacity : 1;
    top     : -43px
}

.widget_slider .owl-carousel .owl-nav > div {
    width       : 25px;
    height      : 25px;
    line-height : 25px
}

.widget_slider .owl-carousel .owl-nav > div:first-child {
    left  : auto;
    right : 30px
}

.widget_slider .owl-carousel .owl-nav > div:after {
    font-size   : 14px;
    line-height : 23px
}

#calendar_wrap {
    width                      : 100%;
    margin-bottom              : 0;
    -webkit-overflow-scrolling : touch;
    -ms-overflow-style         : -ms-autohiding-scrollbar
}

@media (min-width : 768px) and (max-width : 992px) {
    #calendar_wrap {
        overflow-x : auto;
        overflow-y : hidden
    }
}

.widget_calendar table {
    width      : 100%;
    position   : relative;
    text-align : center
}

.widget_calendar caption {
    line-height      : 1;
    font-size        : 24px;
    padding          : 18px 0;
    text-align       : center;
    color            : #FFFFFF;
    background-color : #3D6889
}

.widget_calendar thead {
    background-color : #292A2E;
    border           : 1px solid #292A2E;
    border-width     : 7px 1px;
    color            : #FFFFFF
}

.widget_calendar thead th {
    font-weight : 300
}

.widget_calendar thead th:first-child {
    padding-left : 30px
}

.widget_calendar thead th:last-child {
    padding-right : 30px
}

.widget_calendar tbody {
    font-weight : 300
}

.widget_calendar tbody tr:first-child td {
    padding-top : 29px
}

.widget_calendar tbody tr:last-child td {
    padding-bottom : 30px
}

.widget_calendar tbody td {
    border   : none;
    padding  : 3px 4px;
    position : relative
}

.widget_calendar tbody td:first-child {
    padding-left : 30px
}

.widget_calendar tbody td:last-child {
    padding-right : 30px
}

.widget_calendar tbody td a {
    font-weight : 700;
    color       : #3D6889
}

.widget_calendar tbody td a:hover {
    color : #4E8DBD
}

.widget_calendar th {
    text-align : center
}

.widget_calendar tfoot {
    position : absolute;
    top      : -3px;
    right    : 0;
    left     : 0
}

.widget_calendar tfoot td {
    position : absolute;
    top      : 0;
    padding  : 0
}

.widget_calendar tfoot td a {
    color       : #FFFFFF;
    text-align  : center;
    font-size   : 0;
    width       : 60px;
    height      : 60px;
    line-height : 60px;
    display     : inline-block
}

.widget_calendar tfoot td a:hover {
    color            : #FFFFFF;
    background-color : #3D6889;
    border-color     : #3D6889
}

.widget_calendar tfoot #prev {
    left : 0
}

.widget_calendar tfoot #prev a {
    border-right : 1px solid rgba(255, 255, 255, .2)
}

.widget_calendar tfoot #prev a:before {
    font-size   : 14px;
    font-family : rt-icons-2;
    content     : '\e7c4'
}

.widget_calendar tfoot #next {
    right : 0
}

.widget_calendar tfoot #next a {
    border-left : 1px solid rgba(255, 255, 255, .2)
}

.widget_calendar tfoot #next a:after {
    font-size   : 14px;
    font-family : rt-icons-2;
    content     : '\e7c5'
}

.tweet_avatar {
    float : left
}

.tweet_right {
    overflow    : hidden;
    zoom        : 1;
    margin-left : 60px
}

.tweet_list li + li {
    margin-top : 10px;
    clear      : both
}

.tweet_list li + li .tweet_right {
    border-top : 1px solid rgba(61, 104, 137, .2)
}

.tweet_list li + li .tweet_right, .tweet_list li + li .tweet_avatar {
    padding-top : 10px
}

.widget_schedule ul {
    margin : 0
}

.widget_tabs .nav-tabs > li > a {
    padding : 10px 15px
}

.widget_rss li {
    margin-bottom : 15px
}

.widget_rss li .rsswidget {
    font-size : 1.1em
}

.widget_rss .rss-date {
    display     : block;
    font-weight : 700
}

.widget_rss cite {
    display     : block;
    text-align  : right;
    font-weight : 700
}

.small-teaser {
    line-height : 20px
}

.small-teaser i {
    line-height : inherit
}

.teaser {
    position : relative;
    overflow : visible
}

.teaser[class*=_bg_color], .teaser[class*=with_] {
    padding : 36px 55px
}

@media (max-width : 500px) {
    .teaser[class*=_bg_color], .teaser[class*=with_] {
        padding : 16px 20px
    }
}

.teaser [class*=_bg_color] {
    width          : 2em;
    height         : 2em;
    text-align     : center;
    vertical-align : bottom
}

.teaser [class*=_bg_color] + h4 {
    margin-top : 24px
}

.teaser [class*=_bg_color] i {
    line-height : 2em
}

.teaser .border_icon {
    width        : 2em;
    height       : 2em;
    border-width : 3px;
    border-style : solid
}

.teaser .border_icon i {
    line-height : 2em;
    display     : block;
    margin      : -3px 0 0
}

.teaser i {
    text-align     : center;
    vertical-align : middle;
    display        : inline-block
}

.teaser h2 {
    font-size      : 50px;
    font-weight    : 400;
    margin-bottom  : 33px;
    position       : relative;
    padding-bottom : 10px
}

.teaser h2:after {
    content          : '';
    position         : absolute;
    width            : 50px;
    margin-left      : -25px;
    height           : 1px;
    background-color : rgba(204, 204, 204, .2);
    bottom           : -12px;
    left             : 50%
}

.teaser h3 {
    margin-bottom : 16px;
    font-size     : 24px
}

.teaser img + h4 {
    margin : 32px 0 13px
}

.teaser .media-left {
    padding-right : 20px
}

@media (max-width : 500px) {
    .teaser .media-left {
        padding-right : 20px
    }
}

.teaser .media-body h3 {
    margin-bottom : 20px;
    font-size     : 28px
}

.teaser .media-body h4 {
    margin-bottom : 6px
}

@media (max-width : 500px) {
    .teaser h2, .teaser h3, .teaser h4 {
        font-size : 1.4em
    }

    .teaser h2 span, .teaser h3 span, .teaser h4 span {
        font-size : 1.2em
    }
}

.teaser [class*=count] {
    font-size   : 56px;
    font-weight : 700;
    margin      : 17px 0 6px
}

.teaser [class*=count] + p {
    margin    : 0;
    font-size : 20px
}

.teaser .theme_button {
    margin : 22px 0 0
}

.section_padding_bottom_0 .teaser {
    margin-bottom : 0
}

.teaser_icon {
    display            : inline-block;
    line-height        : 1;
    -webkit-transition : all .2s ease-in-out 0s;
    transition         : all .2s ease-in-out 0s
}

.teaser_icon + p {
    margin-top : 27px
}

.teaser:hover .teaser_icon {
    -webkit-animation : scaleDisappear .5s;
    animation         : scaleDisappear .5s
}

.img-media-teaser .media-left {
    padding-right : 30px
}

.img-media-teaser .media-left img {
    width     : 70px;
    max-width : 70px
}

@media (min-width : 1200px) {
    .borders-angle {
        position : relative
    }

    .borders-angle:before, .borders-angle:after {
        content           : '';
        position          : absolute;
        width             : 1px;
        top               : -30px;
        bottom            : 28px;
        left              : -1px;
        background-color  : rgba(26, 26, 26, .1);
        -webkit-transform : rotate(15deg);
        transform         : rotate(15deg)
    }

    .borders-angle:after {
        left  : auto;
        right : -5px
    }
}

.size_big {
    font-size : 64px
}

.size_normal {
    font-size : 42px
}

.size_small {
    font-size : 32px
}

.contact-teaser {
    line-height : 1.08em
}

.media-left.fontsize_16 {
    padding-top : 2px
}

.media-left.fontsize_16 i {
    display    : inline-block;
    width      : 16px;
    text-align : center;
    opacity    : .7
}

.media-left.fontsize_16 i.rt-icon2-phone3 {
    font-size : 20px;
    position  : relative;
    top       : -2px;
    left      : -2px
}

.media-body.small-text2 {
    line-height : 17px;
    padding-top : 2px
}

.teaser.table_section {
    margin-top : 26px
}

.teaser.table_section + .teaser.table_section {
    margin-top : 70px
}

@media (min-width : 768px) {
    .teaser.table_section [class*=col-] > * {
        margin : 0;
        top    : 0
    }
}

@media (max-width : 767px) {
    .teaser.table_section [class*=col-] * {
        text-align : center
    }
}

.bg_teaser {
    background-size     : cover;
    background-repeat   : no-repeat;
    background-position : 50% 0;
    position            : relative;
    color               : #FFFFFF;
    overflow            : hidden
}

.bg_teaser .bg_overlay {
    position : absolute;
    width    : 100%;
    height   : 1000px
}

.bg_teaser .highlight {
    color : #FFFFFF
}

.bg_teaser h4 + h3 {
    margin-top : -14px;
    font-size  : 50px
}

.bg_teaser.rectangular .teaser_content {
    padding : 200px 30px 50px
}

.bg_teaser.rectangular .teaser_content h3 {
    font-size : 40px
}

.bg_teaser > img {
    display    : none;
    visibility : hidden
}

.bg_teaser .teaser_content {
    margin  : 0;
    padding : 40px 40px 35px;
    z-index : 2
}

.bg_teaser .teaser_content > .media-left {
    padding-right : 35px
}

.bg_teaser .teaser_content.media {
    padding : 75px 40px 60px
}

.color_bg_1 .bg_overlay {
    background-color : rgba(61, 104, 137, .85)
}

.color_bg_2 .bg_overlay {
    background-color : rgba(115, 208, 244, .9)
}

.color_bg_3 .bg_overlay {
    background-color : rgba(78, 141, 189, .9)
}

.darkgrey_bg .bg_overlay {
    background-color : rgba(41, 42, 46, .8)
}

.square_teaser .item-media:after {
    content          : '';
    position         : absolute;
    top              : 0;
    left             : 0;
    right            : 0;
    bottom           : 0;
    background-color : rgba(41, 42, 46, .8)
}

.square_teaser .item-content {
    top      : 0;
    margin   : 30px 0 80px;
    overflow : hidden
}

.square_teaser .teaser_icon {
    color    : #3D6889;
    position : absolute;
    bottom   : 30px;
    left     : 30px
}

.square_teaser .item-media:after, .square_teaser .teaser_icon, .square_teaser h4, .square_teaser p {
    -webkit-transition : all .2s ease-in-out 0s;
    transition         : all .2s ease-in-out 0s
}

.square_teaser h4 {
    color       : #FFFFFF;
    font-size   : 28px;
    font-weight : 700
}

.square_teaser p {
    color   : #FFFFFF;
    opacity : 0
}

.square_teaser:hover h4, .square_teaser:hover .teaser_icon {
    color : #292A2E
}

.square_teaser:hover p {
    opacity : 1
}

.square_teaser:hover .item-media:after {
    background-color : #3D6889
}

.square_teaser.no-image .item-content {
    margin : 0
}

.square_teaser.no-image .teaser_icon {
    position : static
}

.square_teaser.no-image p {
    opacity : 1
}

.testimonials-carousel {
    padding : 0 0 20px
}

.testimonials-carousel .item > p {
    font-style    : italic;
    padding-left  : 40px;
    margin-bottom : 40px;
    position      : relative
}

.testimonials-carousel .item > p:before {
    font-family : pt sans, sans-serif;
    font-size   : 100px;
    font-style  : normal;
    content     : '\201c';
    position    : absolute;
    left        : -3px;
    top         : 30px;
    z-index     : 10
}

.testimonials-carousel h4 {
    margin : 0
}

.testimonials-carousel h4 + p {
    margin-bottom : 0
}

.testimonials-carousel .carousel-indicators {
    bottom  : -16px;
    display : none
}

.testimonials-carousel .carousel-control {
    display : none
}

.testimonials-carousel .media + p {
    position         : relative;
    padding          : 10px 16px;
    background-color : #E8F1F3
}

.testimonials-carousel .media:after {
    content : '';
    display : block;
    clear   : both
}

.testimonials-carousel .media + p:before, .testimonials-carousel .media + p:after {
    content      : '';
    position     : absolute;
    left         : 0;
    top          : auto;
    bottom       : -18px;
    border-style : solid;
    border-width : 18px 0 0 18px;
    border-color : rgba(61, 104, 137, .2) transparent transparent transparent
}

.testimonials-carousel .media + p:after {
    left         : auto;
    right        : 0;
    border-style : solid;
    border-width : 18px 18px 0 0
}

.testimonials-carousel .media-object {
    max-width     : 50px;
    border-radius : 50%;
    margin-bottom : 10px
}

.testimonials-carousel .carousel-indicators li {
    border-color : #3D6889
}

.testimonials-carousel .carousel-indicators li.active {
    background-color : #3D6889
}

.testimonials-control {
    font-size : 22px
}

.thumbnail {
    position         : relative;
    border-radius    : 0;
    padding          : 0;
    border           : none;
    overflow         : hidden;
    background-color : transparent
}

.isotope-item .thumbnail {
    margin-bottom : 30px
}

.thumbnail h4 {
    text-transform : uppercase;
    font-weight    : 700;
    line-height    : .8;
    margin-bottom  : 4px
}

.thumbnail h4 a {
    color : inherit
}

.thumbnail h4 + p.small-text {
    color         : #88ADB9;
    margin-bottom : 10px
}

.thumbnail .caption {
    color            : #88ADB9;
    background-color : transparent;
    padding          : 20px 0 0
}

.thumbnail .media-left {
    padding-top   : 2px;
    padding-right : 7px
}

.thumbnail .media-left i {
    font-size : 20px
}

.thumbnail .media-body.yantramanov {
    font-size   : 18px;
    font-weight : 700
}

.thumbnail .social-icons {
    margin-top : 5px
}

.price-table, .price-table ul {
    margin     : 0;
    padding    : 0;
    list-style : none;
    text-align : center
}

.price-table ul {
    text-align : left;
}

.price-table {
    margin : 0
}

.bg_teaser .plan-name {
    background-color : #F3F8F9
}

.bg_teaser .plan-price {
    background-color : rgba(255, 255, 255, .1)
}

.bg_teaser.color_bg_1 .plan-name h3 {
    color : #3D6889
}

.bg_teaser.color_bg_2 .plan-name h3 {
    color : #73D0F4
}

.bg_teaser.color_bg_3 .plan-name h3 {
    color : #4E8DBD
}

.bg_teaser .features-list li + li {
    border-top : 1px solid rgba(255, 255, 255, .2)
}

.plan-name {
    padding : 30px 0
}

.plan-name h3 {
    font-size : 32px;
    margin    : 0
}

.plan-price {
    padding  : 35px 0 25px;
    position : relative
}

.plan-price span:first-child {
    font-size   : 28px;
    font-weight : 300
}

.plan-price span + span {
    font-size   : 60px;
    font-weight : 700
}

.plan-price p {
    display     : inline;
    font-size   : 28px;
    font-weight : 300
}

.features-list {
    margin-top : 20px
}

.features-list li {
    margin      : 10px 40px;
    padding-top : 10px
}

.features-list .enabled:before, .features-list .disabled:before {
    font-family   : rt-icons-2;
    font-size     : 24px;
    line-height   : 1;
    position      : relative;
    top           : 5px;
    right         : 4px;
    content       : "\e116";
    padding-right : 8px;
    color         : #292A2E
}

.features-list .disabled:before {
    content : "\e117"
}

.call-to-action {
    padding : 20px 40px 38px
}

.price-table.style1 {
    border : 1px solid rgba(61, 104, 137, .2)
}

.price-table.style1 .features-list li + li {
    border-top : 1px solid rgba(61, 104, 137, .2)
}

.price-table.style1 .plan-price {
    background-color : #3D6889;
    color            : #FFFFFF;
    margin           : 1px -1px
}

.price-table.style2 {
    background-color : #E8F1F3
}

.price-table.style2 .plan-name {
    background-color : #3D6889;
    padding          : 30px 0
}

.price-table.style2 .plan-name h3 {
    color : #FFFFFF
}

.price-table.style2 .plan-price {
    padding-bottom : 0;
    color          : #292A2E
}

.price-table.style2 .enabled:before, .price-table.style2 .disabled:before {
    font-family   : rt-icons-2;
    content       : "\e621";
    padding-right : 13px;
    color         : #3D6889
}

.price-table.style2 .disabled:before {
    color   : #292A2E;
    content : "\e622"
}

.contact-form {
    position : relative;
    z-index  : 3
}

.contact-form label[for] {
    display : none
}

.contact-form textarea {
    max-height : 200px
}

.contact-form.parallax {
    padding          : 50px 50px 35px;
    background-image : url(../images/parallax/progress.jpg)
}

.contact-form-respond {
    display     : block;
    font-weight : 800
}

.contact-form-respond:before {
    font-family   : rt-icons-2;
    content       : "\e71a";
    padding-right : 10px
}

.contact-form p {
    margin-bottom : 10px;
    position      : relative
}

.contact-form-submit .theme_button {
    margin-right : 6px
}

.contact-form.transparent-background .form-control {
    background-color : transparent
}

.contact-email {
    font-size      : 40px;
    text-transform : uppercase
}

@media (max-width : 767px) {
    .contact-email {
        font-size     : 20px;
        margin-bottom : 6px
    }

    .contact-form.parallax {
        padding-left   : 20px;
        padding-right  : 20px;
        padding-bottom : 40px
    }
}

.border-paragraphs {
    margin : 25px 0
}

.border-paragraphs p {
    padding       : 9px 0 10px;
    margin        : 0;
    border-bottom : 1px solid rgba(61, 104, 137, .2)
}

.border-paragraphs p:first-child {
    border-top : 1px solid rgba(61, 104, 137, .2)
}

.owl-carousel.partners-carousel {
    margin-bottom : 0
}

.owl-carousel.partners-carousel .owl-item img {
    width   : auto;
    margin  : auto;
    opacity : .6
}

.owl-carousel.partners-carousel .owl-item a:hover img {
    opacity : 1
}

.light-partner-image a {
    display  : block;
    position : relative
}

.light-partner-image a:before {
    content            : '';
    position           : absolute;
    top                : 1px;
    left               : 1px;
    right              : 1px;
    bottom             : 1px;
    background-color   : #88ADB9;
    -webkit-transition : all .2s ease-in-out 0s;
    transition         : all .2s ease-in-out 0s
}

.light-partner-image a:hover:before {
    background-color : #3D6889
}

.highlight-partner-image a {
    display          : block;
    background-color : #3D6889
}

.highlight-partner-image a:hover {
    background-color : #292A2E
}

.info_banner {
    min-height         : 250px;
    -webkit-transition : all .4s ease 0s;
    transition         : all .4s ease 0s
}

.info_banner .media-left i {
    opacity : .35
}

.info_banner li.topmargin_10 {
    margin-top : 9px
}

@media (min-width : 992px) and (max-width : 1199px) {
    .info_banner {
        min-height : 285px
    }
}

.content-card {
    overflow : hidden
}

.content-card .card-heading {
    text-align     : center;
    text-transform : uppercase;
    font-weight    : 700;
    padding        : 35px 0;
    margin         : 0;
    color          : #FFFFFF
}

.calendar-header {
    margin-bottom : 20px
}

.calendar-navigation .theme_button {
    margin     : 0;
    min-width  : auto;
    text-align : center;
    padding    : 14px 15px;
    font-size  : 20px;
    opacity    : .5
}

.calendar-navigation .theme_button:hover {
    color   : #3D6889;
    opacity : 1
}

.calendar-navigation h3 {
    margin : 11px 0
}

@media (max-width : 500px) {
    .calendar-navigation h3 {
        font-size   : 20px;
        line-height : 28px
    }
}

.week-days, .week-row, .month-box {
    font-size : 0
}

.week-days {
    background-color : #4E8DBD;
    border-radius    : 6px 6px 0 0;
    overflow         : hidden
}

.week-days > div {
    position       : relative;
    display        : inline-block;
    width          : 14.285714%;
    font-size      : 13px;
    font-family    : raleway, sans-serif;
    line-height    : 24px;
    text-transform : uppercase;
    font-weight    : 700;
    color          : #FFFFFF;
    text-align     : center;
    padding        : 13px 0
}

.day-cell {
    position   : relative;
    padding    : 25px 0;
    display    : inline-block;
    width      : 14.285714286%;
    text-align : center;
    color      : #3D6889;
    cursor     : pointer
}

.day-cell + .day-cell {
    border-left : 1px solid rgba(61, 104, 137, .2)
}

.day-cell.not-cur-month {
    cursor : default
}

.appointment-calendar .day-cell.selected-day, .day-cell.today {
    background-color : rgba(78, 141, 189, .2)
}

.day-cell.selected-day > *, .day-cell.today > * {
    color : #88ADB9
}

.day-cell.today:before {
    content     : "TODAY";
    position    : absolute;
    top         : 5px;
    left        : 10px;
    font-size   : 14px;
    font-weight : 700;
    color       : #FFFFFF
}

.week-row {
    border : 1px solid #D3E4E8
}

.week-row + .week-row {
    border-top : none
}

.week-row:last-child {
    border-radius : 0 0 6px 6px
}

.day-cell * {
    font-size : 14px
}

.day-date {
    font-size   : 16px;
    font-weight : 600
}

.not-cur-month .day-date {
    color : #88ADB9
}

@media (max-width : 639px) {
    .day-cell * {
        font-size : 12px
    }

    .week-days > div {
        font-size : 12px
    }

    .day-cell {
        padding : 20px 0
    }
}

@media (max-width : 439px) {
    .day-cell * {
        font-size : 8px
    }

    .day-cell .day-date {
        font-size : 12px
    }

    .day-cell {
        padding : 15px 0
    }
}

.appointment-list {
    padding : 35px 40px 45px
}

@media (max-width : 400px) {
    .appointment-list {
        padding : 35px 20px
    }
}

.appointment-list p {
    font-size : 20px
}

.appointment-list ul {
    margin  : 0;
    padding : 0
}

.appointment-list li:first-child {
    border-top : 1px solid rgba(255, 255, 255, .2)
}

.appointment-list li {
    padding       : 10px 0;
    border-bottom : 1px solid rgba(255, 255, 255, .2)
}

.appointment-list li div * {
    font-size : 14px
}

.appointment-list li div.book-appointment a {
    font-size : 13px
}

.appointment-list li > div {
    height      : 50px;
    line-height : 50px;
    display     : inline-block;
    min-width   : 170px
}

.appointment-time span {
    margin-left : 10px;
    color       : #FFFFFF
}

.appointment-slots {
    margin-left : 110px
}

@media (max-width : 991px) {
    .appointment-slots {
        margin-left : 40px
    }

    .boxed .appointment-list li > div {
        display : block;
        float   : none
    }

    .boxed .appointment-slots {
        margin-left : 0
    }
}

@media (max-width : 767px) {
    .appointment-list li > div {
        display : block;
        float   : none
    }

    .appointment-slots {
        margin-left : 0
    }
}

.unavailable .appointment-slots span {
    color : #88ADB9
}

.book-appointment {
    float : right
}

.appointment-list li i {
    position    : relative;
    font-size   : 16px;
    color       : #88ADB9;
    top         : 1px;
    font-weight : 400
}

.appointment-list .book-appointment a {
    min-width : 190px;
    margin    : 0
}

.appointment-list .unavailable .book-appointment a {
    background-color : #3D6889;
    border-color     : #3D6889;
    color            : #88ADB9;
    pointer-events   : none
}

.currency-dropdown .dropdown-menu {
    min-width : 0;
    padding   : 20px 10px
}

.currency-dropdown .dropdown-menu > li > a {
    color   : inherit;
    padding : 5px 20px
}

.currency-dropdown .dropdown-menu > li > a:hover, .currency-dropdown .dropdown-menu > li.active-currency {
    color : #292A2E
}

.login-dropdown .dropdown-menu {
    min-width : 400px;
    padding   : 30px
}

.login-dropdown .dropdown-menu .form-control {
    border-color : rgba(61, 104, 137, .2)
}

.cart-dropdown .dropdown-menu {
    min-width : 400px;
    padding   : 20px 30px
}

@media (max-width : 767px) {
    .login-dropdown, .cart-dropdown {
        position : static
    }

    .login-dropdown .dropdown-menu, .cart-dropdown .dropdown-menu {
        margin-left  : 0;
        margin-right : 0;
        right        : 0;
        left         : 0;
        min-width    : 0
    }
}

.storefront-sorting {
    line-height : 1;
    padding     : 35px 35px 20px
}

.storefront-sorting .form-group {
    margin-bottom : 15px
}

.storefront-sorting label {
    padding-right : 10px
}

#toggle_shop_view {
    margin-left : 14px
}

#toggle_shop_view:before, #toggle_shop_view:after {
    font-family        : fontawesome;
    display            : inline-block;
    background-color   : #292A2E;
    color              : #FFFFFF;
    padding            : 12px 12px 13px;
    font-size          : 14px;
    -webkit-transition : all .2s ease-in-out 0s;
    transition         : all .2s ease-in-out 0s
}

#toggle_shop_view:before {
    content      : "\f00a";
    margin-right : 5px
}

#toggle_shop_view:after {
    content          : "\f03a";
    background-color : #3D6889
}

#toggle_shop_view.grid-view:before {
    background-color : #3D6889
}

#toggle_shop_view.grid-view:after {
    background-color : #292A2E
}

#sort_view {
    color            : #292A2E;
    display          : inline-block;
    padding          : 12px 16px 13px;
    font-size        : 14px;
    background-color : #FFFFFF
}

.columns-1 .product {
    width        : 100%;
    margin-right : 0;
    float        : none
}

@media (min-width : 992px) {
    .columns-2 .product {
        width : 50%;
        float : left
    }

    .columns-3 .product {
        width : 33.33333333%;
        float : left
    }

    .columns-4 .product {
        width : 25%;
        float : left
    }
}

#products {
    margin-left  : -15px;
    margin-right : -15px
}

#products:before, #products:after {
    content : '';
    clear   : both;
    display : block
}

li.product {
    padding-left  : 15px;
    padding-right : 15px;
    margin-bottom : 30px
}

.owl-item .product {
    padding : 0
}

.product .row {
    position : relative
}

.product [class*=col-] {
    position : static
}

.product [class*=col-].images a {
    display  : block;
    position : relative
}

.product h3 {
    margin-bottom : 20px
}

.product .rating-title {
    font-size : 1.2em
}

.product [class*=col-]:first-child h3 {
    text-align     : center;
    font-size      : 20px;
    font-weight    : 700;
    margin         : 18px 0 24px;
    position       : relative;
    letter-spacing : 0
}

.product .theme_button {
    margin        : 4px 0 11px;
    padding-right : 25px;
    padding-left  : 25px;
    min-width     : auto
}

.product .product-media {
    position : relative
}

.product [class*=col-]:first-child a span, .product [class*=col-]:first-child .product-media span {
    position         : absolute;
    background-color : #4E8DBD;
    color            : #FFFFFF;
    padding          : 8px 18px;
    text-transform   : uppercase;
    top              : 20px;
    right            : 20px
}

.product [class*=col-]:first-child a span.onsale, .product [class*=col-]:first-child .product-media span.onsale {
    background-color : #3D6889;
    left             : 20px;
    bottom           : 20px;
    right            : auto;
    top              : auto
}

.related > h2 {
    margin : 20px 0 30px
}

.star-rating {
    overflow      : hidden;
    position      : relative;
    height        : 1em;
    line-height   : 1em;
    font-size     : 12px;
    width         : 5.5em;
    font-family   : rt-icons-2;
    margin-bottom : 4px
}

.star-rating:before, .star-rating span:before {
    content        : "\e7aa\e7aa\e7aa\e7aa\e7aa";
    float          : left;
    top            : 0;
    left           : 0;
    position       : absolute;
    letter-spacing : .1em;
    letter-spacing : 0 \9;
    color          : #E5E5E5
}

.star-rating span {
    overflow    : hidden;
    float       : left;
    top         : 0;
    left        : 0;
    position    : absolute;
    padding-top : 1.5em
}

.star-rating span:before {
    color : #3D6889
}

.price {
    position         : absolute;
    left             : 20px;
    top              : 20px;
    color            : #292A2E;
    background-color : #FFFFFF;
    padding          : 8px 18px
}

.price del {
    opacity   : .5;
    font-size : 12px
}

.price ins {
    text-decoration : none
}

.product-buttons {
    position : absolute;
    bottom   : 20px;
    right    : 20px
}

.product-buttons a {
    float      : left;
    width      : 40px;
    height     : 40px;
    font-size  : 16px;
    text-align : center
}

.product-buttons a i {
    line-height : 40px
}

.product-buttons a.favorite_button {
    background-color : #FFFFFF;
    color            : #292A2E
}

.product-buttons a.add_to_cart_button {
    color            : #FFFFFF;
    background-color : #3D6889
}

.product-buttons a.add_to_cart_button.loading [class*=rt-icon] {
    display           : inline-block;
    -webkit-animation : spin 2s infinite linear;
    animation         : spin 2s infinite linear
}

.product-buttons a.add_to_cart_button.loading [class*=rt-icon]:before {
    content : "\e61b"
}

.product-buttons a:hover {
    background-color : #292A2E;
    color            : #FFFFFF
}

.product-buttons a.added_to_cart {
    background-color : #FFFFFF;
    font-size        : 0;
    color            : transparent
}

.product-buttons a.added_to_cart:hover {
    background-color : #3D6889
}

.product-buttons a.added_to_cart:before {
    content     : "\e6c0";
    line-height : 40px;
    color       : #292A2E;
    font-family : rt-icons-2;
    font-size   : 26px
}

.grid-view {
    text-align : center
}

.grid-view .row {
    position : relative
}

.grid-view .product [class*=col-]:last-child {
    padding  : 20px 35px;
    position : absolute;
    top      : 0
}

.grid-view .product [class*=col-]:last-child h3 {
    display : none
}

.grid-view .item-content h4 {
    display : none
}

.grid-view .star-rating {
    display : none
}

.grid-view .product-description {
    display : none
}

.grid-view [class*=col-] {
    width : 100%;
    float : none
}

.list-view .product {
    float         : none;
    width         : 100%;
    margin-bottom : 60px
}

.list-view .product [class*=col-]:first-child h3 {
    display : none
}

.summary h1 {
    margin-bottom : 3px
}

.summary .product-rating, .summary .woocommerce-product-rating {
    margin-bottom : 10px
}

.summary .price {
    position         : static;
    margin-top       : 20px;
    font-size        : 50px;
    background-color : transparent;
    padding          : 0;
    line-height      : 1;
    word-wrap        : break-word
}

.summary .price del {
    font-size : 28px
}

.summary .star-rating {
    display      : inline-block;
    top          : 4px;
    margin-right : 20px
}

.summary .stock {
    margin-bottom : 0
}

.summary .email-to {
    margin-bottom : 10px
}

.summary .product-option-name {
    display : inline-block;
    margin  : 10px 0
}

.summary .theme_button {
    margin-top : 0
}

.summary .posted_in {
    margin : 10px 0
}

.summary .posted_in .small_button {
    margin-left   : 5px;
    padding-left  : 6px;
    padding-right : 6px
}

.summary ul.list1 li {
    padding-top    : 15px;
    padding-bottom : 20px
}

.summary .reset_variations {
    padding : 0 5px
}

.product_meta_bottom {
    margin : 0 0 20px
}

.product-tabs {
    margin-top : 30px
}

.owl-carousel.product-thumbnails {
    margin : 30px 0
}

.owl-carousel.product-thumbnails .owl-dots {
    margin : 20px 0 5px
}

.owl-carousel.product-thumbnails .owl-dots .owl-dot span {
    width        : 8px;
    height       : 8px;
    border-width : 1px
}

.owl-carousel.product-thumbnails .owl-nav {
    margin-top : -18px
}

.owl-carousel.product-thumbnails .owl-nav > div {
    right : 0
}

.owl-carousel.product-thumbnails .owl-nav > div:first-child {
    left : 0
}

.owl-carousel.product-thumbnails .owl-nav > div:after {
    font-size : 16px;
    color     : #292A2E
}

.product-icons .teaser [class*=_bg_color] {
    padding : 10px
}

.product + .nav-tabs {
    margin-top : 60px
}

.tab-content .comments-area {
    margin-top : 10px
}

.tab-content .comment-respond {
    margin-top : 30px
}

.tab-content .comment-respond h4 {
    margin-bottom : 0;
    font-size     : 28px
}

.comment .star-rating {
    display     : inline-block;
    top         : 5px;
    margin-left : 10px
}

.stars {
    position : relative
}

.stars a {
    font-size      : 12px;
    display        : inline-block;
    font-weight    : 700;
    text-indent    : -9999px;
    position       : relative;
    border-bottom  : 2px solid transparent;
    color          : #3D6889;
    letter-spacing : .1em
}

.stars a.active {
    border-bottom : 1px solid #3D6889
}

.stars a:after {
    font-family : rt-icons-2;
    text-indent : 0;
    position    : absolute;
    top         : 0;
    left        : 0;
    right       : 0
}

.stars a.star-1 {
    width : 2em
}

.stars a.star-1:after {
    content : "\e7aa"
}

.stars a.star-2 {
    width : 3em
}

.stars a.star-2:after {
    content : "\e7aa\e7aa"
}

.stars a.star-3 {
    width : 4em
}

.stars a.star-3:after {
    content : "\e7aa\e7aa\e7aa"
}

.stars a.star-4 {
    width : 5em
}

.stars a.star-4:after {
    content : "\e7aa\e7aa\e7aa\e7aa"
}

.stars a.star-5 {
    width : 6em
}

.stars a.star-5:after {
    content : "\e7aa\e7aa\e7aa\e7aa\e7aa"
}

.comment-form-rating .stars span {
    display    : block;
    padding    : 20px 0;
    text-align : center
}

.shop-adds {
    font-size   : 20px;
    font-weight : 300;
    line-height : 1.4
}

.shop-adds .fa {
    padding : 0 10px
}

.shop-adds.teaser {
    margin-bottom : 0
}

.shop-adds.teaser [class*=_bg_color] {
    padding : 12px
}

.cart-table {
    margin-bottom : 30px
}

.table.cart-table .cart_item .media {
    margin-left : -15px
}

.table.cart-table .cart_item img {
    max-width : 80px
}

.table.cart-table .cart_item h4 {
    margin-bottom : 12px
}

.table.cart-table .cart_item td {
    vertical-align : middle;
    padding        : 18px 10px
}

.cart_totals h4:first-child {
    margin : 60px 0 30px
}

.cart_totals table {
    margin : 0 0 50px
}

.cart_totals table tr td {
    padding : 20px 10px
}

.quantity {
    position : relative;
    display  : inline-block
}

.quantity [type=button] {
    position         : absolute;
    right            : 0;
    top              : 0;
    line-height      : 1;
    border           : none;
    width            : 22px;
    height           : 24px;
    background-color : transparent
}

.quantity [type=button].minus {
    top    : auto;
    bottom : 0
}

.quantity [type=number] {
    padding-right : 30px;
    padding-left  : 20px;
    max-width     : 70px;
    min-width     : 70px
}

.shop-info {
    margin-bottom : 15px
}

#createaccount {
    margin-top : 0
}

#ship-to-different-address label {
    display : inline-block
}

#ship-to-different-address label + input {
    margin : 0 12px
}

.wc_payment_method img {
    display : inline-block;
    padding : 0 10px
}

.checkout [class*=col-], .shop-register [class*=col-] {
    margin-top    : 0;
    margin-bottom : 0
}

.lost_password {
    margin : 15px 0 -15px
}

.select2-container .select2-choice {
    border        : none;
    padding       : 10px 20px;
    border-radius : none
}

.shop-checkout-payment {
    margin-top : 40px
}

.shop-checkout-payment .radio {
    margin : 2px 0
}

.widget_shopping_cart img {
    max-width : 80px
}

.widget_shopping_cart h4 {
    margin      : 0;
    font-size   : 16px;
    font-weight : 400
}

.widget_shopping_cart .cart_list {
    border-bottom  : 1px solid rgba(61, 104, 137, .2);
    padding-bottom : 20px;
    margin         : 20px 0
}

.widget_shopping_cart .media-body:last-child {
    width : 1000px
}

.widget_shopping_cart .media-body:last-child i {
    padding : 0 0 0 10px
}

.widget_price_filter input[readonly] {
    background-color : transparent;
    padding-right    : 3px;
    padding-left     : 3px;
    max-width        : 89px;
    margin-bottom    : 4px;
    display          : inline-block
}

.widget_price_filter .ui-slider-range {
    background-color : #3D6889
}

.widget_price_filter .ui-slider-horizontal {
    margin-bottom : 30px
}

.widget_price_filter label[for] {
    display       : inline-block;
    padding-right : 4px
}

.widget_price_filter .form-group {
    display       : inline-block;
    margin-right  : 10px;
    margin-bottom : 10px
}

.widget_price_filter .theme_button {
    margin-top     : 16px;
    padding-top    : 8px;
    padding-bottom : 8px
}

/*!jQuery UI - v1.11.1 - 2014-10-03
* http://jqueryui.com
* Includes: core.css, slider.css
* Copyright 2014 jQuery Foundation and other contributors; Licensed MIT*/
.ui-widget-overlay {
    position : fixed;
    top      : 0;
    left     : 0;
    width    : 100%;
    height   : 100%
}

.ui-slider {
    position         : relative;
    text-align       : left;
    background-color : #FAFAFA
}

.ui-slider .ui-slider-handle {
    position         : absolute;
    z-index          : 2;
    width            : 12px;
    height           : 12px;
    cursor           : pointer;
    -ms-touch-action : none;
    touch-action     : none;
    background-color : #292A2E
}

.ui-slider .ui-slider-range {
    position            : absolute;
    z-index             : 1;
    font-size           : .7em;
    display             : block;
    border              : 0;
    background-position : 0 0
}

.ui-slider.ui-state-disabled .ui-slider-handle, .ui-slider.ui-state-disabled .ui-slider-range {
    -webkit-filter : inherit;
    filter         : inherit
}

.ui-slider-horizontal {
    height : 10px
}

.ui-slider-horizontal .ui-slider-handle {
    top         : -1px;
    margin-left : -.6em
}

.ui-slider-horizontal .ui-slider-range {
    top    : 0;
    height : 100%
}

.ui-slider-horizontal .ui-slider-range-min {
    left : 0
}

.ui-slider-horizontal .ui-slider-range-max {
    right : 0
}

.ui-slider-vertical {
    width  : .8em;
    height : 100px
}

.ui-slider-vertical .ui-slider-handle {
    left          : -.3em;
    margin-left   : 0;
    margin-bottom : -.6em
}

.ui-slider-vertical .ui-slider-range {
    left  : 0;
    width : 100%
}

.ui-slider-vertical .ui-slider-range-min {
    bottom : 0
}

.ui-slider-vertical .ui-slider-range-max {
    top : 0
}

.widget_layered_nav .color-filters li {
    display : inline-block;
    margin  : 0 29px 14px 0
}

.widget_layered_nav .color-filters a {
    display          : inline-block;
    width            : 23px;
    height           : 23px;
    font-size        : 0;
    background-color : #CCCCCC;
    color            : transparent;
    position         : relative
}

.widget_layered_nav .color-filters a:before {
    content  : '';
    display  : block;
    position : absolute;
    border   : 1px solid rgba(61, 104, 137, .2);
    top      : -2px;
    left     : -2px;
    bottom   : -2px;
    right    : -2px
}

.widget_layered_nav .color-filters span {
    position : relative;
    top      : 2px;
    left     : 5px
}

.widget_products img {
    max-width : 80px
}

.widget_products .media h3 {
    font-size   : inherit;
    line-height : 1.4;
    margin      : 3px 0 10px
}

.widget_products .star-rating {
    margin-bottom : 10px
}

.widget_products .price {
    position         : static;
    background-color : transparent;
    padding          : 0
}

.widget_products .add_to_cart_button {
    display          : inline-block;
    width            : 20px;
    height           : 20px;
    font-size        : 14px;
    text-align       : center;
    background-color : #3D6889;
    color            : #FFFFFF;
    margin-right     : 5px
}

.widget_products .add_to_cart_button:hover {
    color            : #FFFFFF;
    background-color : #292A2E
}

.widget_top_rated_products img {
    max-width : 80px
}

.widget_recent_reviews li {
    margin-bottom : 16px
}

.widget_recent_reviews li:before, .widget_recent_reviews li:after {
    content : '';
    display : block;
    clear   : both
}

.widget_recent_reviews img {
    display      : block;
    float        : left;
    max-width    : 80px;
    margin-right : 10px
}

.owl-carousel {
    display                     : none;
    width                       : 100%;
    -webkit-tap-highlight-color : transparent;
    position                    : relative;
    z-index                     : 1
}

.owl-carousel .owl-stage {
    position         : relative;
    -ms-touch-action : pan-Y
}

.owl-carousel .owl-stage:after {
    content     : ".";
    display     : block;
    clear       : both;
    visibility  : hidden;
    line-height : 0;
    height      : 0
}

.owl-carousel .owl-stage-outer {
    position          : relative;
    overflow          : hidden;
    -webkit-transform : translate3d(0px, 0px, 0px)
}

.owl-carousel .owl-item {
    position                    : relative;
    min-height                  : 1px;
    float                       : left;
    -webkit-backface-visibility : hidden;
    -webkit-tap-highlight-color : transparent;
    -webkit-touch-callout       : none
}

.owl-carousel .owl-item img {
    display                 : block;
    width                   : 100%;
    -webkit-transform-style : preserve-3d
}

.owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled {
    display : none
}

.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-dot {
    cursor              : pointer;
    cursor              : hand;
    -webkit-user-select : none;
    -moz-user-select    : none;
    -ms-user-select     : none;
    user-select         : none;
    -webkit-transition  : all .2s ease-in-out 0s;
    transition          : all .2s ease-in-out 0s
}

.owl-carousel.owl-loaded {
    display : block
}

.owl-carousel.owl-loading {
    opacity : 0;
    display : block
}

.owl-carousel.owl-hidden {
    opacity : 0
}

.owl-carousel.owl-refresh .owl-item {
    display : none
}

.owl-carousel.owl-drag .owl-item {
    -webkit-user-select : none;
    -moz-user-select    : none;
    -ms-user-select     : none;
    user-select         : none
}

.owl-carousel.owl-grab {
    cursor : move;
    cursor : -webkit-grab;
    cursor : grab
}

.owl-carousel.owl-rtl {
    direction : rtl
}

.owl-carousel.owl-rtl .owl-item {
    float : right
}

.owl-carousel .owl-dots {
    margin-top                  : 10px;
    text-align                  : center;
    -webkit-tap-highlight-color : transparent
}

.owl-carousel .owl-dots .owl-dot {
    display  : inline-block;
    zoom     : 1;
    *display : inline
}

.owl-carousel .owl-dots .owl-dot span {
    width                       : 10px;
    height                      : 10px;
    margin                      : 3px;
    border                      : 2px solid #292A2E;
    display                     : block;
    -webkit-backface-visibility : visible;
    -webkit-transition          : all 200ms ease;
    transition                  : all 200ms ease;
    border-radius               : 50%
}

.owl-carousel .owl-dots .owl-dot.active span, .owl-carousel .owl-dots .owl-dot:hover span {
    background-color : #292A2E
}

.owl-carousel .owl-nav {
    -webkit-transition : all .6s ease 0s;
    transition         : all .6s ease 0s
}

.owl-carousel .owl-nav > div {
    color            : transparent;
    font-size        : 0;
    display          : inline-block;
    right            : 0;
    text-align       : center;
    width            : 50px;
    height           : 50px;
    background-color : #FFFFFF;
    cursor           : pointer
}

.owl-carousel .owl-nav > div:first-child {
    right : 55px
}

.owl-carousel .owl-nav > div:after {
    -webkit-transition : all .3s ease 0s;
    transition         : all .3s ease 0s;
    font-family        : FontAwesome;
    font-size          : 20px;
    line-height        : 48px;
    content            : "\f0da";
    color              : #88ADB9
}

.owl-carousel .owl-nav > div:first-child:after {
    content : "\f0d9"
}

.owl-carousel .owl-nav > div:hover:after {
    color : #3D6889
}

.no-js .owl-carousel {
    display : block
}

.owl-carousel .animated {
    -webkit-animation-duration  : 1000ms;
    animation-duration          : 1000ms;
    -webkit-animation-fill-mode : both;
    animation-fill-mode         : both
}

.owl-carousel .owl-animated-in {
    z-index : 0
}

.owl-carousel .owl-animated-out {
    z-index : 1
}

.owl-carousel .fadeOut {
    -webkit-animation-name : fadeOut;
    animation-name         : fadeOut
}

@-webkit-keyframes fadeOut {
    0% {
        opacity : 1
    }
    100% {
        opacity : 0
    }
}

@keyframes fadeOut {
    0% {
        opacity : 1
    }
    100% {
        opacity : 0
    }
}

.owl-height {
    -webkit-transition : height 500ms ease-in-out;
    transition         : height 500ms ease-in-out
}

.owl-carousel .owl-item .owl-lazy {
    opacity            : 0;
    -webkit-transition : opacity 400ms ease;
    transition         : opacity 400ms ease
}

.owl-carousel .owl-item img.owl-lazy {
    -webkit-transform-style : preserve-3d;
    transform-style         : preserve-3d
}

.owl-carousel .owl-video-wrapper {
    position   : relative;
    height     : 100%;
    background : #000000
}

.owl-carousel .owl-video-play-icon {
    position                    : absolute;
    height                      : 80px;
    width                       : 80px;
    left                        : 50%;
    top                         : 50%;
    margin-left                 : -40px;
    margin-top                  : -40px;
    background                  : url(../img/owl.video.play.png) no-repeat;
    cursor                      : pointer;
    z-index                     : 1;
    -webkit-backface-visibility : hidden;
    -webkit-transition          : -webkit-transform 100ms ease;
    transition                  : -webkit-transform 100ms ease;
    transition                  : transform 100ms ease;
    transition                  : transform 100ms ease, -webkit-transform 100ms ease
}

.owl-carousel .owl-video-play-icon:hover {
    -webkit-transform : scale(1.3, 1.3);
    transform         : scale(1.3, 1.3)
}

.owl-carousel .owl-video-playing .owl-video-tn, .owl-carousel .owl-video-playing .owl-video-play-icon {
    display : none
}

.owl-carousel .owl-video-tn {
    opacity             : 0;
    height              : 100%;
    background-position : center center;
    background-repeat   : no-repeat;
    background-size     : contain;
    -webkit-transition  : opacity 400ms ease;
    transition          : opacity 400ms ease
}

.owl-carousel .owl-video-frame {
    position : relative;
    z-index  : 1;
    height   : 100%;
    width    : 100%
}

.owl-theme.with-nav {
    margin-top : 100px
}

.owl-theme .owl-nav {
    position                    : absolute;
    top                         : -80px;
    right                       : 0;
    left                        : 0;
    text-align                  : center;
    -webkit-tap-highlight-color : transparent
}

.owl-theme .owl-nav > div {
    color            : transparent;
    font-size        : 0;
    position         : absolute;
    right            : 0;
    text-align       : center;
    width            : 50px;
    height           : 50px;
    background-color : transparent;
    border           : 1px solid rgba(61, 104, 137, .2);
    border-radius    : 2px
}

.owl-theme .owl-nav .disabled {
    opacity : .5;
    cursor  : default
}

.owl-center .owl-nav {
    top        : 0;
    bottom     : 0;
    position   : static;
    margin-top : 0;
    opacity    : 1
}

.owl-center .owl-nav > div {
    right            : -15px;
    width            : 50px;
    top              : 0;
    bottom           : 0;
    margin           : 0;
    height           : auto;
    background-color : rgba(41, 42, 46, .6)
}

.owl-center .owl-nav > div:first-child {
    left  : -15px;
    right : auto
}

.owl-center .owl-nav > div:first-child:after {
    left : 13px
}

.owl-center .owl-nav > div:after {
    position   : absolute;
    top        : 50%;
    margin-top : -10px;
    color      : #FFFFFF;
    font-size  : 20px;
    left       : 17px
}

@media (min-width : 992px) {
    .owl-carousel.top-offset.with-nav {
        margin-top : 15px
    }
}

.owl-carousel .owl-item {
    -webkit-transition : opacity .4s ease .2s;
    transition         : opacity .4s ease .2s;
    opacity            : 0
}

.owl-carousel .owl-item.active {
    opacity : 1
}

.owl-center .owl-item {
    opacity : 1
}

.flex-container a:active, .flexslider a:active, .flex-container a:focus, .flexslider a:focus {
    outline : none
}

.slides, .flex-control-nav, .flex-direction-nav {
    margin     : 0;
    padding    : 0;
    list-style : none
}

.flexslider {
    margin  : 0;
    padding : 0
}

.flexslider .slides > li {
    display                     : none;
    -webkit-backface-visibility : hidden
}

.flexslider .slides img {
    width   : 100%;
    display : block
}

.flex-pauseplay span {
    text-transform : capitalize
}

.slides:after {
    content     : "\0020";
    display     : block;
    clear       : both;
    visibility  : hidden;
    line-height : 0;
    height      : 0
}

html[xmlns] .slides {
    display : block
}

* html .slides {
    height : 1%
}

.no-js .slides > li:first-child {
    display : block
}

.flexslider {
    margin   : 0;
    position : relative;
    zoom     : 1
}

.flex-viewport {
    max-height         : 2000px;
    -webkit-transition : all 1s ease;
    transition         : all 1s ease
}

.loading .flex-viewport {
    max-height : 300px
}

.flexslider .slides {
    zoom : 1
}

.flex-direction-nav {
    *height : 0
}

.flex-direction-nav {
    margin-top : 35px
}

.flex-direction-nav li {
    display : inline-block
}

.flex-direction-nav li + li {
    margin-left : 6px
}

.flex-direction-nav a {
    text-decoration  : none;
    position         : relative;
    display          : block;
    width            : 50px;
    height           : 50px;
    z-index          : 10;
    overflow         : hidden;
    opacity          : 1;
    cursor           : pointer;
    font-size        : 0;
    color            : transparent;
    text-align       : center;
    background-color : #FFFFFF;
    border           : 1px solid #C1D8DD;
    border-radius    : 2px
}

.flex-direction-nav .flex-next {
    text-align : right
}

.flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover {
    opacity : 1
}

.flex-direction-nav .flex-disabled {
    opacity : 0;
    filter  : alpha(opacity=0);
    cursor  : default
}

.flex-direction-nav a:before {
    font-family        : fontawesome;
    font-size          : 20px;
    content            : "\f0d9";
    color              : #88ADB9;
    position           : absolute;
    top                : 12px;
    left               : 19px;
    -webkit-transition : all .2s linear 0s;
    transition         : all .2s linear 0s
}

.flex-direction-nav a.flex-next:before {
    content : "\f0da";
    left    : 21px
}

.flex-direction-nav a:hover:before {
    color : #3D6889
}

.flex-pauseplay a {
    display  : block;
    width    : 20px;
    height   : 20px;
    position : absolute;
    bottom   : 5px;
    left     : 10px;
    opacity  : .8;
    z-index  : 10;
    overflow : hidden;
    cursor   : pointer;
    color    : #000000
}

.flex-pauseplay a:before {
    font-family : rt-icons-2;
    font-size   : 20px;
    display     : inline-block;
    content     : "\e6a2"
}

.flex-pauseplay a.flex-play:before {
    content : "\e711"
}

.flex-control-nav {
    width      : 100%;
    position   : absolute;
    bottom     : 42px;
    text-align : center;
    z-index    : 10
}

.flex-control-nav li {
    text-align : center;
    display    : inline-block;
    padding    : 0 4px;
    zoom       : 1;
    *display   : inline
}

.flex-control-paging li a {
    width         : 12px;
    height        : 12px;
    display       : block;
    color         : transparent;
    border        : 2px solid #292A2E;
    cursor        : pointer;
    text-indent   : -9999px;
    border-radius : 50%
}

.ds .flex-control-paging li a, .cs .flex-control-paging li a {
    border-color : #FFFFFF
}

.flex-control-paging li a:hover {
    border-color : #3D6889
}

.flex-control-paging li a.flex-active {
    border-color : #3D6889
}

.flex-control-thumbs {
    margin   : 5px 0 0;
    position : static;
    overflow : hidden
}

.flex-control-thumbs li {
    width  : 25%;
    float  : left;
    margin : 0
}

.flex-control-thumbs img {
    width   : 100%;
    display : block;
    opacity : .7;
    cursor  : pointer
}

.flex-control-thumbs img:hover {
    opacity : 1
}

.flex-control-thumbs .flex-active {
    opacity : 1;
    cursor  : default
}

@media screen and (max-width : 600px) {
    .flex-direction-nav .flex-prev {
        top : 14%
    }

    .flex-direction-nav .flex-next {
        top : 14%
    }
}

.flex-custom-nav {
    text-align         : center;
    position           : absolute;
    bottom             : 38px;
    right              : 0;
    left               : 0;
    z-index            : 3;
    line-height        : 1;
    -webkit-transition : all .2s ease-in-out 0s;
    transition         : all .2s ease-in-out 0s
}

.flex-custom-nav .flex-control-nav {
    width    : auto;
    display  : inline-block;
    position : static
}

.flex-custom-nav .flex-custom-link {
    font-size : 12px;
    position  : relative;
    bottom    : 7px;
    margin    : 0 8px;
    color     : #292A2E
}

.ds .flex-custom-nav .flex-custom-link, .cs .flex-custom-nav .flex-custom-link {
    color : #FFFFFF
}

.flex-custom-nav .flex-custom-link:hover {
    color : #3D6889
}

.intro_section:hover .flex-custom-nav {
    opacity : 1
}

div.facebook .pp_expand {
    background : url(../img/prettyPhoto/facebook/sprite.png) -31px -26px no-repeat;
    cursor     : pointer
}

div.facebook .pp_expand:hover {
    background : url(../img/prettyPhoto/facebook/sprite.png) -31px -47px no-repeat;
    cursor     : pointer
}

div.facebook .pp_contract {
    background : url(../img/prettyPhoto/facebook/sprite.png) 0 -26px no-repeat;
    cursor     : pointer
}

div.facebook .pp_contract:hover {
    background : url(../img/prettyPhoto/facebook/sprite.png) 0 -47px no-repeat;
    cursor     : pointer
}

div.facebook .pp_close {
    width      : 22px;
    height     : 22px;
    background : url(../img/prettyPhoto/facebook/sprite.png) -1px -1px no-repeat;
    cursor     : pointer;
    z-index    : 3000
}

div.facebook .pp_description {
    margin : 0 37px 0 0
}

div.facebook .pp_loaderIcon {
    background : url(../img/prettyPhoto/facebook/loader.gif) center center no-repeat
}

div.facebook .pp_arrow_previous {
    background : url(../img/prettyPhoto/facebook/sprite.png) 0 -71px no-repeat;
    height     : 22px;
    margin-top : 0;
    width      : 22px
}

div.facebook .pp_arrow_previous.disabled {
    background-position : 0 -96px;
    cursor              : default
}

div.facebook .pp_arrow_next {
    background : url(../img/prettyPhoto/facebook/sprite.png) -32px -71px no-repeat;
    height     : 22px;
    margin-top : 0;
    width      : 22px
}

div.facebook .pp_arrow_next.disabled {
    background-position : -32px -96px;
    cursor              : default
}

div.facebook .pp_nav {
    margin-top : 0
}

div.facebook .pp_nav p {
    font-size  : 15px;
    padding    : 0 3px 0 4px;
    margin-top : -2px
}

div.facebook .pp_nav .pp_play {
    background : url(../img/prettyPhoto/facebook/sprite.png) -1px -123px no-repeat;
    height     : 22px;
    width      : 22px
}

div.facebook .pp_nav .pp_pause {
    background : url(../img/prettyPhoto/facebook/sprite.png) -32px -123px no-repeat;
    height     : 22px;
    width      : 22px
}

div.facebook .pp_next:hover {
    background : url(../img/prettyPhoto/facebook/btnNext.png) center right no-repeat;
    cursor     : pointer
}

div.facebook .pp_previous:hover {
    background : url(../img/prettyPhoto/facebook/btnPrevious.png) center left no-repeat;
    cursor     : pointer
}

div.pp_pic_holder a:focus {
    outline : none
}

div.pp_overlay {
    background : #000000;
    display    : none;
    left       : 0;
    position   : absolute;
    top        : 0;
    width      : 100%;
    z-index    : 9500
}

div.pp_pic_holder {
    display  : none;
    position : absolute;
    width    : 100px;
    z-index  : 10000
}

.pp_content {
    height    : 40px;
    min-width : 40px;
    overflow  : hidden
}

* html .pp_content {
    width : 40px
}

.pp_content_container {
    position   : relative;
    text-align : left;
    width      : 100%
}

.pp_content_container .pp_left {
    padding-left : 20px
}

.pp_content_container .pp_right {
    padding-right : 20px
}

.pp_content_container .pp_details {
    float            : left;
    padding          : 7px 10px;
    background-color : #FFFFFF
}

.pp_description {
    display : none;
    margin  : 0
}

.pp_social {
    float  : left;
    margin : 0
}

.pp_social .facebook {
    float       : left;
    margin-left : 5px;
    width       : auto;
    overflow    : hidden
}

.pp_social .twitter {
    float : left
}

.pp_nav {
    clear  : right;
    float  : left;
    margin : 3px 10px 0 0
}

.pp_nav p {
    float       : left;
    white-space : nowrap;
    margin      : 2px 4px
}

.pp_nav .pp_play, .pp_nav .pp_pause {
    float        : left;
    margin-right : 4px;
    text-indent  : -10000px
}

a.pp_arrow_previous, a.pp_arrow_next {
    display     : block;
    float       : left;
    height      : 15px;
    margin-top  : 3px;
    overflow    : hidden;
    text-indent : -10000px;
    width       : 14px
}

.pp_hoverContainer {
    position : absolute;
    top      : 0;
    width    : 100%;
    z-index  : 2000
}

.pp_gallery {
    display    : none;
    left       : 50%;
    margin-top : -50px;
    position   : absolute;
    z-index    : 10000
}

.pp_gallery div {
    float    : left;
    overflow : hidden;
    position : relative
}

.pp_gallery ul {
    float       : left;
    height      : 35px;
    position    : relative;
    white-space : nowrap;
    margin      : 0 0 0 5px;
    padding     : 0
}

.pp_gallery ul a {
    border   : 1px rgba(0, 0, 0, .5) solid;
    display  : block;
    float    : left;
    height   : 33px;
    overflow : hidden
}

.pp_gallery ul a img {
    border : 0
}

.pp_gallery li {
    display : block;
    float   : left;
    margin  : 0 5px 0 0;
    padding : 0
}

.pp_gallery li.default a {
    background : url(../img/prettyPhoto/facebook/default_thumbnail.gif) 0 0 no-repeat;
    display    : block;
    height     : 33px;
    width      : 50px
}

div.facebook .pp_gallery .pp_arrow_previous, div.facebook .pp_gallery .pp_arrow_next {
    margin-top : 7px
}

a.pp_next {
    background         : url(../img/prettyPhoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;
    display            : block;
    float              : right;
    height             : 100%;
    text-indent        : -10000px;
    width              : 49%;
    -webkit-transition : none;
    transition         : none
}

a.pp_previous {
    background         : url(../img/prettyPhoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;
    display            : block;
    float              : left;
    height             : 100%;
    text-indent        : -10000px;
    width              : 49%;
    -webkit-transition : none;
    transition         : none
}

a.pp_expand, a.pp_contract {
    cursor      : pointer;
    display     : none;
    height      : 20px;
    position    : absolute;
    right       : 30px;
    text-indent : -10000px;
    top         : 10px;
    width       : 20px;
    z-index     : 20000
}

a.pp_close {
    position    : absolute;
    right       : 6px;
    top         : -16px;
    display     : block;
    line-height : 22px;
    text-indent : -10000px
}

.pp_loaderIcon {
    display  : block;
    height   : 24px;
    left     : 50%;
    position : absolute;
    top      : 50%;
    width    : 24px;
    margin   : -12px 0 0 -12px
}

#pp_full_res {
    line-height : 1
}

#pp_full_res .pp_inline {
    text-align : left
}

#pp_full_res .pp_inline p {
    margin : 0 0 15px
}

div.ppt {
    color     : #FFFFFF;
    display   : none;
    font-size : 17px;
    z-index   : 9999;
    margin    : 0 0 5px 15px
}

.pp_top, .pp_bottom {
    height   : 20px;
    position : relative
}

* html .pp_top, * html .pp_bottom {
    padding : 0 20px
}

.pp_top .pp_left, .pp_bottom .pp_left {
    height   : 20px;
    left     : 0;
    position : absolute;
    width    : 20px
}

.pp_top .pp_middle, .pp_bottom .pp_middle {
    height   : 20px;
    left     : 20px;
    position : absolute;
    right    : 20px
}

* html .pp_top .pp_middle, * html .pp_bottom .pp_middle {
    left     : 0;
    position : static
}

.pp_top .pp_right, .pp_bottom .pp_right {
    height   : 20px;
    left     : auto;
    position : absolute;
    right    : 0;
    top      : 0;
    width    : 20px
}

.pp_fade, .pp_gallery li.default a img {
    display : none
}

.chart {
    position      : relative;
    display       : inline-block;
    min-height    : 150px;
    min-width     : 150px;
    margin-top    : 20px;
    margin-bottom : 10px;
    text-align    : center
}

.chart .chart-meta {
    position   : absolute;
    width      : 100%;
    top        : 50%;
    margin-top : -30px
}

.percent {
    display        : inline-block;
    font-size      : 70px;
    font-weight    : 100;
    z-index        : 2;
    letter-spacing : -.05em
}

.percent:after {
    content : '%'
}

.angular {
    margin-top : 100px
}

.angular .chart {
    margin-top : 0
}

#timetable span {
    display : block
}

#timetable th, #timetable td {
    vertical-align : middle;
    font-weight    : 700;
    text-align     : center;
    padding        : 14px 5px
}

#timetable td {
    font-size      : 11px;
    text-transform : uppercase
}

#timetable thead {
    color : #FFFFFF
}

#timetable thead th {
    border       : 1px solid rgba(255, 255, 255, .2);
    border-width : 0 1px
}

#timetable thead th:first-child {
    border-radius : 6px 0 0 0
}

#timetable thead th:last-child {
    border-radius : 0 6px 0 0
}

#timetable tbody th, #timetable tbody td {
    border             : 1px solid #D3E4E8;
    -webkit-transition : all .2s ease-in-out 0s;
    transition         : all .2s ease-in-out 0s
}

#timetable tbody th {
    font-size : 14px;
    color     : #3D6889
}

#timetable tbody .current {
    background-color : #4E8DBD
}

#timetable tbody .current a {
    color : #FFFFFF
}

#toTop {
    bottom           : 0;
    right            : 0;
    display          : none;
    width            : 60px;
    height           : 40px;
    overflow         : hidden;
    position         : fixed;
    text-decoration  : none;
    z-index          : 9999;
    font-size        : 0;
    color            : transparent;
    background-color : #3D6889;
    opacity          : .7
}

#toTop:after {
    color              : #FFFFFF;
    content            : '\f106';
    font-size          : 16px;
    line-height        : 40px;
    font-family        : fontawesome;
    text-align         : center;
    display            : block;
    position           : absolute;
    top                : 0;
    right              : 0;
    bottom             : 0;
    left               : 0;
    -webkit-transition : all .2s linear;
    transition         : all .2s linear
}

#toTopHover, #toTop:hover {
    bottom  : 3px;
    opacity : 1
}

#toTop:hover:after {
    color : #FFFFFF
}

#toTop:active, #toTop:focus {
    outline : medium
}

.modal-open #toTop {
    visibility : hidden
}

#comingsoon-countdown {
    text-align : center
}

#comingsoon-countdown:before, #comingsoon-countdown:after {
    clear   : both;
    display : table;
    content : ' '
}

.countdown-rtl {
    direction : rtl
}

.countdown-row {
    clear         : both;
    display       : table;
    margin        : auto;
    padding       : 20px 0;
    margin-bottom : 20px;
    text-align    : center
}

.countdown-section {
    display     : table-cell;
    padding     : 10px 30px 20px;
    text-align  : center;
    border-left : 1px solid rgba(61, 104, 137, .2)
}

.countdown-section:first-child {
    border-left-width : 0
}

.countdown-amount {
    font-size   : 90px;
    line-height : 1;
    font-weight : 100;
    color       : #3D6889
}

.countdown-period {
    display     : block;
    font-size   : 28px;
    font-weight : 100
}

.countdown-descr {
    display : block
}

@media (max-width : 767px) {
    .countdown-section {
        padding : 20px 8px 0
    }

    .countdown-amount {
        font-size : 40px
    }

    .countdown-period {
        font-size : 16px
    }
}

a[class*=soc-]:before {
    font-family : socicon;
    font-size   : 16px
}

a.social-icon {
    display        : inline-block;
    line-height    : 1;
    text-align     : center;
    vertical-align : middle
}

a.social-icon span {
    opacity        : .7;
    display        : block;
    font-size      : 14px;
    text-transform : uppercase
}

a.social-icon span:first-child {
    font-size   : 24px;
    margin-top  : 8px;
    font-weight : 700
}

a.social-icon [class*=rt-icon] + span {
    font-size   : 24px;
    margin-top  : 8px;
    font-weight : 700
}

a.social-icon + a.social-icon, .tooltip + a.social-icon {
    margin-left : 22px
}

a.social-icon.bg-icon + a.social-icon.bg-icon {
    margin-left : 7px
}

a.social-icon.color-bg-icon + a.social-icon.color-bg-icon {
    margin-left : 1px
}

a.social-icon.block-icon {
    width : 100%
}

a[class*=soc-].rounded-icon {
    border-radius : 50%
}

a.big-icon {
    width          : 115px;
    height         : 115px;
    padding-top    : 20px;
    padding-bottom : 20px;
    overflow       : hidden
}

a.big-icon[class*=soc-]:before {
    font-size : 32px
}

a.big-icon [class*=rt-icon]:before {
    font-size : 32px
}

a.color-icon.soc-twitter, a[class*=soc-].soc-twitter:hover {
    color        : #00ABEF;
    border-color : #00ABEF
}

a.color-icon.soc-facebook, a[class*=soc-].soc-facebook:hover {
    color        : #507CBE;
    border-color : #507CBE
}

a.color-icon.soc-google, a[class*=soc-].soc-google:hover {
    color        : #D93E2D;
    border-color : #D93E2D
}

a.color-icon.soc-pinterest, a[class*=soc-].soc-pinterest:hover {
    color        : #C92619;
    border-color : #C92619
}

a.color-icon.soc-foursquare, a[class*=soc-].soc-foursquare:hover {
    color        : #23B7E0;
    border-color : #23B7E0
}

a.color-icon.soc-yahoo, a[class*=soc-].soc-yahoo:hover {
    color        : #6E2A85;
    border-color : #6E2A85
}

a.color-icon.soc-skype, a[class*=soc-].soc-skype:hover {
    color        : #28ABE3;
    border-color : #28ABE3
}

a.color-icon.soc-yelp, a[class*=soc-].soc-yelp:hover {
    color        : #C83218;
    border-color : #C83218
}

a.color-icon.soc-feedburner, a[class*=soc-].soc-feedburner:hover {
    color        : #FFCC00;
    border-color : #FFCC00
}

a.color-icon.soc-linkedin, a[class*=soc-].soc-linkedin:hover {
    color        : #3371B7;
    border-color : #3371B7
}

a.color-icon.soc-viadeo, a[class*=soc-].soc-viadeo:hover {
    color        : #E4A000;
    border-color : #E4A000
}

a.color-icon.soc-xing, a[class*=soc-].soc-xing:hover {
    color        : #005A60;
    border-color : #005A60
}

a.color-icon.soc-myspace, a[class*=soc-].soc-myspace:hover {
    color        : #323232;
    border-color : #323232
}

a.color-icon.soc-soundcloud, a[class*=soc-].soc-soundcloud:hover {
    color        : #FE3801;
    border-color : #FE3801
}

a.color-icon.soc-spotify, a[class*=soc-].soc-spotify:hover {
    color        : #7BB342;
    border-color : #7BB342
}

a.color-icon.soc-grooveshark, a[class*=soc-].soc-grooveshark:hover {
    color        : #F67E03;
    border-color : #F67E03
}

a.color-icon.soc-lastfm, a[class*=soc-].soc-lastfm:hover {
    color        : #D41316;
    border-color : #D41316
}

a.color-icon.soc-youtube, a[class*=soc-].soc-youtube:hover {
    color        : #D12121;
    border-color : #D12121
}

a.color-icon.soc-vimeo, a[class*=soc-].soc-vimeo:hover {
    color        : #51B5E7;
    border-color : #51B5E7
}

a.color-icon.soc-dailymotion, a[class*=soc-].soc-dailymotion:hover {
    color        : #004E72;
    border-color : #004E72
}

a.color-icon.soc-vine, a[class*=soc-].soc-vine:hover {
    color        : #00B389;
    border-color : #00B389
}

a.color-icon.soc-flickr, a[class*=soc-].soc-flickr:hover {
    color        : #0062DD;
    border-color : #0062DD
}

a.color-icon.soc-500px, a[class*=soc-].soc-500px:hover {
    color        : #58A9DE;
    border-color : #58A9DE
}

a.color-icon.soc-instagram, a[class*=soc-].soc-instagram:hover {
    color        : #9C7C6E;
    border-color : #9C7C6E
}

a.color-icon.soc-wordpress, a[class*=soc-].soc-wordpress:hover {
    color        : #464646;
    border-color : #464646
}

a.color-icon.soc-tumblr, a[class*=soc-].soc-tumblr:hover {
    color        : #45556C;
    border-color : #45556C
}

a.color-icon.soc-blogger, a[class*=soc-].soc-blogger:hover {
    color        : #EC661C;
    border-color : #EC661C
}

a.color-icon.soc-technorati, a[class*=soc-].soc-technorati:hover {
    color        : #5CB030;
    border-color : #5CB030
}

a.color-icon.soc-reddit, a[class*=soc-].soc-reddit:hover {
    color        : #E74A1E;
    border-color : #E74A1E
}

a.color-icon.soc-dribbble, a[class*=soc-].soc-dribbble:hover {
    color        : #E84D88;
    border-color : #E84D88
}

a.color-icon.soc-stumbleupon, a[class*=soc-].soc-stumbleupon:hover {
    color        : #E64011;
    border-color : #E64011
}

a.color-icon.soc-digg, a[class*=soc-].soc-digg:hover {
    color        : #1D1D1B;
    border-color : #1D1D1B
}

a.color-icon.soc-envato, a[class*=soc-].soc-envato:hover {
    color        : #597C3A;
    border-color : #597C3A
}

a.color-icon.soc-behance, a[class*=soc-].soc-behance:hover {
    color        : #007EFF;
    border-color : #007EFF
}

a.color-icon.soc-delicious, a[class*=soc-].soc-delicious:hover {
    color        : #31A9FF;
    border-color : #31A9FF
}

a.color-icon.soc-deviantart, a[class*=soc-].soc-deviantart:hover {
    color        : #C5D200;
    border-color : #C5D200
}

a.color-icon.soc-forrst, a[class*=soc-].soc-forrst:hover {
    color        : #5B9A68;
    border-color : #5B9A68
}

a.color-icon.soc-play, a[class*=soc-].soc-play:hover {
    color        : #5ACDBD;
    border-color : #5ACDBD
}

a.color-icon.soc-zerply, a[class*=soc-].soc-zerply:hover {
    color        : #9DBC7A;
    border-color : #9DBC7A
}

a.color-icon.soc-wikipedia, a[class*=soc-].soc-wikipedia:hover {
    color        : #000000;
    border-color : #000000
}

a.color-icon.soc-apple, a[class*=soc-].soc-apple:hover {
    color        : #B9BFC1;
    border-color : #B9BFC1
}

a.color-icon.soc-flattr, a[class*=soc-].soc-flattr:hover {
    color        : #F67C1A;
    border-color : #F67C1A
}

a.color-icon.soc-github, a[class*=soc-].soc-github:hover {
    color        : #221E1B;
    border-color : #221E1B
}

a.color-icon.soc-chimein, a[class*=soc-].soc-chimein:hover {
    color        : #888688;
    border-color : #888688
}

a.color-icon.soc-friendfeed, a[class*=soc-].soc-friendfeed:hover {
    color        : #2F72C4;
    border-color : #2F72C4
}

a.color-icon.soc-newsvine, a[class*=soc-].soc-newsvine:hover {
    color        : #075B2F;
    border-color : #075B2F
}

a.color-icon.soc-identica, a[class*=soc-].soc-identica:hover {
    color        : #000000;
    border-color : #000000
}

a.color-icon.soc-bebo, a[class*=soc-].soc-bebo:hover {
    color        : #EF1011;
    border-color : #EF1011
}

a.color-icon.soc-zynga, a[class*=soc-].soc-zynga:hover {
    color        : #DC0606;
    border-color : #DC0606
}

a.color-icon.soc-steam, a[class*=soc-].soc-steam:hover {
    color        : #8F8D8A;
    border-color : #8F8D8A
}

a.color-icon.soc-xbox, a[class*=soc-].soc-xbox:hover {
    color        : #92C83E;
    border-color : #92C83E
}

a.color-icon.soc-windows, a[class*=soc-].soc-windows:hover {
    color        : #00BDF6;
    border-color : #00BDF6
}

a.color-icon.soc-outlook, a[class*=soc-].soc-outlook:hover {
    color        : #0072C6;
    border-color : #0072C6
}

a.color-icon.soc-coderwall, a[class*=soc-].soc-coderwall:hover {
    color        : #3E8DCC;
    border-color : #3E8DCC
}

a.color-icon.soc-tripadvisor, a[class*=soc-].soc-tripadvisor:hover {
    color        : #000000;
    border-color : #000000
}

a.color-icon.soc-appnet, a[class*=soc-].soc-appnet:hover {
    color        : #494949;
    border-color : #494949
}

a.color-icon.soc-goodreads, a[class*=soc-].soc-goodreads:hover {
    color        : #1ABC9C;
    border-color : #1ABC9C
}

a.color-icon.soc-tripit, a[class*=soc-].soc-tripit:hover {
    color        : #1982C3;
    border-color : #1982C3
}

a.color-icon.soc-lanyrd, a[class*=soc-].soc-lanyrd:hover {
    color        : #3C80C9;
    border-color : #3C80C9
}

a.color-icon.soc-slideshare, a[class*=soc-].soc-slideshare:hover {
    color        : #4BA3A6;
    border-color : #4BA3A6
}

a.color-icon.soc-buffer, a[class*=soc-].soc-buffer:hover {
    color        : #000000;
    border-color : #000000
}

a.color-icon.soc-rss, a[class*=soc-].soc-rss:hover {
    color        : #F26109;
    border-color : #F26109
}

a.color-icon.soc-vkontakte, a[class*=soc-].soc-vkontakte:hover {
    color        : #5A7FA6;
    border-color : #5A7FA6
}

a.color-icon.soc-disqus, a[class*=soc-].soc-disqus:hover {
    color        : #2E9FFF;
    border-color : #2E9FFF
}

a.color-icon.soc-houzz, a[class*=soc-].soc-houzz:hover {
    color        : #7CC04B;
    border-color : #7CC04B
}

a.color-icon.soc-mail, a[class*=soc-].soc-mail:hover {
    color        : #000000;
    border-color : #000000
}

a.color-icon.soc-patreon, a[class*=soc-].soc-patreon:hover {
    color        : #E44727;
    border-color : #E44727
}

a.color-icon.soc-paypal, a[class*=soc-].soc-paypal:hover {
    color        : #009CDE;
    border-color : #009CDE
}

a.color-icon.soc-playstation, a[class*=soc-].soc-playstation:hover {
    color        : #000000;
    border-color : #000000
}

a.color-icon.soc-smugmug, a[class*=soc-].soc-smugmug:hover {
    color        : #ACFD32;
    border-color : #ACFD32
}

a.color-icon.soc-swarm, a[class*=soc-].soc-swarm:hover {
    color        : #FC9D3C;
    border-color : #FC9D3C
}

a.color-icon.soc-triplej, a[class*=soc-].soc-triplej:hover {
    color        : #E53531;
    border-color : #E53531
}

a.color-icon.soc-yammer, a[class*=soc-].soc-yammer:hover {
    color        : #1175C4;
    border-color : #1175C4
}

a.color-icon.soc-stackoverflow, a[class*=soc-].soc-stackoverflow:hover {
    color        : #FD9827;
    border-color : #FD9827
}

a.color-icon.soc-drupal, a[class*=soc-].soc-drupal:hover {
    color        : #00598E;
    border-color : #00598E
}

a.color-icon.soc-odnoklassniki, a[class*=soc-].soc-odnoklassniki:hover {
    color        : #F48420;
    border-color : #F48420
}

a.color-icon.soc-android, a[class*=soc-].soc-android:hover {
    color        : #8EC047;
    border-color : #8EC047
}

a.color-icon.soc-meetup, a[class*=soc-].soc-meetup:hover {
    color        : #E2373C;
    border-color : #E2373C
}

a.color-icon.soc-persona, a[class*=soc-].soc-persona:hover {
    color        : #E6753D;
    border-color : #E6753D
}

a.color-icon.soc-amazon, a[class*=soc-].soc-amazon:hover {
    color        : #FF9900;
    border-color : #FF9900
}

a.color-icon.soc-ello, a[class*=soc-].soc-ello:hover {
    color        : #000000;
    border-color : #000000
}

a.color-icon.soc-mixcloud, a[class*=soc-].soc-mixcloud:hover {
    color        : #314359;
    border-color : #314359
}

a.color-icon.soc-8tracks, a[class*=soc-].soc-8tracks:hover {
    color        : #122C4B;
    border-color : #122C4B
}

a.color-icon.soc-twitch, a[class*=soc-].soc-twitch:hover {
    color        : #6441A5;
    border-color : #6441A5
}

a.color-icon.soc-airbnb, a[class*=soc-].soc-airbnb:hover {
    color        : #FF5A5F;
    border-color : #FF5A5F
}

a.color-icon.soc-pocket, a[class*=soc-].soc-pocket:hover {
    color        : #ED4055;
    border-color : #ED4055
}

.color-bg-icon {
    color : #FFFFFF
}

.color-bg-icon.soc-twitter, a.soc-twitter.bg-icon:hover, .soc-twitter.color-icon.border-icon:hover {
    background-color : #00ABEF
}

.color-bg-icon.soc-facebook, a.soc-facebook.bg-icon:hover, .soc-facebook.color-icon.border-icon:hover {
    background-color : #507CBE
}

.color-bg-icon.soc-google, a.soc-google.bg-icon:hover, .soc-google.color-icon.border-icon:hover {
    background-color : #D93E2D
}

.color-bg-icon.soc-pinterest, a.soc-pinterest.bg-icon:hover, .soc-pinterest.color-icon.border-icon:hover {
    background-color : #C92619
}

.color-bg-icon.soc-foursquare, a.soc-foursquare.bg-icon:hover, .soc-foursquare.color-icon.border-icon:hover {
    background-color : #23B7E0
}

.color-bg-icon.soc-yahoo, a.soc-yahoo.bg-icon:hover, .soc-yahoo.color-icon.border-icon:hover {
    background-color : #6E2A85
}

.color-bg-icon.soc-skype, a.soc-skype.bg-icon:hover, .soc-skype.color-icon.border-icon:hover {
    background-color : #28ABE3
}

.color-bg-icon.soc-yelp, a.soc-yelp.bg-icon:hover, .soc-yelp.color-icon.border-icon:hover {
    background-color : #C83218
}

.color-bg-icon.soc-feedburner, a.soc-feedburner.bg-icon:hover, .soc-feedburner.color-icon.border-icon:hover {
    background-color : #FFCC00
}

.color-bg-icon.soc-linkedin, a.soc-linkedin.bg-icon:hover, .soc-linkedin.color-icon.border-icon:hover {
    background-color : #3371B7
}

.color-bg-icon.soc-viadeo, a.soc-viadeo.bg-icon:hover, .soc-viadeo.color-icon.border-icon:hover {
    background-color : #E4A000
}

.color-bg-icon.soc-xing, a.soc-xing.bg-icon:hover, .soc-xing.color-icon.border-icon:hover {
    background-color : #005A60
}

.color-bg-icon.soc-myspace, a.soc-myspace.bg-icon:hover, .soc-myspace.color-icon.border-icon:hover {
    background-color : #323232
}

.color-bg-icon.soc-soundcloud, a.soc-soundcloud.bg-icon:hover, .soc-soundcloud.color-icon.border-icon:hover {
    background-color : #FE3801
}

.color-bg-icon.soc-spotify, a.soc-spotify.bg-icon:hover, .soc-spotify.color-icon.border-icon:hover {
    background-color : #7BB342
}

.color-bg-icon.soc-grooveshark, a.soc-grooveshark.bg-icon:hover, .soc-grooveshark.color-icon.border-icon:hover {
    background-color : #F67E03
}

.color-bg-icon.soc-lastfm, a.soc-lastfm.bg-icon:hover, .soc-lastfm.color-icon.border-icon:hover {
    background-color : #D41316
}

.color-bg-icon.soc-youtube, a.soc-youtube.bg-icon:hover, .soc-youtube.color-icon.border-icon:hover {
    background-color : #D12121
}

.color-bg-icon.soc-vimeo, a.soc-vimeo.bg-icon:hover, .soc-vimeo.color-icon.border-icon:hover {
    background-color : #51B5E7
}

.color-bg-icon.soc-dailymotion, a.soc-dailymotion.bg-icon:hover, .soc-dailymotion.color-icon.border-icon:hover {
    background-color : #004E72
}

.color-bg-icon.soc-vine, a.soc-vine.bg-icon:hover, .soc-vine.color-icon.border-icon:hover {
    background-color : #00B389
}

.color-bg-icon.soc-flickr, a.soc-flickr.bg-icon:hover, .soc-flickr.color-icon.border-icon:hover {
    background-color : #0062DD
}

.color-bg-icon.soc-500px, a.soc-500px.bg-icon:hover, .soc-500px.color-icon.border-icon:hover {
    background-color : #58A9DE
}

.color-bg-icon.soc-instagram, a.soc-instagram.bg-icon:hover, .soc-instagram.color-icon.border-icon:hover {
    background-color : #9C7C6E
}

.color-bg-icon.soc-wordpress, a.soc-wordpress.bg-icon:hover, .soc-wordpress.color-icon.border-icon:hover {
    background-color : #464646
}

.color-bg-icon.soc-tumblr, a.soc-tumblr.bg-icon:hover, .soc-tumblr.color-icon.border-icon:hover {
    background-color : #45556C
}

.color-bg-icon.soc-blogger, a.soc-blogger.bg-icon:hover, .soc-blogger.color-icon.border-icon:hover {
    background-color : #EC661C
}

.color-bg-icon.soc-technorati, a.soc-technorati.bg-icon:hover, .soc-technorati.color-icon.border-icon:hover {
    background-color : #5CB030
}

.color-bg-icon.soc-reddit, a.soc-reddit.bg-icon:hover, .soc-reddit.color-icon.border-icon:hover {
    background-color : #E74A1E
}

.color-bg-icon.soc-dribbble, a.soc-dribbble.bg-icon:hover, .soc-dribbble.color-icon.border-icon:hover {
    background-color : #E84D88
}

.color-bg-icon.soc-stumbleupon, a.soc-stumbleupon.bg-icon:hover, .soc-stumbleupon.color-icon.border-icon:hover {
    background-color : #E64011
}

.color-bg-icon.soc-digg, a.soc-digg.bg-icon:hover, .soc-digg.color-icon.border-icon:hover {
    background-color : #1D1D1B
}

.color-bg-icon.soc-envato, a.soc-envato.bg-icon:hover, .soc-envato.color-icon.border-icon:hover {
    background-color : #597C3A
}

.color-bg-icon.soc-behance, a.soc-behance.bg-icon:hover, .soc-behance.color-icon.border-icon:hover {
    background-color : #007EFF
}

.color-bg-icon.soc-delicious, a.soc-delicious.bg-icon:hover, .soc-delicious.color-icon.border-icon:hover {
    background-color : #31A9FF
}

.color-bg-icon.soc-deviantart, a.soc-deviantart.bg-icon:hover, .soc-deviantart.color-icon.border-icon:hover {
    background-color : #C5D200
}

.color-bg-icon.soc-forrst, a.soc-forrst.bg-icon:hover, .soc-forrst.color-icon.border-icon:hover {
    background-color : #5B9A68
}

.color-bg-icon.soc-play, a.soc-play.bg-icon:hover, .soc-play.color-icon.border-icon:hover {
    background-color : #5ACDBD
}

.color-bg-icon.soc-zerply, a.soc-zerply.bg-icon:hover, .soc-zerply.color-icon.border-icon:hover {
    background-color : #9DBC7A
}

.color-bg-icon.soc-wikipedia, a.soc-wikipedia.bg-icon:hover, .soc-wikipedia.color-icon.border-icon:hover {
    background-color : #000000
}

.color-bg-icon.soc-apple, a.soc-apple.bg-icon:hover, .soc-apple.color-icon.border-icon:hover {
    background-color : #B9BFC1
}

.color-bg-icon.soc-flattr, a.soc-flattr.bg-icon:hover, .soc-flattr.color-icon.border-icon:hover {
    background-color : #F67C1A
}

.color-bg-icon.soc-github, a.soc-github.bg-icon:hover, .soc-github.color-icon.border-icon:hover {
    background-color : #221E1B
}

.color-bg-icon.soc-chimein, a.soc-chimein.bg-icon:hover, .soc-chimein.color-icon.border-icon:hover {
    background-color : #888688
}

.color-bg-icon.soc-friendfeed, a.soc-friendfeed.bg-icon:hover, .soc-friendfeed.color-icon.border-icon:hover {
    background-color : #2F72C4
}

.color-bg-icon.soc-newsvine, a.soc-newsvine.bg-icon:hover, .soc-newsvine.color-icon.border-icon:hover {
    background-color : #075B2F
}

.color-bg-icon.soc-identica, a.soc-identica.bg-icon:hover, .soc-identica.color-icon.border-icon:hover {
    background-color : #000000
}

.color-bg-icon.soc-bebo, a.soc-bebo.bg-icon:hover, .soc-bebo.color-icon.border-icon:hover {
    background-color : #EF1011
}

.color-bg-icon.soc-zynga, a.soc-zynga.bg-icon:hover, .soc-zynga.color-icon.border-icon:hover {
    background-color : #DC0606
}

.color-bg-icon.soc-steam, a.soc-steam.bg-icon:hover, .soc-steam.color-icon.border-icon:hover {
    background-color : #8F8D8A
}

.color-bg-icon.soc-xbox, a.soc-xbox.bg-icon:hover, .soc-xbox.color-icon.border-icon:hover {
    background-color : #92C83E
}

.color-bg-icon.soc-windows, a.soc-windows.bg-icon:hover, .soc-windows.color-icon.border-icon:hover {
    background-color : #00BDF6
}

.color-bg-icon.soc-outlook, a.soc-outlook.bg-icon:hover, .soc-outlook.color-icon.border-icon:hover {
    background-color : #0072C6
}

.color-bg-icon.soc-coderwall, a.soc-coderwall.bg-icon:hover, .soc-coderwall.color-icon.border-icon:hover {
    background-color : #3E8DCC
}

.color-bg-icon.soc-tripadvisor, a.soc-tripadvisor.bg-icon:hover, .soc-tripadvisor.color-icon.border-icon:hover {
    background-color : #000000
}

.color-bg-icon.soc-appnet, a.soc-appnet.bg-icon:hover, .soc-appnet.color-icon.border-icon:hover {
    background-color : #494949
}

.color-bg-icon.soc-goodreads, a.soc-goodreads.bg-icon:hover, .soc-goodreads.color-icon.border-icon:hover {
    background-color : #1ABC9C
}

.color-bg-icon.soc-tripit, a.soc-tripit.bg-icon:hover, .soc-tripit.color-icon.border-icon:hover {
    background-color : #1982C3
}

.color-bg-icon.soc-lanyrd, a.soc-lanyrd.bg-icon:hover, .soc-lanyrd.color-icon.border-icon:hover {
    background-color : #3C80C9
}

.color-bg-icon.soc-slideshare, a.soc-slideshare.bg-icon:hover, .soc-slideshare.color-icon.border-icon:hover {
    background-color : #4BA3A6
}

.color-bg-icon.soc-buffer, a.soc-buffer.bg-icon:hover, .soc-buffer.color-icon.border-icon:hover {
    background-color : #000000
}

.color-bg-icon.soc-rss, a.soc-rss.bg-icon:hover, .soc-rss.color-icon.border-icon:hover {
    background-color : #F26109
}

.color-bg-icon.soc-vkontakte, a.soc-vkontakte.bg-icon:hover, .soc-vkontakte.color-icon.border-icon:hover {
    background-color : #5A7FA6
}

.color-bg-icon.soc-disqus, a.soc-disqus.bg-icon:hover, .soc-disqus.color-icon.border-icon:hover {
    background-color : #2E9FFF
}

.color-bg-icon.soc-houzz, a.soc-houzz.bg-icon:hover, .soc-houzz.color-icon.border-icon:hover {
    background-color : #7CC04B
}

.color-bg-icon.soc-mail, a.soc-mail.bg-icon:hover, .soc-mail.color-icon.border-icon:hover {
    background-color : #000000
}

.color-bg-icon.soc-patreon, a.soc-patreon.bg-icon:hover, .soc-patreon.color-icon.border-icon:hover {
    background-color : #E44727
}

.color-bg-icon.soc-paypal, a.soc-paypal.bg-icon:hover, .soc-paypal.color-icon.border-icon:hover {
    background-color : #009CDE
}

.color-bg-icon.soc-playstation, a.soc-playstation.bg-icon:hover, .soc-playstation.color-icon.border-icon:hover {
    background-color : #000000
}

.color-bg-icon.soc-smugmug, a.soc-smugmug.bg-icon:hover, .soc-smugmug.color-icon.border-icon:hover {
    background-color : #ACFD32
}

.color-bg-icon.soc-swarm, a.soc-swarm.bg-icon:hover, .soc-swarm.color-icon.border-icon:hover {
    background-color : #FC9D3C
}

.color-bg-icon.soc-triplej, a.soc-triplej.bg-icon:hover, .soc-triplej.color-icon.border-icon:hover {
    background-color : #E53531
}

.color-bg-icon.soc-yammer, a.soc-yammer.bg-icon:hover, .soc-yammer.color-icon.border-icon:hover {
    background-color : #1175C4
}

.color-bg-icon.soc-stackoverflow, a.soc-stackoverflow.bg-icon:hover, .soc-stackoverflow.color-icon.border-icon:hover {
    background-color : #FD9827
}

.color-bg-icon.soc-drupal, a.soc-drupal.bg-icon:hover, .soc-drupal.color-icon.border-icon:hover {
    background-color : #00598E
}

.color-bg-icon.soc-odnoklassniki, a.soc-odnoklassniki.bg-icon:hover, .soc-odnoklassniki.color-icon.border-icon:hover {
    background-color : #F48420
}

.color-bg-icon.soc-android, a.soc-android.bg-icon:hover, .soc-android.color-icon.border-icon:hover {
    background-color : #8EC047
}

.color-bg-icon.soc-meetup, a.soc-meetup.bg-icon:hover, .soc-meetup.color-icon.border-icon:hover {
    background-color : #E2373C
}

.color-bg-icon.soc-persona, a.soc-persona.bg-icon:hover, .soc-persona.color-icon.border-icon:hover {
    background-color : #E6753D
}

.color-bg-icon.soc-amazon, a.soc-amazon.bg-icon:hover, .soc-amazon.color-icon.border-icon:hover {
    background-color : #FF9900
}

.color-bg-icon.soc-ello, a.soc-ello.bg-icon:hover, .soc-ello.color-icon.border-icon:hover {
    background-color : #000000
}

.color-bg-icon.soc-mixcloud, a.soc-mixcloud.bg-icon:hover, .soc-mixcloud.color-icon.border-icon:hover {
    background-color : #314359
}

.color-bg-icon.soc-8tracks, a.soc-8tracks.bg-icon:hover, .soc-8tracks.color-icon.border-icon:hover {
    background-color : #122C4B
}

.color-bg-icon.soc-twitch, a.soc-twitch.bg-icon:hover, .soc-twitch.color-icon.border-icon:hover {
    background-color : #6441A5
}

.color-bg-icon.soc-airbnb, a.soc-airbnb.bg-icon:hover, .soc-airbnb.color-icon.border-icon:hover {
    background-color : #FF5A5F
}

.color-bg-icon.soc-pocket, a.soc-pocket.bg-icon:hover, .soc-pocket.color-icon.border-icon:hover {
    background-color : #ED4055
}

a.social-icon {
    color : #88ADB9
}

a.border-icon {
    border        : 1px solid #88ADB9;
    border-color  : rgba(136, 173, 185, .4);
    padding       : 10px 11px;
    margin-bottom : 4px
}

a.bg-icon {
    color            : #FFFFFF;
    background-color : #88ADB9;
    padding          : 11px 12px;
    margin-bottom    : 4px
}

a.social-icon.color-icon:hover {
    color : #292A2E
}

a.social-icon.bg-icon:hover {
    color : #FFFFFF
}

a.color-bg-icon {
    color            : #FFFFFF;
    background-color : #292A2E;
    margin-bottom    : 4px;
    padding          : 11px 12px
}

a.social-icon.color-bg-icon:hover {
    background-color : #3D6889;
    color            : #FFFFFF
}

a.color-icon:hover {
    color : #292A2E
}

a.color-icon.border-icon:hover {
    color : #FFFFFF
}

.demo-icon {
    display    : inline-block;
    text-align : center;
    padding    : 15px 20px 10px
}

.demo-icon span {
    display : block
}

.demo-icon .name {
    font-size   : 1.01em;
    font-weight : 700
}

.demo-icon a[class*=soc-] {
    font-size : 24px;
    margin    : 10px 0 0
}

.social-icons-demo [class*=col-] a[class*=soc-] {
    margin : 10px
}

.gallery-item {
    text-align : center
}

.gallery-columns-1 .gallery-item {
    width : 100%
}

.gallery-columns-2 .gallery-item {
    width : 50%
}

.gallery-columns-3 .gallery-item {
    width : 33.33%
}

.gallery-columns-4 .gallery-item {
    width : 25%
}

.gallery-columns-5 .gallery-item {
    width : 20%
}

.gallery-columns-6 .gallery-item {
    width : 16.66%
}

.gallery-columns-7 .gallery-item {
    width : 14.28%
}

.gallery-columns-8 .gallery-item {
    width : 12.5%
}

.gallery-columns-9 .gallery-item {
    width : 11.11%
}

.gallery-icon img {
    display : block;
    margin  : 0 auto;
    width   : 100%
}

.gallery-caption {
    display : block;
    padding : .5em
}

.gallery-columns-6 .gallery-caption, .gallery-columns-7 .gallery-caption, .gallery-columns-8 .gallery-caption, .gallery-columns-9 .gallery-caption {
    display : none
}

@media (max-width : 600px) {
    .gallery-columns-4 .gallery-item, .gallery-columns-5 .gallery-item, .gallery-columns-6 .gallery-item, .gallery-columns-7 .gallery-item, .gallery-columns-8 .gallery-item, .gallery-columns-9 .gallery-item {
        width : 33%
    }

    .gallery-columns-4 .gallery-caption, .gallery-columns-5 .gallery-caption, .gallery-columns-6 .gallery-caption, .gallery-columns-7 .gallery-caption, .gallery-columns-8 .gallery-caption, .gallery-columns-9 .gallery-caption {
        display : none
    }
}

.isotope_container {
    -webkit-transition : height .2s ease-in-out 0s;
    transition         : height .2s ease-in-out 0s
}

.isotope-item[class*=col-] {
    margin-top : 0
}

#switcher {
    position           : fixed;
    top                : 22%;
    right              : -200px;
    width              : 200px;
    background-color   : #404040;
    color              : #FFFFFF;
    z-index            : 1001;
    font-size          : 15px;
    padding-bottom     : 20px;
    -webkit-transition : all .6s ease;
    transition         : all .6s ease
}

#switcher.active {
    right : 0
}

#switcher a {
    color : #3D6889
}

#switcher .checkbox {
    margin      : 4px 0;
    line-height : 1.5
}

#switcher h4 {
    line-height      : 60px;
    font-size        : 18px;
    text-align       : center;
    color            : #999999;
    background-color : #222222;
    margin           : 0;
    font-weight      : 300;
    letter-spacing   : 0;
    padding          : 0 36px
}

#switcher span[class^=rt-icon2-] {
    display          : block;
    position         : absolute;
    width            : 60px;
    height           : 60px;
    line-height      : 60px;
    color            : #FFFFFF;
    font-size        : 24px;
    background-color : #222222;
    text-align       : center;
    left             : -60px;
    top              : 0;
    cursor           : pointer;
    z-index          : 2
}

#switcher span[class^=rt-icon2-]:hover {
    color : #999999
}

#switcher span[class=rt-icon2-delete-outline] {
    background-color : transparent;
    z-index          : 1;
    left             : -20px
}

#switcher li {
    margin-bottom : 0;
    padding       : 0 4px
}

#switcher p {
    font-weight : 300;
    font-size   : 16px;
    line-height : 1;
    margin      : 0 0 10px
}

#switcher label {
    color : #999999
}

#switcher ul {
    margin-bottom : 0
}

#switcher .tooltip {
    min-width : 110px
}

#switcher .tooltip-arrow {
    display : none
}

#switcher-version a {
    padding        : 13px 14px;
    display        : inline-block;
    line-height    : 1;
    font-size      : 12px;
    font-weight    : 400;
    text-transform : uppercase
}

#switcher-version a:hover {
    color : #E4344E !important
}

#switcher-version a.light {
    background-color : #FFFFFF;
    color            : #292A2E
}

#switcher-version a.dark {
    color            : #FFFFFF;
    background-color : #262626
}

#switcher-colors a {
    position         : relative;
    display          : inline-block;
    width            : 40px;
    height           : 40px;
    background-color : #F5BB2C;
    overflow         : hidden
}

#switcher-colors a:hover {
    opacity : .7
}

#switcher-colors a:after {
    content           : '';
    position          : absolute;
    width             : 30px;
    height            : 74px;
    right             : 0;
    background-color  : #E23751;
    -webkit-transform : rotate(45deg);
    transform         : rotate(45deg)
}

#switcher-colors a.color2 {
    background-color : #E23751
}

#switcher-colors a.color2:after {
    background-color : #16A4D6
}

#switcher-colors a.color3 {
    background-color : #16A4D6
}

#switcher-colors a.color3:after {
    background-color : #F5BB2C
}

#switcher-colors {
    line-height : 0
}

#switcher-patterns a {
    display          : block;
    line-height      : 1;
    height           : 40px;
    width            : 40px;
    overflow         : hidden;
    background-color : #FFFFFF
}

#switcher-patterns a img {
    opacity : 0
}

#switcher-patterns a:hover {
    opacity : .7
}

#switcher-patterns li:first-child a img {
    opacity : 1
}

#switcher_accent_color, #switcher_color_scheme, #switcher_layout, #switcher_patterns {
    padding : 26px 30px 0
}

.checkbox-slider--b-flat {
    position : relative
}

.checkbox-slider--b-flat input {
    display         : block;
    position        : absolute;
    top             : 0;
    right           : 0;
    bottom          : 0;
    left            : 0;
    width           : 0%;
    height          : 0%;
    margin          : 0;
    cursor          : pointer;
    zoom            : 1;
    -webkit-opacity : 0;
    -moz-opacity    : 0;
    opacity         : 0;
    filter          : alpha(opacity=0)
}

.checkbox-slider--b-flat input + span {
    cursor              : pointer;
    -webkit-user-select : none;
    -moz-user-select    : none;
    -ms-user-select     : none;
    user-select         : none
}

.checkbox-slider--b-flat input + span:before {
    position : absolute;
    left     : 0;
    display  : inline-block
}

.checkbox-slider--b-flat input + span > h4 {
    display : inline
}

.checkbox-slider--b-flat input + span {
    padding-left : 40px
}

.checkbox-slider--b-flat input + span:before {
    content    : "";
    height     : 20px;
    width      : 40px;
    background : #FFFFFF
}

.checkbox-slider--b-flat input + span:after {
    width              : 20px;
    height             : 20px;
    position           : absolute;
    left               : 0;
    top                : 0;
    display            : block;
    background         : #FFFFFF;
    -webkit-transition : margin-left .4s ease-in-out;
    transition         : margin-left .4s ease-in-out;
    text-align         : center;
    font-weight        : 700;
    content            : ""
}

.checkbox-slider--b-flat input:checked + span:after {
    margin-left : 20px;
    content     : ""
}

.checkbox-slider--b-flat input:checked + span:before {
    -webkit-transition : background .2s ease-in;
    transition         : background .2s ease-in
}

.checkbox-slider--b-flat input + span {
    padding-left : 30px
}

.checkbox-slider--b-flat input + span:before {
    border-radius : 20px;
    width         : 40px
}

.checkbox-slider--b-flat input + span:after {
    background      : #3D6889;
    content         : "";
    width           : 20px;
    border          : solid transparent 2px;
    background-clip : padding-box;
    border-radius   : 20px
}

.checkbox-slider--b-flat input:checked + span:after {
    content         : "";
    margin-left     : 20px;
    border          : solid transparent 2px;
    background-clip : padding-box
}

.checkbox-slider--b-flat input:checked + span:before {
    background : #FFFFFF
}

.checkbox-slider--b-flat.checkbox-slider-md input + span:before {
    border-radius : 30px
}

.checkbox-slider--b-flat.checkbox-slider-md input + span:after {
    border-radius : 30px
}

.checkbox-slider--b-flat.checkbox-slider-lg input + span:before {
    border-radius : 40px
}

.checkbox-slider--b-flat.checkbox-slider-lg input + span:after {
    border-radius : 40px
}

.checkbox-slider--b-flat input + span:before {
    box-shadow : none
}
